import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import { Box } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { DynamicButton, Input } from 'components/common';
import { showErrorToast, showSuccessToast } from 'components/common/Toaster';
import { DefaultSkeletton } from 'components/common/WebSkeleton';
import { INTERNAL_SERVER_ERROR, SUCCESS } from 'constants/apiStatusCodes';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { userForgotPassword } from 'redux/APICaller/authAPIs';
import styles from '../styles';

const ForgotPassword = () => {
  const {
    control,
    formState: { errors },
    trigger,
    getValues
  } = useForm({
    defaultValues: {
      email: ''
    },
    mode: 'all',
    criteriaMode: 'all'
  });

  const navigate = useNavigate();

  const forgetPassword = useMutation({
    mutationFn: (data) => userForgotPassword(data),
    onSuccess: (res, { email_address }) => {
      if (res?.status === SUCCESS) {
        if (res?.data?.message) showSuccessToast(res?.data?.message);
        navigate(`/verify/${email_address}`);
      } else if (res?.status !== INTERNAL_SERVER_ERROR) {
        showErrorToast(res?.data?.message);
      }
    },
    onError: () => {
      showErrorToast();
    }
  });

  const handleClick = (res) => {
    if (res) {
      const { email } = getValues();
      forgetPassword.mutate({ email_address: email });
    }
  };

  return (
    <Box sx={styles.wrapper}>
      <Box>
        <Box sx={styles.heading}>Forget Password</Box>
        <Box sx={styles.description}>Rest your account password and access your account again.</Box>
      </Box>
      <Box sx={styles.inputWrapper}>
        <Input
          name="email"
          label="Email"
          placeholder="Email Address"
          customStyles={styles.input}
          loading={forgetPassword.isPending}
          startIcon={<MailOutlinedIcon />}
          control={control}
          errors={errors}
          rules={{
            required: 'This is a required field',
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: 'Please provide a valid email'
            }
          }}
        />
      </Box>
      <Box sx={styles.buttonWrapper}>
        <DynamicButton
          label="Next"
          customStyles={styles.button}
          disabled={forgetPassword.isPending}
          onClick={() => trigger().then((res) => handleClick(res))}
        />
      </Box>
      <Box sx={styles.footer}>
        <DefaultSkeletton loading={forgetPassword.isPending} customStyles={styles.footerLoading}>
          <Box>
            Don’t have an account?{' '}
            <Box component="span" sx={styles.linkers} onClick={() => navigate('/sign-up')}>
              Sign up
            </Box>
          </Box>
        </DefaultSkeletton>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
