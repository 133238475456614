export const stepList = [
  {
    heading: 'Onboarding',
    icon: '/rocket.svg',
    role: 'ROLE_onboarding',
    subHeading: [
      {
        label: 'Getting Started',
        route: '/getting-started'
      }
    ]
  },
  {
    heading: 'YMS',
    icon: '/yms.svg',
    role: 'ROLE_gate_yard_dock',
    nestedHeading: [
      {
        label: 'Overview',
        components: [{ label: 'Home', route: '/' }]
      },
      {
        label: 'Gate In',
        components: [{ label: 'Manage ACN', route: '/search-acn' }]
      },
      {
        label: 'Gate Out',
        components: [{ label: 'Manage Gate Out', route: '/allow-gate-out' }]
      },
      {
        label: 'Dock',
        components: [
          {
            label: 'Receive on dock',
            route: '/receive-on-dock'
          },
          {
            label: 'Start Loading / Unloading',
            route: '/start-loading-unloading'
          },
          {
            label: 'Complete Loading/ Unloading',
            route: '/complete-loading-unloading'
          },
          {
            label: 'Release from dock',
            route: '/release-from-dock'
          }
        ]
      }
    ]
  },
  {
    heading: 'Inbound',
    icon: '/inbound.png',
    role: 'ROLE_inbound',
    nestedHeading: [
      {
        label: 'Inbound PO',
        components: [
          {
            label: 'Purchase Orders',
            route: '/find-orders'
          },
          {
            label: 'PO Details',
            route: '/view-orders'
          }
        ]
      },
      {
        label: 'Consignment Details',
        components: [
          {
            label: 'Manage ACN',
            route: '/find-consignment'
          },
          {
            label: 'ACN Details',
            route: '/view-consignment'
          }
        ]
      },
      {
        label: 'Return Orders',
        components: [
          {
            label: 'Manage Return Orders',
            route: '/find-return-order'
          },
          {
            label: 'Return Details',
            route: '/view-return-order'
          }
        ]
      },
      {
        label: 'IRN',
        components: [
          {
            label: 'Generate IRN',
            route: '/create-irn'
          },
          {
            label: 'Receive IRN',
            route: '/receive-irn'
          },
          {
            label: 'Manage IRN',
            route: '/view-irn'
          }
        ]
      },
      {
        label: 'Putaways',
        components: [
          {
            label: 'Manage Putlist',
            route: '/find-putlist'
          },
          {
            label: 'Mark Complete',
            route: '/complete-putlist'
          },
          {
            label: 'Putlist Details',
            route: '/view-putlist'
          }
        ]
      }
    ]
  },
  {
    heading: 'Inventory',
    icon: '/inventory.png',
    role: 'ROLE_inventory',
    nestedHeading: [
      {
        label: 'Products',
        components: [
          {
            label: 'Manage Product',
            route: '/find-product'
          },
          {
            label: 'Product Detail',
            route: '/view-product'
          },
          {
            label: 'Product Location',
            route: '/search-inventory'
          }
        ]
      },
      {
        label: 'Stock Transfer',
        components: [
          {
            label: 'Manage STN',
            route: '/search-transfer'
          },
          {
            label: 'STN Picklist',
            route: '/pick-transfer'
          },
          {
            label: 'STN Putaway',
            route: '/put-away-transfer'
          },
          {
            label: 'STN Details',
            route: '/view-transfer'
          }
        ]
      }
    ]
  },
  {
    heading: 'Outbound',
    icon: '/outbound.png',
    role: 'ROLE_outbound',
    subHeading: [
      {
        label: 'Create SO',
        route: '/create-so'
      },
      {
        label: 'All Orders',
        route: '/all-orders'
      },
      {
        label: 'Pick',
        route: '/pick'
      },
      {
        label: 'Pack',
        route: '/pack'
      },
      {
        label: 'Manifest',
        route: '/manifest'
      }
    ]
  },
  {
    heading: 'Return',
    icon: '/return.png',
    role: 'ROLE_returns',
    subHeading: [
      {
        label: 'Create RO',
        route: '/create-ro'
      },
      {
        label: 'Return Manifest',
        route: '/return-manifest'
      },
      {
        label: 'Staging Inventory',
        route: '/staging-inventory'
      },
      {
        label: 'Put back',
        route: '/put-back'
      }
    ]
  },
  {
    heading: 'Bundling',
    icon: '/bundling.png',
    role: 'ROLE_bundling',
    subHeading: [
      {
        label: 'Manage Bundle',
        route: '/bundle-details'
      },
      {
        label: 'All Orders',
        route: '/all-bundling-orders'
      },
      {
        label: 'Issue',
        route: '/issue'
      },
      {
        label: 'Receive',
        route: '/receive'
      }
    ]
  },
  {
    heading: 'Packaging',
    icon: '/bundling.png',
    role: 'ROLE_packaging',
    subHeading: [
      {
        label: 'Generate Lot',
        route: '/generate-lot'
      },
      {
        label: 'Issue to SBS',
        route: '/issue-to-sbs'
      }
    ]
  },
  {
    heading: 'User Privilege',
    icon: '/user.png',
    role: 'Vendor',
    subHeading: [
      {
        label: 'Manage User Group',
        route: '/manage-user-group'
      },
      {
        label: 'Manage Users',
        route: '/manage-users'
      }
    ]
  },
  {
    heading: 'Master Management',
    icon: '/master.svg',
    role: 'Master',
    subHeading: [
      {
        label: 'Facility',
        route: '/facility-list'
      },
      {
        label: 'Warehouse',
        route: '/warehouse-zone'
      },
      {
        label: 'Area',
        route: '/area-list'
      },
      {
        label: 'Manage Suppliers',
        route: '/supplier-list'
      },
      {
        label: 'Manage Category',
        route: '/category-list'
      }
    ]
  }
];
