import { Box } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { showErrorToast, showSuccessToast } from 'components/common/Toaster';
import { useParams } from 'react-router-dom';
import { facilityWarehouseConverter } from '../../../constants/apiDataChanger';
import { ERROR_MESSAGE, ERROR_MSG, SUCCESS_MSG } from '../../../constants/constants';
import { createArea, getAreaByID } from '../../../redux/APICaller/areaAPIs';
import { getWarehouseListByFacilityId } from '../../../redux/APICaller/masterManagementAPIs';
import { selectAuthStore } from '../../../redux/authSlice';

import { Dropdown, Form, FormWrapper, Input, Radio } from '../../common';
import styles from './styles';
import { useNavigate } from 'react-router-dom';
import { SUCCESS_CREATED } from '../../../constants/apiStatusCodes';

const ManageArea = () => {
  const navigate = useNavigate();
  const authStore = useSelector(selectAuthStore);
  const dispatch = useDispatch();

  const { id } = useParams();

  const { control, trigger, getValues, setValue, watch, reset } = useForm({
    defaultValues: {
      facility_id: authStore.warehouseLocation,
      area_name: '',
      warehouse_id: '',
      status: true
    },
    mode: 'all',
    criteriaMode: 'all'
  });

  const { data: warehouseList, isLoading } = useQuery({
    queryKey: 'warehouseList',
    queryFn: async () => getWarehouseListByFacilityId({ facilityId: authStore.warehouseLocation }),
    enabled: !!watch('facility_id')
  });

  const { data: editArea = {}, isLoading: editLoading } = useQuery({
    queryKey: 'editArea',
    queryFn: async () => getAreaByID({ id }),
    enabled: !!id
  });

  const manageArea = useMutation({
    mutationFn: (data) => createArea(data),
    onSuccess: (res) => {
      if (res.status === SUCCESS_CREATED) {
        showSuccessToast(res?.data?.message || res?.data?.msg || 'Area Created');
        navigate('/area-list');
      } else {
        showErrorToast(res?.data?.message || res?.data?.msg);
      }
    },
    onError: () => {
      showErrorToast();
    }
  });

  const handleClick = (res) => {
    if (res) {
      const { facility_id, area_name, warehouse_id, status } = getValues();
      manageArea.mutate({ facility_id, area_name, warehouse_id, status });
    }
  };

  const facilityWarehouseList = facilityWarehouseConverter(warehouseList?.data);

  useEffect(() => {
    if (id) reset(editArea.data);
  }, [reset, editArea.data, id]);

  useEffect(() => {
    setValue('facility_id', authStore.warehouseLocation);
  }, [authStore]);

  return (
    <FormWrapper
      heading="Manage Area"
      loading={manageArea.isPending || isLoading || editLoading || id}
      buttonClick={() => trigger().then((res) => handleClick(res))}>
      <Form heading="Create Area" customStyles={styles.wrapper}>
        <Box sx={styles.formWrapper}>
          <Dropdown
            name="facility_id"
            label="Facility"
            disabled
            loading={manageArea.isPending || isLoading || editLoading}
            customStyles={styles.input}
            control={control}
            options={authStore?.warehouseLocationList}
            rules={{ required: 'This is a required field' }}
          />
          <Input
            name="area_name"
            label="Area Name"
            disabled={id}
            loading={manageArea.isPending || isLoading || editLoading}
            customStyles={styles.input}
            control={control}
            rules={{ required: 'This is a required field' }}
          />
          <Dropdown
            name="warehouse_id"
            label="Warehouse"
            disabled={!watch('facility_id') || id}
            loading={manageArea.isPending || isLoading || editLoading}
            customStyles={styles.input}
            control={control}
            options={facilityWarehouseList}
            rules={{ required: 'This is a required field' }}
          />
          <Radio
            name="status"
            label="Status"
            disabled={id}
            loading={manageArea.isPending || isLoading || editLoading}
            isRequired
            control={control}
            customStyles={styles.radioButton}
            options={[
              {
                label: 'Enable',
                value: true
              }
              // {
              //   label: 'Disable',
              //   value: false
              // }
            ]}
          />
        </Box>
      </Form>
    </FormWrapper>
  );
};

export default ManageArea;
