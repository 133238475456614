import { Box } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Accordion, Dropdown, Form, FormWrapper } from 'components/common';
import { showErrorToast, showSuccessToast } from 'components/common/Toaster';
import { SUCCESS } from 'constants/apiStatusCodes';
import { shortenString } from 'constants/common';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createZoneStorage,
  getAreaListByWarehouseId,
  getWarehouseListByFacilityId,
  getZoneListByAreaId
} from 'redux/APICaller/masterManagementAPIs';
import { selectAuthStore } from 'redux/authSlice';
import AisleDetails from './StorageTypeForms/AisleDetails';
import Bin from './StorageTypeForms/Bin';
import Rack from './StorageTypeForms/Rack';
import Shelf from './StorageTypeForms/Shelf';
import StorageUnit from './StorageTypeForms/StorageUnit';
import styles from './styles';

const StorageDetailsZone = () => {
  const { facilityId, areaId: areaID, zoneId } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Master data
  const [binList, setBinList] = useState([]);
  const [aisleList, setAisleList] = useState([]);
  const [rackList, setRackList] = useState([]);
  const [shelfList, setShelfList] = useState([]);

  const [warehouseList, setWarehouseList] = useState([]);
  const [warehouseListWithArea, setWarehouseListWithArea] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [warehouseId, setWarehouseId] = useState('');
  const [areaId, setAreaId] = useState('');

  const [expanded, setExpanded] = useState(false);
  const { warehouseLocationList: facilityList } = useSelector(selectAuthStore);
  const { control, watch, trigger, getValues, setValue } = useForm({
    defaultValues: {
      facility: '',
      capacity: '',
      storageUnitCode: '',
      storageUnitType: ''
    },
    mode: 'all',
    criteriaMode: 'all'
  });

  const getallareas = (warehouseList) => {
    warehouseList?.map(async ({ id }, i) => {
      const res = await getAreaListByWarehouseId({ warehouseId: id });

      if (res?.status === SUCCESS) {
        const newList = res?.data?.list?.map((area) => {
          return { ...warehouseList[i], ...area };
        });
        setWarehouseListWithArea((previous) => {
          return [...previous, ...newList];
        });
      }
    });
  };

  const handleChange = (panel, id) => (_, isExpanded) => {
    if (warehouseId != id) setAreaList([]);
    setAreaId('');

    setWarehouseId(id);
    setExpanded(isExpanded ? panel : false);
  };

  const watchFacility = watch('facility');
  const storageType = watch('storageUnitType');

  useEffect(() => {
    if (warehouseList?.length) {
      setWarehouseListWithArea([]);
      getallareas(warehouseList);
    }
  }, [warehouseList]);

  const { data = { data: [] }, isLoading } = useQuery({
    queryKey: ['warehouseList', watchFacility],
    queryFn: async () => {
      const res = await getWarehouseListByFacilityId({ facilityId: watchFacility });

      if (res?.status === SUCCESS) setWarehouseList(res?.data);
      return res;
    },
    enabled: !!watchFacility
  });

  const { areaListData = { data: [] }, isLoading: areaListIsLoading } = useQuery({
    queryKey: ['zoneList', warehouseId],
    queryFn: async () => {
      const res = await getZoneListByAreaId({ areaId: warehouseId });
      setAreaList(res?.data?.list);
      return res;
    },
    enabled: !!warehouseId
  });

  const forms = [
    {
      heading: 'Aisle Details',
      form: AisleDetails,
      show: ['4']
    },
    {
      heading: 'Rack',
      form: Rack,
      show: ['4', '3']
    },
    {
      heading: 'Shelf',
      form: Shelf,
      show: ['4', '3', '2']
    },
    {
      heading: 'Bin',
      form: Bin,
      show: ['4', '3', '2', '1']
    }
  ];

  useEffect(() => {
    if (facilityId && facilityList?.length) {
      setValue('facility', facilityId);
    }
  }, [facilityId, facilityList]);

  useEffect(() => {
    if (facilityId && areaID && zoneId && warehouseList?.length) {
      setWarehouseId(areaID);
      setExpanded(`panel${areaID}`);
      setAreaId(zoneId);
    }
  }, [warehouseList?.length, areaID, facilityId, zoneId]);

  const { isPending, mutate } = useMutation({
    mutationFn: (data) => {
      return createZoneStorage(data);
    },
    onSuccess: (res) => {
      if (res?.status === SUCCESS) {
        showSuccessToast(res?.data?.msg);
      } else {
        showErrorToast(res?.data?.message);
      }
    },
    onError: () => {
      showErrorToast();
    }
  });

  const handleClick = (res) => {
    if (res) {
      const { capacity, storageUnitType } = getValues();
      // BIN
      if (storageUnitType === '1') {
        if (!binList?.length) {
          showErrorToast('Please add at least one Bin');
        } else {
          const payload = {
            capacity: Number(capacity),
            su_type: 'BIN',
            zone_id: areaId,
            bin: binList,
            shelf: [],
            rack: [],
            aisle: []
          };
          mutate(payload);
        }
      } else if (storageUnitType === '2') {
        if (!shelfList?.length) {
          showErrorToast('Please add at least one Shelf');
        } else {
          const payload = {
            capacity: Number(capacity),
            su_type: 'SHELF',
            zone_id: areaId,
            bin: binList,
            shelf: shelfList,
            rack: [],
            aisle: []
          };
          mutate(payload);
        }
      } else if (storageUnitType === '3') {
        if (!rackList?.length) {
          showErrorToast('Please add at least one Rack');
        } else {
          const payload = {
            capacity: Number(capacity),
            su_type: 'RACK',
            zone_id: areaId,
            bin: binList,
            shelf: shelfList,
            rack: rackList,
            aisle: []
          };
          mutate(payload);
        }
      } else if (storageUnitType === '4') {
        if (!aisleList?.length) {
          showErrorToast('Please add at least one Aisle');
        } else {
          const payload = {
            capacity: Number(capacity),
            su_type: 'AISLE',
            zone_id: areaId,
            bin: binList,
            shelf: shelfList,
            rack: rackList,
            aisle: aisleList
          };
          mutate(payload);
        }
      }
    }
  };

  return (
    <Box sx={styles.wrapper}>
      <FormWrapper
        heading="Storage Details"
        customStyles={styles.warehouseWrapper}
        childrenStyles={styles.warehouseWrapperChildren}>
        <Box sx={styles.listContainer}>
          <Box sx={styles.dropDownWrapper}>
            <Dropdown
              name="facility"
              control={control}
              options={facilityList}
              selectClass="selectClass"
            />
          </Box>
          <Box sx={styles.accordianContentWrapper}>
            {watchFacility &&
              warehouseListWithArea?.map(({ warehouseName, area_name, area_id }, idx) => {
                return (
                  <Box key={idx} sx={styles.accordianWrapper}>
                    <Accordion
                      expanded={expanded === `panel${area_id}`}
                      handleChange={handleChange(`panel${area_id}`, area_id)}
                      heading={shortenString(`${warehouseName}(${area_name})`, 30)}
                      customStyles={{
                        borderRadius: 0,
                        borderTop: idx !== 0 && 'none'
                      }}
                      description={areaList?.map(({ zone_name, zone_id }, id) => {
                        return (
                          <Box
                            key={id}
                            sx={{
                              ...styles.accordianItem,
                              color: areaId === zone_id ? 'primary.main' : 'black'
                            }}
                            onClick={() => {
                              setAreaId(zone_id);
                              // reset({
                              //   zone_name: '',
                              //   picklist_quantity: '',
                              //   category_id: '',
                              //   status: true,
                              //   facility: watchFacility
                              // });
                            }}>
                            {shortenString(zone_name, 25)}
                          </Box>
                        );
                      })}
                    />
                  </Box>
                );
              })}
          </Box>
        </Box>
      </FormWrapper>
      {areaId && (
        <FormWrapper
          heading="Storage Unit Type"
          customStyles={styles.zoneWrapper}
          buttonClick={() => trigger().then((res) => handleClick(res))}>
          <Form heading="Storage Unit" customStyles={{ width: '100%' }}>
            <StorageUnit control={control} />
          </Form>
          {forms.map(({ heading, form: FormComponent, show }, index) => {
            return (
              <Box key={index} hidden={!show.includes(storageType)}>
                <Form
                  customStyles={{ maxHeight: '100%' }}
                  heading={heading}
                  linkButtonLabel={index === 4 - Number(storageType) ? 'View List' : ''}
                  linkButtonClick={() => {
                    navigate('/storage-listing');
                  }}>
                  <FormComponent
                    watch={watch}
                    setBinList={setBinList}
                    setAisleList={setAisleList}
                    setRackList={setRackList}
                    aisleList={aisleList}
                    rackList={rackList}
                    setShelfList={setShelfList}
                    shelfList={shelfList}
                    storageType={storageType}
                  />
                </Form>
              </Box>
            );
          })}
        </FormWrapper>
      )}
    </Box>
  );
};

export default StorageDetailsZone;
