const styles = {
  filesWrapper: {
    border: '1px solid #F0F1F2',
    backgroundColor: '#F0F1F2',
    marginTop: '0.4em',
    boxSizing: 'border-box',
    padding: '2px 5px',
    overflowY: 'auto',
    maxHeight: '130px'
  },

  fileWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },

  file: {
    color: 'primary.main',
    width: '132px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',

    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  }
};

export default styles;
