const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
    position: 'relative',
    boxSizing: 'border-box'
  },

  heading: {
    fontSize: '18px',
    fontWeight: 400,
    letterSpacing: 1.2
  }
};

export default styles;
