import { Box } from '@mui/material';
import { Dropdown, Input, MainContent, Password, Radio } from 'components/common';
import { BAD_REQUEST, SUCCESS } from 'constants/apiStatusCodes';
import { ERROR, SUCCESS as SUCCESS_MSG } from 'constants/constants';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { showErrorToast, showSuccessToast } from 'components/common/Toaster';
import { useNavigate, useParams } from 'react-router-dom';
import { getUser, updateUser } from 'redux/APICaller/userPrivilegeAPI';
import { showToastMessage } from 'redux/showMessageSlice';
import {
  emailValidator,
  passwordErrors,
  passwordHash,
  passwordMapping,
  passwordValidator,
  phoneNumberValidator,
  required,
  status
} from '../constants';
import styles from './styles';

const UpdateUser = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const {
    control,
    getValues,
    formState: { errors },
    reset,
    trigger,
    setValue
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
      confirm_password: '',
      language: 'english',
      status: false,
      first_name: '',
      last_name: '',
      phone_number: ''
    },
    mode: 'all',
    criteriaMode: 'all'
  });
  const navigate = useNavigate();

  const [isUpdated, setIsUpdated] = useState(false);
  const [showPassword, setShowPassword] = useState(!!(getValues('password') === passwordHash));

  const fetchUserDetails = async (id) => {
    const response = await getUser({ id });

    if (response?.status === SUCCESS) {
      reset({ ...response?.data, password: passwordHash });
      response?.data?.message && showSuccessToast(response?.data?.message);
    } else {
      if (response?.status === BAD_REQUEST) showErrorToast(response?.data?.message);
      else showErrorToast();
    }
  };

  const updatingUser = async () => {
    const { first_name, last_name, status, phone_number, language, password, confirm_password } =
      getValues();
    const payload = {
      id,
      first_name,
      last_name,
      status,
      phone_number,
      language
    };
    if (password !== passwordHash && password !== '') {
      payload['password'] = password;
      payload['confirm_password'] = confirm_password;
    }
    const response = await updateUser(payload);
    if (response.status === SUCCESS) {
      setIsUpdated(true);
      showSuccessToast(response?.data?.message);
    } else {
      if (response?.status === BAD_REQUEST) showErrorToast(response?.data?.message);
      else showErrorToast();
    }
  };

  useEffect(() => {
    fetchUserDetails(id);
  }, [id]);

  return (
    <MainContent
      heading="User List"
      navigateLabel="Update"
      navigateClick={() => trigger().then((res) => res && updatingUser())}>
      <Box sx={styles.actionWrapper}>
        <Box sx={styles.actionButtonWrapper}>
          <Box
            sx={{
              ...styles.actionButton,
              backgroundColor: '#FFEFD3',
              border: '1px solid #FFAA1B'
            }}>
            General
          </Box>
          <Box
            sx={{
              ...styles.actionButton,
              backgroundColor: '#F4F4F4',
              border: 'none'
            }}
            onClick={() => {
              navigate(`/user-groups/${id}/update`);
            }}>
            User Groups
          </Box>
        </Box>
      </Box>
      <Box sx={styles.wrapper}>
        <Box>
          <Box sx={styles.headerWrapper}>
            <Box sx={styles.heading}>User account information</Box>
          </Box>
          <Box sx={styles.inputWrapper}>
            <Box sx={styles.input}>
              <Input
                name="email"
                label="Email"
                disabled
                control={control}
                isRequired
                rules={{
                  required,
                  pattern: emailValidator
                }}
              />
            </Box>
            <Box sx={styles.input}>
              <Password
                name="password"
                label="Password"
                disabled={isUpdated}
                showEye={showPassword}
                errors={errors}
                control={control}
                onBluring={() =>
                  getValues('password') === '' &&
                  (setValue('password', passwordHash), setShowPassword(false))
                }
                focusChange={() =>
                  getValues('password') === passwordHash &&
                  (setValue('password', ''), setShowPassword(true))
                }
                rules={{ validate: passwordValidator }}
                errorMapping={passwordMapping}
              />
            </Box>
            {getValues('password') !== passwordHash && getValues('password') !== '' && (
              <Box sx={styles.input}>
                <Password
                  name="confirm_password"
                  label="Confirm Password"
                  disabled={isUpdated}
                  showEye
                  errors={errors}
                  control={control}
                  rules={{
                    required: getValues('password'),
                    validate: {
                      isSame: (value) =>
                        (value === getValues('password') && !!value) || passwordErrors.isSame
                    }
                  }}
                  errorMapping={[{ key: 'isSame', label: 'Matched' }]}
                />
              </Box>
            )}
            <Box sx={styles.input}>
              <Dropdown
                name="language"
                label="Language"
                control={control}
                isRequired
                disabled
                options={[{ label: 'English', value: 'english' }]}
              />
            </Box>
            <Box sx={styles.input}>
              <Box>
                Status{' '}
                <Box component="span" sx={styles.colorRed}>
                  *
                </Box>
              </Box>
              <Radio
                name="status"
                isDisabled={isUpdated}
                control={control}
                options={status}
                customStyles={styles.modalRadio}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={styles.contactInfo}>
          <Box sx={styles.heading}>Contact information</Box>
          <Box sx={styles.inputWrapper}>
            <Box sx={styles.input}>
              <Input
                name="first_name"
                label="First Name"
                disabled={isUpdated}
                control={control}
                isRequired
                rules={{ required }}
              />
            </Box>
            <Box sx={styles.input}>
              <Input
                name="last_name"
                label="Last Name"
                disabled={isUpdated}
                control={control}
                isRequired
                rules={{ required }}
              />
            </Box>
            <Box sx={styles.input}>
              <Input
                name="phone_number"
                label="Phone Number"
                disabled={isUpdated}
                isRequired
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                control={control}
                rules={{
                  pattern: phoneNumberValidator,
                  required
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </MainContent>
  );
};

export default UpdateUser;
