import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton } from '@mui/material';
import styles from './styles';

const FileRenderer = ({ itemList, itemNameClick = () => {}, itemClearClick = () => {} }) => {
  return (
    <Box>
      {itemList && itemList?.length !== 0 && (
        <Box sx={styles.filesWrapper}>
          {itemList?.map((item, index) => {
            return (
              <Box key={index} sx={styles.fileWrapper}>
                <Box sx={styles.file} onClick={() => itemClearClick(item, index)}>
                  {item?.fileName}
                </Box>
                <IconButton onClick={() => itemNameClick(item, index)}>
                  <CloseIcon />
                </IconButton>
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default FileRenderer;
