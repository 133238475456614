const styles = {
  input: {
    position: 'relative'
    // height: {
    //   xs: '34px',
    //   sm: '38px'
    // }
  },

  progressBar: {
    position: 'absolute',
    right: 10,
    bottom: 9
  }
};

export default styles;
