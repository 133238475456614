import { Box } from '@mui/material';
import React from 'react';
import styles from '../styles';

const UrlRenderer = (cellData) => {
  const ACN_ID = cellData?.ACNId ? cellData?.ACNId : cellData;

  return (
    <Box sx={styles.lcanId} component="a" href={ACN_ID} target="_blank">
      {ACN_ID}
    </Box>
  );
};

export default UrlRenderer;
