import { Box, FormHelperText, InputBase } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import styles from './styles';

const getFileExtensions = (mimeTypes) => {
  const mimeTypeToExtension = {
    'application/pdf': 'PDF',
    'application/doc': 'DOC',
    'text/csv': 'CSV',
    'image/jpeg': 'JPG',
    'video/mp4': 'MP4'
    // Add more mappings here
  };

  const extensions = [];

  mimeTypes?.forEach((mimeType) => {
    const extension = mimeTypeToExtension[mimeType];
    if (extension) {
      extensions.push(extension);
    }
  });

  return extensions;
};

const FileUploadComponent = ({
  setFile,
  isDisabled,
  uploadState,
  fileSize = 1,
  allowedTypes = ['application/pdf'],
  variant = 'standard',
  className,
  fullWidth = true
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const inputRef = useRef(null);

  const handleBoxClick = () => {
    if (!isDisabled && !uploadState) {
      inputRef.current.click();
    }
  };

  const handleFileSelect = (event) => {
    setFile(null);
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      setErrorMessage('Please select a file.');
      return;
    }
    if (!allowedTypes.includes(selectedFile?.type)) {
      setErrorMessage(`File type should be ${getFileExtensions(allowedTypes)?.toString()}.`);
      return;
    }

    if (selectedFile.size > fileSize * 1024 * 1024) {
      setErrorMessage(`File size should be less than ${fileSize}mb`);
      return;
    }

    setSelectedFile(selectedFile);
    setErrorMessage('');
  };

  useEffect(() => {
    if (selectedFile) {
      setFile(selectedFile);
    }
  }, [selectedFile]);

  return (
    <Box sx={styles.wrapper}>
      <InputBase type="file" onChange={handleFileSelect} inputRef={inputRef} sx={styles.inputBox} />
      <Box
        onClick={handleBoxClick}
        sx={{
          ...styles.input,
          cursor: isDisabled || uploadState ? 'not-allowed' : 'pointer'
        }}>
        {selectedFile ? '' : 'Click to upload'}
        <Box>
          {uploadState
            ? uploadState
            : selectedFile
            ? selectedFile?.name
            : `${getFileExtensions(allowedTypes)?.toString()} | ${fileSize} MB (maximum)`}
        </Box>
      </Box>
      {errorMessage && <FormHelperText sx={styles.error}>{errorMessage}</FormHelperText>}
    </Box>
  );
};

export default FileUploadComponent;
