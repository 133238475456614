import { Popover } from '@mui/material';
import styles from './styles';

const StepOpener = ({ children, anchorEl, handleClose, customStyles }) => {
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      sx={{ ...styles.mainPopover, ...customStyles }}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}>
      {children}
    </Popover>
  );
};
export default StepOpener;
