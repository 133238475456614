export const status = [
  { label: 'Enable', value: true },
  { label: 'Disable', value: false }
];

export const passwordRegex = {
  isUpper: /[A-Z]/,
  isLower: /[a-z]/,
  isNum: /\d/,
  isSymbol: /[^\p{L}\d\s]/u
};

export const passwordErrors = {
  isLength: 'Password must be at least 6 characters long',
  isUpper: 'Password must contain at least one uppercase letter',
  isLower: 'Password must contain at least one lowercase letter',
  isNum: 'Password must contain at least one digit',
  isSame: 'Password Does not match'
};

export const passwordHash = 'P@ssw0rd123!';

export const required = 'This is a required field';

export const emailValidator = {
  value: /\S+@\S+\.\S+/,
  message: 'Please provide a valid email address'
};

export const passwordValidator = {
  isLength: (value) => (value && value.length >= 6) || passwordErrors.isLength,
  isUpper: (value) =>
    (value && passwordRegex.isUpper.test(value.toString())) || passwordErrors.isUpper,
  // isLower: (value) =>
  //   (value && passwordRegex.isLower.test(value.toString())) || passwordErrors.isLower,
  isNum: (value) => (value && passwordRegex.isNum.test(value.toString())) || passwordErrors.isNum,
  isWhitespace: (value) => {
    if (value) {
      let checkValue = value.split('');
      if (checkValue[0] === ' ' || checkValue[checkValue.length - 1] === ' ')
        return 'Password should not have trailing whitespace';
    }
  }
};

export const passwordMapping = [
  { key: 'isLength', label: 'Minimum 6 digits' },
  // { key: 'isLower', label: 'Lowercase' },
  { key: 'isUpper', label: 'Uppercase' },
  { key: 'isNum', label: 'Number' },
  { key: 'isWhitespace', label: 'Whitespace' }
];

export const phoneNumberValidator = {
  value: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
  message: 'Please provide a valid phone number'
};
