import { Box } from '@mui/material';
import { MainContent, Pagination, Spinner, TableDataRenderer, Tooltip } from 'components/common';
import useWindowDimensions from 'hooks/useWindowDimensions';
import styles from './styles';

const BinList = ({ isLoading }) => {
  const [{ width }] = useWindowDimensions();
  const isWebView = width > 1000;

  const columnDefs = [
    {
      field: 'bin_name',
      headerName: 'Bin Name',
      minWidth: 250,
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => <Tooltip title={data.bin_name ? data.bin_name : 'No data'} />
    },
    {
      field: 'shelf_name',
      headerName: 'Shelf Name',
      minWidth: 250,
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => <Tooltip title={data.shelf_name ? data.shelf_name : 'No data'} />
    },
    {
      field: 'shelf_code',
      headerName: 'Shelf Code',
      minWidth: 250,
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => <Tooltip title={data.shelf_code ? data.shelf_code : 'No data'} />
    }
  ];

  return (
    <MainContent heading="Bin Details" description="Bin listing page">
      <Box sx={styles.wrapper}>
        {isLoading ? <Spinner /> : <TableDataRenderer list={[]} columnDefs={columnDefs} />}
        <Box sx={styles.paginationWrapper}>
          <Pagination
            count={10}
            page={1}
            //   onChange={(e, pageNumber) => pageClick(e, pageNumber)}
          />
        </Box>
      </Box>
    </MainContent>
  );
};

export default BinList;
