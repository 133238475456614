const styles = {
  wrapper: {
    display: 'flex',
    // justifyContent: 'space-evenly',
    flexDirection: 'column',
    padding: '16px 0px',
    rowGap: '20px',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
    backgroundColor: '#FFF8EB'
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    justifyContent: 'center',
    alignItems: 'center'
  },

  iconButton: {
    backgroundColor: '#FCECCF',
    padding: {
      xs: '6px',
      sm: '10px'
    }
  },

  heading: {
    fontSize: {
      xs: '12px',
      sm: '14px'
    },
    fontWeight: 500,
    textAlign: 'center'
  },

  mainAccordian: {
    border: 'none',
    boxShadow: 'none',

    '& .MuiAccordionDetails-root': {
      padding: '0 !important'
    },

    '&.MuiAccordion-root:before': {
      padding: '0 !important',
      opacity: '0 !important'
    },

    '& .Mui-disabled': {
      cursor: 'not-allowed',
      backgroundColor: 'custom.disabled !important',
      pointerEvent: 'all'
    }
  },

  icon: {
    color: 'black'
  },

  label: {
    fontSize: '16px',
    fontWeight: 400
  },

  details: {
    position: 'relative',
    boxSizing: 'border-box',
    fontSize: {
      xs: '12px',
      sm: '14px'
    },
    cursor: 'pointer',
    letterSpacing: 0.8,
    margin: '0.5em',
    padding: {
      xs: '10px 8px 10px 3em',
      md: '12px 8px 12px 2.6em'
    },
    '&:hover': {
      backgroundColor: '#FFF8EB'
    }
  },

  leftBar: {
    position: 'absolute',
    top: 0,
    left: 2,
    borderRadius: '8px',
    backgroundColor: '#FAB278',
    height: '100%',
    border: '2px solid',
    borderColor: 'primary.main'
  }
};

export default styles;
