import { amber } from '@mui/material/colors';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
  palette: {
    primary: {
      main: '#E9B519'
    },
    secondary: amber,
    success: {
      main: '#77e457'
    },
    error: {
      main: '#fa5959'
    },
    custom: {
      background: '#fbfbfb',
      label: '#000000',
      gray: '#697576',
      disabledLabel: '#d8d8d8',
      heading: '#231f20',
      connector: '#e8e9eb',
      disabled: '#EDECEE',
      darkSilver: '#707070',
      scrollThumb: '#bbbec2',
      border: '#e6e6e6'
    }
  },

  typography: {
    fontFamily: ['SF Pro Display', 'sans-serif'].join(', '),
    font: {
      openSans: {
        fontFamily: ['SF Pro Display', 'sans-serif'].join(', ')
      }
    }
  }
});

// styles overrides
theme = createTheme(theme, {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          '& *': {
            '&::-webkit-scrollbar ': {
              width: '8px'
            },

            '&::-webkit-scrollbar-track': {
              backgroundColor: theme.palette.custom.connector,
              borderRadius: '4px'
            },

            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.custom.scrollThumb,
              borderRadius: '4px'
            },

            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: theme.palette.custom.connector
            }
          }
        }
      }
    },

    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize'
        }
      }
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.MuiInputLabel-root.clip': {
            maxWidth: '90%'
          },
          '&.MuiInputLabel-root': {
            maxWidth: '100%'
          },
          fontSize: '16px',
          [theme.breakpoints.down('sm')]: {
            fontSize: '12px'
          },
          color: theme.palette.custom.label,
          '&.Mui-focused': {
            color: theme.palette.custom.label
          },
          transform: 'none'
        }
      }
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          'label + &': {
            marginTop: '40px',
            [theme.breakpoints.down('sm')]: {
              marginTop: '30px'
            }
          },
          height: '50px',
          boxSizing: 'border-box',
          backgroundColor: 'white',
          borderRadius: 5,
          position: 'relative',
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: theme.palette.custom.disabled,
          fontSize: '14px',
          padding: '10px 12px',
          [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
            height: '43px'
          },
          '&.Mui-error': {
            borderColor: theme.palette.error.main
          },
          '&.Mui-disabled': {
            borderColor: '#EDECEF',
            backgroundColor: '#EDECEF'
          },
          '&.Mui-focused': {
            borderColor: theme.palette.primary.main
          },
          '& input::-webkit-outer-spin-button': {
            display: 'none',
            MozAppearance: 'none'
          },
          '& input::-webkit-inner-spin-button': {
            display: 'none',
            MozAppearance: 'none'
          }
        }
      }
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          fontStyle: 'italic',
          color: theme.palette.error.main,
          fontWeight: 'bold',
          position: 'absolute',
          bottom: '-22px',
          [theme.breakpoints.down('sm')]: {
            fontSize: '10px',
            bottom: '-18px'
          }
        }
      }
    }
  }
});

const customTypography = {};

theme = {
  ...theme,
  typography: {
    ...theme.typography,
    ...customTypography
  }
};

theme = responsiveFontSizes(theme);

export default theme;
