const INBOUND_ORDER_PATHS = {
  POST_CREATE_PO_FILE_UPLOAD: '/order/import',
  GET_PO_LIST_LIST: '/order/details',
  GET_PO_LIST_LIST_CSV_DOWNLOAD: '/order/details/export',
  // GET_PO_DETAILS: `/order/:POid`,
  GET_PO_DETAILS: `/order`,
  // GET_PO_LIST_DETAILS: `/order/:POid/products`,
  GET_PO_LIST_DETAILS: `/order/products`,
  // GET_PO_DETAILS_CSV: '/order/:po_id/products/export'
  GET_PO_DETAILS_CSV: '/order/products/export'
};

export default INBOUND_ORDER_PATHS;
