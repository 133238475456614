const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      sm: 'row'
    },
    alignItems: {
      xs: 'flex-start',
      sm: 'center'
    },
    gap: '5px',
    margin: '1em'
  },

  multiWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '15px',
    margin: '1em'
  },

  optionWrapper: {
    display: 'flex',
    gap: '5px'
  },

  label: {
    fontSize: {
      xs: '14px',
      sm: '16px',
      md: '16px'
    }
  },

  value: {
    fontSize: {
      xs: '14px',
      sm: '16px',
      md: '16px'
    },
    fontWeight: 500,
    textTransform: 'uppercase'
  },

  singleValue: {
    fontSize: {
      xs: '14px',
      sm: '16px',
      md: '16px'
    },
    fontWeight: 500,
    textTransform: 'capitalize'
  }
};

export default styles;
