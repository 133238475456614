const styles = {
  wrapper: {
    boxSizing: 'border-box',
    // border: '1px solid #EAEBEE',
    borderRadius: '16px',
    boxShadow: 'none',

    '& .MuiAccordionDetails-root': {
      padding: '0 !important'
    },

    '&.MuiAccordion-root:before': {
      padding: '0 !important',
      opacity: '0 !important'
    },

    '& .Mui-disabled': {
      cursor: 'not-allowed',
      backgroundColor: 'custom.disabled !important',
      pointerEvent: 'all'
    }
  },

  summary: {
    boxSizing: 'border-box',
    borderRadius: '10px 10px 0px 0px',
    position: 'relative',
    fontSize: '14px',
    // margin: '0.2rem 0',
    gap: '16px',
    padding: {
      xs: '0 13px',
      md: '0 16px'
    }
  },

  icon: {
    fontSize: '32px',
    marginRight: '-6px'
  },

  heading: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    boxSizing: 'border-box',
    transition: '.5s',
    fontSize: {
      xs: '14px',
      sm: '16px'
    },
    fontWeight: 400
  },

  description: {
    position: 'relative',
    boxSizing: 'border-box',
    fontSize: {
      xs: '12px',
      sm: '14px'
    },
    cursor: 'pointer',
    letterSpacing: 0.8,
    padding: {
      xs: '10px',
      md: '12px 10px'
    }
  },

  details: {
    borderRadius: '0px 0px 10px 10px',
    backgroundColor: '#FFFAF2'
  }
};

export default styles;
