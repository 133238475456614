export const DOCK_RELEASE = 'RELEASED FROM DOCK';
export const ACN_ID = 'ACN ID';
export const PO_NO = 'PO No.';
export const INVOICE_NUMBER = 'Invoice No.';
export const TRAILOR_NUMBER = 'Trailor number';
export const TRAILOR_STATUS = 'Trailor status';
export const TRAILOR_LOCATION = 'Trailor location';
export const TRUCK_NUMBER = 'Truck No';
export const consigmentType = {
  inBound: 'Inbound',
  outBound: 'Outbound'
};
export const dispatchStatus = {
  startLoading: 'Start Loading',
  startUnloading: 'Start Unloading',
  completeLoading: 'Complete Loading',
  completeUnloading: 'Complete Unloading'
};
