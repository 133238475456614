import { IconButton } from '@mui/material';
import { styled } from '@mui/system';

const SquareIconButton = styled(IconButton)({
  borderRadius: 0,
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    borderRadius: 8
  }
});

function CustomIconButton({ children, ...props }) {
  return <SquareIconButton {...props}>{children}</SquareIconButton>;
}

export default CustomIconButton;
