const styles = {
  dataWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '20px',
    alignItems: {
      xs: 'start',
      sm: 'center'
    }
  },

  error: {
    padding: {
      md: '18px',
      xs: '12px'
    },
    textTransform: 'capitalize',
    fontSize: {
      md: '16px',
      xs: '14px'
    },
    letterSpacing: '2px',
    marginBottom: '1rem',
    backgroundColor: '#FCECE9',
    border: '1px solid #ECCAC3',
    fontWeight: 500
  },

  headerWrapper: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      md: 'row'
    },
    alignItems: {
      xs: 'start',
      md: 'center'
    },
    gap: '20px'
  },

  image: {
    width: '70px',
    borderRadius: '8px',
    padding: '15px'
  },

  heading: {
    fontSize: {
      xs: '14px',
      sm: '16px'
    },
    fontWeight: 500
  },

  description: {
    fontSize: {
      xs: '12px',
      sm: '14px'
    }
  },

  button: {
    width: {
      xs: '130px',
      md: '140px'
    },

    fontSize: '14px',
    height: '46px',
    borderRadius: '8px'
  },

  divider: {
    border: '1px solid #EAEBEE'
  }
};

export default styles;
