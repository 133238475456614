import { Box } from '@mui/material';
import { Input, Radio } from 'components/common';
import styles from '../styles';

const CategoryForm = ({ control, loading, disabled }) => {
  return (
    <Box sx={styles.formWrapper}>
      <Input
        isRequired
        name="category_name"
        label="Category Name"
        disabled={disabled}
        control={control}
        loading={loading}
        customStyles={styles.input}
        rules={{ required: 'This is a required field' }}
      />
      <Input
        isRequired
        name="category_code"
        label="Category Code"
        disabled={disabled}
        control={control}
        loading={loading}
        customStyles={styles.input}
        rules={{ required: 'This is a required field' }}
      />
      <Radio
        name="status"
        label="Status"
        disabled={disabled}
        isRequired
        loading={loading}
        control={control}
        customStyles={styles.radioButton}
        options={[
          {
            label: 'Enable',
            value: true
          }
          // {
          //   label: 'Disable',
          //   value: false
          // }
        ]}
      />
    </Box>
  );
};

export default CategoryForm;
