const styles = {
  wrapper: {
    position: 'relative',
    padding: '20px',
    backgroundColor: 'white',

    '& .react-datepicker': {
      display: 'flex',
      border: 'none',
      fontFamily: "'Poppins', sans-serif",
      gap: '2.5em',
      boxSizing: 'border-box'
    },

    '& .react-datepicker__header': {
      border: 'none',
      backgroundColor: 'white',
      padding: '20px 5px',
      fontSize: '16px',
      color: 'black',
      fontWeight: 500,
      boxSizing: 'border-box'
    },

    '& .react-datepicker__day': {
      width: '40px',
      lineHeight: '40px',
      m: 0,
      borderRadius: 0,
      fontSize: '14px',
      boxSizing: 'border-box'
    },

    '& .react-datepicker__day:hover': {
      backgroundColor: '#077398 !important',
      color: 'white',
      borderRadius: 1,
      fontWeight: 500,
      boxSizing: 'border-box'
    },

    '& .react-datepicker__day--keyboard-selected': {
      backgroundColor: 'white',
      color: 'black',
      boxSizing: 'border-box'
    },

    '& .react-datepicker__day--in-range': {
      backgroundColor: '#B9E8F7',
      color: 'black',
      boxSizing: 'border-box'
    },

    '& .react-datepicker__day--selected:hover': {
      backgroundColor: '#2C8CFF',
      boxSizing: 'border-box'
    },

    '& .react-datepicker__month-text--in-selecting-range': {
      backgroundColor: '#B9E8F7'
    },

    '& .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in--range, .react-datepicker__text--in-range, .react-datepicker__year-text--in-range)':
      { backgroundColor: '#B9E8F7', boxSizing: 'border-box', color: 'black' },

    '& .react-datepicker__day--selected, .react-datepicker__day--range-end': {
      backgroundColor: '#077398 !important',
      color: 'white',
      boxSizing: 'border-box'
    },

    '& .react-datepicker__day-name': {
      width: '2.2rem',
      marginTop: '20px',
      color: '#232F3E',
      boxSizing: 'border-box'
    },

    '& .react-datepicker__day--outside-month': { color: '#E4E7E7', boxSizing: 'border-box' },

    '& .react-datepicker__current-month': {
      fontSize: '24px',
      fontWeight: '500',
      color: '#232F3E',
      boxSizing: 'border-box'
    },

    '& .react-datepicker__navigation': {
      top: 21,
      '&:hover': {
        backgroundColor: '#E6E6E6',
        borderRadius: '2px'
      }
    },

    '& .react-datepicker__navigation-icon--previous': {
      right: 1,
      top: 1
    },

    '& .react-datepicker__navigation-icon--next': {
      left: 1,
      top: 1
    },

    '& .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before':
      {
        borderColor: 'black !important'
      },

    '& .react-datepicker__day--today': {
      textDecoration: 'underline'
    }
  },

  mainDateDisplay: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: '500',
    cursor: 'pointer'
  },

  dateBox: { display: 'flex', fontSize: { mobile: '12px', laptop: '14px' }, color: '#3b3b3b' },

  popOver: { '& .MuiPopover-paper': { borderRadius: '12px' } },

  icon: { position: 'absolute', fontSize: '25px', top: -2 },

  visibleDate: {
    backgroundColor: 'white',
    padding: '10px',
    display: 'flex',
    fontSize: '14px',
    color: '#3b3b3b',
    fontWeight: '500',
    width: '100%',
    borderBottom: '3px solid #F2F2F2'
  },

  boxDate: { display: 'flex', alignItems: 'center', marginRight: '10px', cursor: 'pointer' },

  currentDate: { '&:hover': { borderBottom: '3px solid #2C8CFF', backgroundColor: '#EAF5FF' } },

  calenderIcon: { fontSize: '10px', marginRight: '5px' }
};
export default styles;
