import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { showErrorToast, showSuccessToast } from 'components/common/Toaster';
import { BAD_REQUEST, SUCCESS } from '../../../constants/apiStatusCodes';
import { ERROR } from '../../../constants/constants';
import { getAreaList } from '../../../redux/APICaller/browseInventoryAPIs';
import {
  getStorageListingDetails,
  getWarehouseListByFacilityId
} from '../../../redux/APICaller/masterManagementAPIs';
import { selectAuthStore } from '../../../redux/authSlice';

import { FormWrapper } from '../../common';
import StorageListingFilters from './StorageListingFilters';
import StorageLisitngTable from './StorageListingTable';

const initialPayload = {
  facility: '',
  warehouse: '',
  area: ''
};

const StorageListing = () => {
  const authStore = useSelector(selectAuthStore);

  const [list, setList] = useState([]);
  const [payload, setPayload] = useState(initialPayload);
  const [isLoading, setIsLoading] = useState(true);
  const [tableRows, setTableRows] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [pageData, setPageData] = useState({
    currentPage: 1,
    maxPage: 0
  });

  const dispatch = useDispatch();

  const { control, watch, setValue } = useForm({
    defaultValues: {
      facility: authStore.warehouseLocation,
      warehouse: '',
      area: ''
    },
    mode: 'all',
    criteriaMode: 'all'
  });

  /**
   * Fetching warehouse list based on facility
   */
  const { data: warehouseList = {}, isLoading: facilityLoading } = useQuery({
    queryKey: 'warehouseList',
    queryFn: async () =>
      getWarehouseListByFacilityId({
        facilityId: authStore.warehouseLocation
      }),
    enabled: !!watch('facility')
  });

  /**
   * Fetching area List
   */
  const { data: areaList = { data: [] }, isLoading: areaLoading } = useQuery({
    queryKey: 'area',
    queryFn: () => getAreaList()
  });

  const APICALL = async (page = 1) => {
    setIsLoading(true);
    let customPayload = {};

    if (page !== 'reset') {
      if (payload.facility) customPayload.facility_id = payload.facility;
      if (payload.warehouse) customPayload.warehouse_id = payload.warehouse;
      if (payload.area) customPayload.area_id = payload.area;
    } else {
      setPayload(initialPayload);
    }

    const res = await getStorageListingDetails({
      page: page === 'reset' ? 0 : page - 1,
      size: 15,
      ...customPayload
    });

    if (res.status === SUCCESS) {
      if (page === 1 || page === 'reset') {
        const newData = res?.data?.list;
        setList([{ page: 1, array: newData }]);
      } else setList([...list, { page, array: res?.data?.list }]);
      setPageData({ maxPage: res.data.total_pages, currentPage: page });
      if (initialLoad) setInitialLoad(false);
    } else {
      if (res.status === BAD_REQUEST) showErrorToast(res?.data?.message);
      else showErrorToast();
    }
    setIsLoading(false);
  };

  const isDataExist = (page) => {
    let found = false;
    list?.map((item) => {
      if (item.page === page) {
        found = true;
      }
    });
    return found;
  };

  const apiCallWithPage = (page) => {
    if (!isDataExist(page)) APICALL(page);
    else setPageData({ ...pageData, currentPage: page });
  };

  const setRowsWithPage = (page = 1) => {
    list?.map((item) => {
      if (item.page === page) {
        setTableRows(item.array);
      }
    });
  };

  const pageClick = (_, pageNumber) => {
    apiCallWithPage(pageNumber);
  };

  useEffect(() => {
    setRowsWithPage(pageData.currentPage);
  }, [pageData.currentPage, list]);

  useEffect(() => {
    setValue('facility', authStore.warehouseLocation);
  }, [authStore]);

  useEffect(() => {
    watch('facility') !== '' && APICALL();
  }, [payload]);

  return (
    <FormWrapper heading="Storage Listing">
      <StorageListingFilters
        control={control}
        watch={watch}
        data={payload}
        setData={setPayload}
        warehouseList={warehouseList.data}
        areaList={areaList.data}
      />
      <StorageLisitngTable
        list={tableRows}
        pageData={pageData}
        pageClick={pageClick}
        isLoading={isLoading || facilityLoading || areaLoading}
        page={pageData.currentPage}
      />
    </FormWrapper>
  );
};
export default StorageListing;
