const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      md: 'row'
    },
    gap: 4,
    justifyContent: 'space-between'
  },

  warehouseWrapper: {
    width: {
      xs: '100%',
      md: '30%'
    }
  },

  dropDownWrapper: {
    padding: '8px 10px',
    borderBottom: '1px solid #EAEBEE',
    '& .selectClass': {
      border: 'none'
    }
  },

  accordianContentWrapper: {
    padding: '15px 10px'
  },

  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.6rem',
    border: '1px solid #EAEBEE',
    // padding: '15px 20px',
    borderRadius: '24px',
    backgroundColor: 'white'
  },

  warehouseWrapperChildren: {
    gap: '0.6rem'
  },

  zoneWrapper: {
    width: {
      xs: '100%',
      md: '68%'
    }
  },

  button: {
    height: '45px'
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem'
  },

  form: {
    width: '100%'
  },

  formWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    rowGap: '20px'
  },

  input: {
    width: '47%'
  },

  radioButton: {
    '& .MuiFormGroup-root': {
      display: 'flex',
      flexDirection: 'row'
    },
    width: '47%'
  },

  accordianWrapper: {
    margin: '0',
    backgroundColor: '#FFFAF2'
  },

  accordianItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    fontSize: '13.5px',
    padding: '10px 25px'
  }
};

export default styles;
