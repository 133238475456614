import { Box } from '@mui/material';
import React from 'react';
import { Input, Radio, Dropdown } from '../../../common';
import styles from '../styles';
import { selectAuthStore } from '../../../../redux/authSlice';
import { useSelector } from 'react-redux';

const CreateWarehouse = ({ control, loading, disable }) => {
  const authStore = useSelector(selectAuthStore);

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: { xs: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)' },
        alignItems: 'center',
        rowGap: '20px',
        columnGap: { xs: '2rem', md: '4rem' }
      }}>
      <Input
        isRequired
        disabled={disable}
        name="warehouse_name"
        label="Warehouse Name"
        loading={loading}
        control={control}
        rules={{ required: 'This is a required field' }}
      />
      <Input
        disabled={disable}
        name="warehouse_code"
        label="Warehouse Code"
        loading={loading}
        control={control}
        isRequired
        rules={{ required: 'This is a required field' }}
      />
      <Dropdown
        disabled={disable}
        name="facility_id"
        label="Facility ID"
        loading={loading}
        control={control}
        isRequired
        options={authStore?.warehouseLocationList}
        rules={{ required: 'This is a required field' }}
      />
      <Input
        disabled={disable}
        name="e_com_site_code"
        label="Econ Site Code"
        loading={loading}
        control={control}
        // customStyles={styles.input}
        isRequired
        rules={{ required: 'This is a required field' }}
      />
      <Radio
        disabled={disable}
        name="status"
        label="Status"
        loading={loading}
        isRequired
        control={control}
        customStyles={styles.radioButton}
        options={[
          {
            label: 'Enable',
            value: true
          }
          // {
          //   label: 'Disable',
          //   value: false
          // }
        ]}
      />
    </Box>
  );
};

export default CreateWarehouse;
