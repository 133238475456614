import { Box } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { showErrorToast, showSuccessToast } from 'components/common/Toaster';
import { useNavigate } from 'react-router-dom';
import { SUCCESS } from '../../../constants/apiStatusCodes';
import { ERROR_MESSAGE, ERROR_MSG, SUCCESS_MSG } from '../../../constants/constants';
import {
  getUserProfileDetails,
  updateUserProfileDetails
} from '../../../redux/APICaller/userProfileAPIs';

import { Form, FormWrapper } from '../../common';
import AccountInformation from './AccountInformation';
import BillingAddress from './BillingAddress';
import ContactInformation from './ContactInformation';

const UserProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { control, trigger, getValues, watch, setValue, reset } = useForm({
    defaultValues: {
      company_name: '',
      email: '',
      first_name: '',
      last_name: '',
      phone_number: '',
      contact_full_address: '',
      country: '',
      state: '',
      zip_code: '',
      city: '',
      billing_full_address: ''
    },
    mode: 'all',
    criteriaMode: 'all'
  });

  const forms = [
    {
      label: 'Account information',
      form: AccountInformation
    },
    {
      label: 'Contact information',
      form: ContactInformation
    },
    {
      label: 'Billing Address',
      form: BillingAddress
    }
  ];

  /**
   * Getting user information
   */
  const { data = {}, isLoading } = useQuery({
    queryKey: 'userData',
    queryFn: () => getUserProfileDetails()
  });

  /**
   * Making new request to update user profile
   */
  const { isPending, mutate } = useMutation({
    mutationFn: (data) => updateUserProfileDetails(data),
    onSuccess: (res) => {
      if (res.status === SUCCESS) {
        showSuccessToast(res?.data?.message);
      } else {
        showErrorToast(res?.data?.message);
      }
      navigate('/getting-started');
    },
    onError: () => {
      showErrorToast();
    }
  });

  const handleClick = (res) => {
    if (res) {
      mutate(getValues());
    }
  };

  useEffect(() => {
    reset(data.data);
  }, [reset, isLoading]);

  return (
    <FormWrapper
      loading={isPending || isLoading}
      heading="Profile Setup"
      buttonClick={() => trigger().then((res) => handleClick(res))}>
      {forms?.map(({ label, form: FormRenderer }, index) => {
        return (
          <Box key={index}>
            <Form heading={label} customStyles={{ maxHeight: 'min-content' }}>
              <FormRenderer
                control={control}
                watch={watch}
                setValue={setValue}
                loading={isPending || isLoading}
                getValues={getValues}
              />
            </Form>
          </Box>
        );
      })}
    </FormWrapper>
  );
};

export default UserProfile;
