import USER_PROFILE_PATHS from '../../constants/APIPaths/UserProfile';
import { getAPI, putAPI } from '../../helpers/API';

const { UPDATE_USER_PROFILE, GET_USER_PROFILE, GET_COUNTRY_DATA, GET_CITY_DATA, GET_STATE_DATA } =
  USER_PROFILE_PATHS;

export function updateUserProfileDetails(data) {
  return putAPI({
    url: UPDATE_USER_PROFILE,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

export function getUserProfileDetails(data) {
  return getAPI({
    url: GET_USER_PROFILE,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

export function getCountryList(data) {
  return getAPI({
    url: GET_COUNTRY_DATA,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}
export function getStateList(data) {
  return getAPI({
    url: GET_STATE_DATA.replace(':id', data?.id),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}
export function getCityList(data) {
  return getAPI({
    url: GET_CITY_DATA.replace(':id', data?.id),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}
