const styles = {
  wrapper: {
    position: 'relative',
    width: '100%'
  },

  inputBox: {
    display: 'none'
  },

  input: {
    padding: '2rem',
    backgroundColor: '#F4F6FC',
    borderRadius: '12px',
    border: 'dashed',
    borderColor: 'rgb(34, 83, 237,0.6)',
    borderDash: '15px',
    color: '#E9B519',
    textDecoration: 'underline',
    fontWeight: 400,
    textAlign: 'center',
    fontSize: 14
  },

  error: {
    position: 'absolute'
  }
};

export default styles;
