const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    height: '100%'
  },

  paginationWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    height: '100%'
  }

  // tableWrapper: {
  //   width: '100%',
  //   height: 470,
  //   overflowY: 'auto',
  //   fontFamily: "'Poppins', sans-serif",
  //   '& .ag-header': {
  //     height: '75px !important',
  //     backgroundColor: '#F8F8F9',
  //     borderBottom: '1px solid #EBEBEB'
  //   },

  //   '& .ag-row': {
  //     borderBottom: '1px solid #EBEBEB'
  //   },

  //   '& .ag-header-cell': {
  //     height: '70px !important'
  //   },

  //   '& .ag-header-cell-text': {
  //     fontWeight: 500,
  //     fontSize: '16px'
  //   },

  //   '& .ag-cell': {
  //     fontSize: '14.5px',
  //     display: 'flex',
  //     alignItems: 'center'
  //   },

  //   '& .ag-root-wrapper': {
  //     borderRadius: '12px',
  //     borderColor: '#EBEBEB'
  //   }
  // }
};

export default styles;
