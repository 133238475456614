import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box } from '@mui/material';
import { DynamicButton, Input, MainContent } from 'components/common';
import { showErrorToast, showSuccessToast } from 'components/common/Toaster';
import { BAD_REQUEST, SUCCESS } from 'constants/apiStatusCodes';
import { consigmentType } from 'constants/commonLabels';
import { trailerStatus } from 'constants/messages';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAcceptConsignmentDetails } from 'redux/APICaller';
import { assignDock, getDockList, getInvoiceDetails } from 'redux/APICaller/receiveOnDockAPIs';

import styles from './styles';
import DropDown from 'components/common/FormComponents/Dropdown';

const showInvoiceDetails = (invoiceData) => {
  if (invoiceData.length === 0) return <Box sx={{ textAlign: 'center' }}>No Invoice to show</Box>;
  return (
    <Box sx={styles.invoiceDetailsWrapper}>
      <Box sx={styles.invoiceHeading}>Invoice Details</Box>
      <Box sx={styles.detailsWrapper}>
        {invoiceData.map(({ invoiceNumber, shipToCity, noOfUnits, name }, index) => {
          return (
            <Box key={`${invoiceNumber + '-' + index}`}>
              <Box sx={styles.detailsHeading}>{`${index + 1}. ${invoiceNumber}`}</Box>
              <Box sx={styles.details}>
                <Box sx={styles.singleDetail}>
                  <Box>Name</Box>
                  <Box>{name}</Box>
                </Box>
                <Box sx={styles.singleDetail}>
                  <Box>Ship to city</Box>
                  <Box>{shipToCity}</Box>
                </Box>
                <Box sx={styles.singleDetail}>
                  <Box>Quantity</Box>
                  <Box>{noOfUnits}</Box>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

const ReceiveTrailerOnDock = () => {
  const dataKeyNameChange = useMemo(
    () => (arrayOfObj) => {
      const newArrayOfObj = arrayOfObj?.map(({ dockCode: label, dock_id: value, ...rest }) => ({
        label,
        value,
        ...rest
      }));

      return newArrayOfObj;
    },
    []
  );

  const [invoiceData, setInvoiceData] = useState([]);
  const [dockList, setDockList] = useState([]);
  const [data, setData] = useState({});
  const [confirm, setConfirm] = useState(false);
  const [showNumberOfInvoice, setshowNumberOfInvoice] = useState(false);

  const params = useParams();
  const dispatch = useDispatch();

  const { control, trigger, watch, setValue } = useForm({
    defaultValues: {
      chooseDock: ''
    },
    mode: 'onBlur',
    criteriaMode: 'all'
  });

  const fetchInvoiceDetails = async (tripId) => {
    const res = await getInvoiceDetails({ tripId });
    if (res?.status == SUCCESS) {
      setInvoiceData(res?.data);
    } else {
      if (res?.status === BAD_REQUEST) showErrorToast(res?.data?.message);
      else showErrorToast('Something Went Wrong');
    }
  };

  const APICALL = async () => {
    const res = await getAcceptConsignmentDetails({
      acn_no: params?.acnId
    });
    if (res.status === SUCCESS) {
      setValue('truckNumber', res?.data?.truck_number);
      setValue('consignmentType', res?.data?.consignment_type);

      if (res?.data?.consignment_type !== consigmentType?.inBound) {
        setshowNumberOfInvoice(true);
        fetchInvoiceDetails(res?.data?.trip_id);
        setValue('numberOfInvoice', res?.data?.no_of_invoices);
      }

      setData(res?.data);
    } else {
      if (res?.status === BAD_REQUEST) showErrorToast(res?.data?.message);
      else showErrorToast('Something Went Wrong');
    }
  };

  const fetchDockDropList = useCallback(async () => {
    const res = await getDockList({ acn_no: params?.acnId });
    if (res?.status == SUCCESS) {
      setDockList(dataKeyNameChange(res?.data));
      setValue('chooseDock', res?.data[0]?.dock_id);
    } else {
      if (res?.status === BAD_REQUEST) showErrorToast(res?.data?.message);
      else showErrorToast('Something Went Wrong');
    }
  }, [dispatch, setValue, getDockList, params]);

  useEffect(() => {
    APICALL();
    fetchDockDropList();
  }, []);

  /**
   * Functions to call API and Validate fields
   */

  const assignDockApiCall = useCallback(async () => {
    const res = await assignDock({
      truck_number: watch('truckNumber'),
      dock_id: watch('chooseDock'),
      trip_id: data?.trip_id
    });
    if (res.status === SUCCESS) {
      setConfirm(true);
      showSuccessToast(res?.data?.message || trailerStatus);
    } else {
      if (res?.status === BAD_REQUEST) showErrorToast(res?.data?.message);
      else showErrorToast('Something Went Wrong');
    }
  }, [dispatch, assignDock, watch, data]);

  const finishExecution = useCallback(
    (res) => {
      if (res) {
        assignDockApiCall();
      }
    },
    [assignDockApiCall]
  );

  const triggerValidation = useCallback(() => {
    trigger().then((res) => finishExecution(res));
  }, [trigger, finishExecution]);

  return (
    <MainContent heading="Trailer Information" description="List of trailers waiting in bay">
      <Box component="form" sx={styles.wrapper}>
        <Box sx={styles.formWrapper}>
          <Input
            name="truckNumber"
            label="Truck Number"
            disabled
            control={control}
            customStyles={styles.input}
            rules={{ required: 'This is a required field' }}
          />
          <Input
            name="consignmentType"
            label="Consignment Type"
            control={control}
            disabled
            customStyles={styles.input}
            rules={{ required: 'This is a required field' }}
          />
          <DropDown
            disabled={confirm}
            name="chooseDock"
            label="Choose Dock"
            customStyles={styles.input}
            options={dockList}
            control={control}
            rules={{ required: 'This is a required field' }}
          />
          {showNumberOfInvoice && (
            <Box sx={styles.invoiceWrapper}>
              <Box sx={styles.checkInvoice}>
                Check Invoice Here <ArrowForwardIcon sx={styles.icon} />
              </Box>
              <Input
                name="numberOfInvoice"
                label="Number Of Invoice"
                additionalInfo={showInvoiceDetails(invoiceData)}
                disabled
                control={control}
                rules={{ required: 'This is a required field' }}
              />
            </Box>
          )}
        </Box>
        <Box sx={styles.buttonWrapper}>
          <DynamicButton
            disabled={confirm}
            label="Confirm receive on dock"
            onClick={triggerValidation}
            customStyles={styles.button}
          />
        </Box>
      </Box>
    </MainContent>
  );
};

export default ReceiveTrailerOnDock;
