import { Box } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from 'components/common/Toaster';
import { SUCCESS } from 'constants/apiStatusCodes';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { createNewCategory, getCategory } from 'redux/APICaller/warehouseAPIs';
import { Form, FormWrapper } from '../../common';
import CategoryForm from './CategoryForm';

const CreateCategory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  const { control, trigger, getValues, reset } = useForm({
    defaultValues: {
      category_name: '',
      category_code: '',
      status: true
    },
    mode: 'all',
    criteriaMode: 'all'
  });

  const forms = [
    {
      label: 'Create Category',
      form: CategoryForm
    }
  ];

  const { data: categoryData = {} } = useQuery({
    queryKey: 'category',
    queryFn: () => getCategory({ id }),
    enabled: !!id
  });

  const category = useMutation({
    mutationFn: (data) => createNewCategory(data),
    onSuccess: (res) => {
      if (res.status === SUCCESS) {
        showSuccessToast(res?.data?.message);
        if (res?.data?.category_count === 1) navigate('/suppliers');
        navigate('/category-list');
      } else {
        showErrorToast(res?.data?.message);
      }
    },
    onError: () => {
      showErrorToast();
    }
  });

  const handleClick = (res) => {
    if (res) {
      const { category_name, category_code, status } = getValues();
      category.mutate({ category_name, category_code, status });
    }
  };

  useEffect(() => {
    if (id) reset(categoryData.data);
  }, [categoryData.data, id]);

  return (
    <FormWrapper
      loading={category.isPending || id}
      heading="Manage Category"
      buttonClick={() => trigger().then((res) => handleClick(res))}>
      {forms?.map(({ label, form: FormRenderer }, index) => {
        return (
          <Box key={index}>
            <Form heading={label} customStyles={{ maxHeight: '100%' }}>
              <FormRenderer disabled={id} control={control} loading={category.isPending} />
            </Form>
          </Box>
        );
      })}
    </FormWrapper>
  );
};

export default CreateCategory;
