import Box from '@mui/material/Box';
import { useMutation } from '@tanstack/react-query';
import { DynamicButton } from 'components/common';
import Otp from 'components/common/FormComponents/Otp';
import { showErrorToast, showSuccessToast } from 'components/common/Toaster';
import { DefaultSkeletton } from 'components/common/WebSkeleton';
import { INTERNAL_SERVER_ERROR, SUCCESS } from 'constants/apiStatusCodes';
import { ERROR_MESSAGE } from 'constants/constants';
import useTimer from 'hooks/useTimer';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { userForgotPassword, userOtpVerify } from 'redux/APICaller/authAPIs';
import styles from './styles';

const OtpVerification = () => {
  const { control, formState, getValues, setValue, clearErrors, trigger } = useForm({
    defaultValues: {
      otp: ''
    },
    mode: 'all',
    criteriaMode: 'all'
  });

  const { id } = useParams();
  const navigate = useNavigate();

  const {
    errors,
    errors: { otp }
  } = formState;

  const [time, reset, isTimeout] = useTimer(1, 0);
  const [resendTime, resetResendTime, resendIsTimeout] = useTimer(0, 30);

  const { min, sec } = time;
  const resendSec = resendTime.sec < 10 ? `0${resendTime.sec}` : resendTime.sec;

  const isTimedOut = isTimeout();
  const resend = resendIsTimeout();

  const resendCode = useMutation({
    mutationFn: (data) => userForgotPassword(data),
    onSuccess: (res) => {
      reset();
      resetResendTime();
      setValue('otp', '');
      clearErrors('otp');
      res.status === 200
        ? showSuccessToast('OTP sent successfully')
        : showErrorToast(res.data.message);
    },
    onError: (err) => {
      showErrorToast();
    }
  });

  const verifyCode = useMutation({
    mutationFn: (data) => userOtpVerify(data),
    onSuccess: (res) => {
      if (res.status === SUCCESS) {
        if (res.data.message) showSuccessToast(res.data.message);
        const resetToken = res?.data['reset-token'];
        if (resetToken) {
          navigate(`/reset-password/${id}/${resetToken}`);
        }
      } else if (res.status !== INTERNAL_SERVER_ERROR) {
        showErrorToast(res.data.message);
      }
    },
    onError: () => {
      showErrorToast();
    }
  });

  const handleNextbtnClick = (res) => {
    if (res) {
      verifyCode.mutate({ email_address: id, otp: getValues('otp') });
    }
  };

  useEffect(() => {
    clearErrors('otp');
  }, [clearErrors]);

  return (
    <Box sx={styles.container}>
      <Box>
        <Box sx={styles.heading}>Verification Code</Box>
        <Box sx={styles.description}>
          We have sent the OTP code to <b>{id}</b> for the verification process
        </Box>
      </Box>
      <Box sx={styles.wrapper}>
        <DefaultSkeletton
          loading={resendCode.isPending || verifyCode.isPending}
          sx={styles.timeLeftText}
          customStyles={styles.skeletonWrapper}>
          <Box sx={styles.timeLeftText}>Time left to enter your OTP code:</Box>
        </DefaultSkeletton>
        <DefaultSkeletton
          loading={resendCode.isPending || verifyCode.isPending}
          customStyles={styles.skeletonWrapper}
          sx={styles.timer}>
          <Box sx={styles.timer}>
            {min}:{sec < 10 ? `0${sec}` : sec}s
          </Box>
        </DefaultSkeletton>
        <Otp
          name="otp"
          customStyles={styles.otpComponent}
          control={control}
          setValue={setValue}
          getValues={getValues}
          errors={errors}
          rules={{ required: 'This is a required field' }}
          disabled={isTimedOut}
          loading={resendCode.isPending || verifyCode.isPending}
        />
        <DefaultSkeletton
          loading={resendCode.isPending || verifyCode.isPending}
          customStyles={styles.skeletonWrapper}
          sx={styles.resendOtp}>
          {resend ? (
            <Box sx={styles.resendOtp}>
              {"Didn't receive the OTP? "}
              <Box
                component="span"
                className="resend"
                onClick={() => resendCode.mutate({ email_address: id })}>
                Resend
              </Box>
            </Box>
          ) : (
            <Box sx={styles.resendOtp}>
              Wait
              <Box component="span" className="reset">
                {` ${resendSec} `}
              </Box>
              second to resend code
            </Box>
          )}
        </DefaultSkeletton>
        {(otp || isTimedOut) && (
          <Box sx={styles.otpError}>
            <Box component="img" className="img" src="/alert.svg" alt="alert-icon" />
            <Box component="span" className="text">
              {isTimedOut ? 'Please resend OTP' : otp.message}
            </Box>
          </Box>
        )}
      </Box>
      <Box sx={styles.buttonWrapper}>
        <DynamicButton
          label="Next"
          customStyles={styles.button}
          disabled={resendCode.isPending || verifyCode.isPending}
          onClick={() => trigger('otp').then((res) => handleNextbtnClick(res))}
        />
      </Box>
      <Box sx={styles.footer}>
        <DefaultSkeletton
          loading={resendCode.isPending || verifyCode.isPending}
          customStyles={styles.footerLoading}>
          <Box>
            Don’t have an account?{' '}
            <Box component="span" sx={styles.linkers} onClick={() => navigate('/sign-up')}>
              Sign up
            </Box>
          </Box>
        </DefaultSkeletton>
      </Box>
    </Box>
  );
};

export default OtpVerification;
