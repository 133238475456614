const USER_PRIVILEGE_PATHS = {
  GET_USER_GROUP_LIST: '/company/group',
  GET_USER_ROLL_LIST: '/roles',
  DELETE_USER_GROUP: '/company/group/delete',
  CREATE_USER: '/user',
  UPDATE_USER: '/user',
  GET_USER: '/user/:id',
  CREATE_USER_GROUP: '/company/group/create',
  GET_USER_GROUP_EDIT_DETAILS: '/company/group/:id',
  UPDATE_USER_GROUP: '/company/group/update/:id',
  GET_USER_LIST: '/user',
  UPDATE_USER_GROUP_STATUS: '/user/status/update',
  GET_USER_GROUP_LIST_BY_USER_ID: '/users/:userId/groups'
};

export default USER_PRIVILEGE_PATHS;
