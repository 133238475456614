import Box from '@mui/material/Box';
import styles from './styles';

const Bar = ({ heading, children, customStyles }) => {
  return (
    <Box sx={{ ...styles.wrapper, ...customStyles }}>
      {heading && <Box sx={styles.heading}>{heading}</Box>}
      <Box>{children}</Box>
    </Box>
  );
};

export default Bar;
