import { Box, Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { Pagination, Spinner, TableDataRenderer, Tooltip } from '../../../common';
import LinkButton from 'components/common/FormComponents/Button/LinkButton';
import styles from '../styles';

const ZoneTable = ({
  list = [],
  pageData,
  pageClick,
  isLoading,
  page,
  areaId,
  facilityId,
  warehouseId,
  addZone,
  btnOnClick
}) => {
  const navigate = useNavigate();

  const [{ width }] = useWindowDimensions();
  const isWebView = width > 1000;

  const columnDefs = [
    {
      field: 'ZoneName',
      headerName: 'Zone Name',
      flex: isWebView ? 1 : 0,
      minWidth: 220,
      cellRenderer: ({ data }) => <Tooltip title={data.ZoneName} dataLength={20} />
    },
    {
      field: 'Category',
      headerName: 'Category',
      flex: isWebView ? 1 : 0,
      minWidth: 220,
      cellRenderer: ({ data }) => <Tooltip title={data.Category} dataLength={20} />
    },
    {
      field: 'StorageType',
      headerName: 'Storage Type',
      flex: isWebView ? 1 : 0,
      minWidth: 220,
      cellRenderer: ({ data }) => (
        <Tooltip title={data?.StorageType ? data?.StorageType : 'No Data'} />
      )
    },
    {
      field: 'Navigator',
      headerName: '',
      minWidth: 250,
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => {
        return (
          <Box>
            <Button
              sx={{
                '&:hover': {
                  backgroundColor: '#FFEFD3'
                }
              }}
              onClick={() =>
                navigate(`/zone-storage/${facilityId}/${areaId}/${data?.zone_id}/${warehouseId}`)
              }>
              View Storage
            </Button>
          </Box>
        );
      }
    }
  ];
  addZone, btnOnClick;
  return (
    <Box sx={styles.tabelWrapper}>
      {isLoading ? <Spinner /> : <TableDataRenderer list={list} columnDefs={columnDefs} />}
      <Box sx={styles.paginationWrapper}>
        <Box>
          <LinkButton
            label={!addZone ? 'Add Zone' : 'Cancel'}
            onClick={btnOnClick}
            customStyles={styles.linkButton}
          />
        </Box>
        <Pagination
          count={pageData?.maxPage}
          page={page}
          onChange={(e, pageNumber) => {
            pageClick(e, pageNumber);
          }}
        />
      </Box>
    </Box>
  );
};

export default ZoneTable;
