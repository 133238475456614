import { Navigate, Route, Routes } from 'react-router-dom';
import Dashboard from '../components/Dashboard';
import { Error, SuccessPage, ClearAll } from '../components/features';
import { authentication, defaultRoutes } from '../constants/routes';
import { AuthLayout } from '../layout';
import PrivateRoute from './PrivateRoute';

const accessFoundOrNot = (mainString, subString) => {
  if (subString === 'whitelist') return true;
  // // const stringsArray = mainString?.split(',');
  // // let found = false;
  // // stringsArray?.forEach((string) => {
  // //   if (string?.trim() === subString?.trim()) {
  // //     found = true;
  // //   }
  // // });
  const found = mainString?.includes(subString);
  return found;
};

const AppRouter = ({ facilityFetched }) => {
  const token = localStorage.getItem('wms_access_token');
  const isLoggedIn = !!token;

  // Use this when roles come from backend
  const roleList = localStorage.getItem('wms_access_roles');

  return (
    <Routes>
      {authentication?.map(({ path, element: Element }, index) => {
        return (
          <Route
            key={index}
            path={path}
            element={
              isLoggedIn ? (
                <Navigate to="/" />
              ) : (
                <AuthLayout>
                  <Element />
                </AuthLayout>
              )
            }
          />
        );
      })}

      {defaultRoutes?.map(({ path, element: Element, role }, index) => {
        return (
          <Route
            key={index}
            path={path}
            element={
              accessFoundOrNot(roleList, role) ? (
                <PrivateRoute>
                  <Dashboard facilityFetched={facilityFetched}>
                    <Element />
                  </Dashboard>
                </PrivateRoute>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        );
      })}
      <Route path="/cache-clear" element={<ClearAll />} />
      <Route path="/success" element={<SuccessPage />} />
      <Route path="*" element={<Error />} />
    </Routes>
  );
};

export default AppRouter;
