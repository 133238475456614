import { Box } from '@mui/material';
import React, { useEffect, useCallback } from 'react';
import { Dropdown, Form, Input, Radio } from '../../../common';
import { useDispatch, useSelector } from 'react-redux';
import { showErrorToast, showSuccessToast } from 'components/common/Toaster';
import { selectAppStore, setProductCategoryList } from '../../../../redux/appSlice';
import { getCategoryList } from '../../../../redux/APICaller';
import { BAD_REQUEST, SUCCESS } from '../../../../constants/apiStatusCodes';
import { CategoryListConverter } from '../../../../constants/apiDataChanger';
import { ERROR_MSG } from '../../../../constants/constants';

import styles from '../styles';

const CreateZone = ({ control, handleSaveClick, disabled, loading }) => {
  const dispatch = useDispatch();
  const { productCategoryList } = useSelector(selectAppStore);

  const getProductCategoryDropdownData = useCallback(async () => {
    if (!productCategoryList?.length) {
      const res = await getCategoryList({});
      if (res?.status == SUCCESS) {
        dispatch(setProductCategoryList(CategoryListConverter(res?.data)));
      } else {
        if (res?.status === BAD_REQUEST) showErrorToast(res?.data?.message);
        else showErrorToast();
      }
    }
  }, [dispatch, productCategoryList]);

  useEffect(() => {
    getProductCategoryDropdownData();
  }, []);

  return (
    <Form
      heading="Zone"
      customStyles={styles.form}
      buttonLabel="Save"
      buttonClick={() => {
        handleSaveClick();
      }}>
      <Box sx={styles.formWrapper}>
        <Input
          disabled={disabled}
          name="zone_name"
          label="Zone Name"
          control={control}
          customStyles={styles.input}
          isRequired
          loading={loading}
          rules={{ required: 'This is a required field' }}
        />
        <Input
          disabled={disabled}
          name="picklist_quantity"
          label="Max Picked Quantity"
          control={control}
          customStyles={styles.input}
          // isRequired
          loading={loading}
          onKeyPress={(event) => {
            if (!/[0-9+]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          // rules={{
          //   validate: {
          //     minDigits: (value) =>
          //       (value?.length >= 5 && !!value) || 'There should be minimum 5 digits'
          //   }
          // }}
        />
        <Dropdown
          disabled={disabled}
          name="category_id"
          label="Map Category"
          options={productCategoryList}
          control={control}
          customStyles={styles.input}
          isRequired
          loading={loading}
          rules={{ required: 'This is a required field' }}
        />

        <Radio
          disabled={disabled}
          name="status"
          label="Status"
          // isRequired
          loading={loading}
          control={control}
          customStyles={styles.radioButton}
          options={[
            {
              label: 'Enable',
              value: true
            }
          ]}
        />
      </Box>
    </Form>
  );
};

export default CreateZone;
