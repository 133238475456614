import { Box } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { SUCCESS } from '../../../constants/apiStatusCodes';
import { ERROR_MESSAGE, ERROR_MSG, SUCCESS_MSG } from '../../../constants/constants';
import { createNewWarehouse } from '../../../redux/APICaller/warehouseAPIs';
import { showToastMessage } from '../../../redux/showMessageSlice';
import { Form, FormWrapper } from '../../common';
import CreateWarehouse from './CreateWarehouse';
import { showErrorToast, showSuccessToast } from 'components/common/Toaster';
import { getWarehouseDetailsId } from '../../../redux/APICaller/masterManagementAPIs';

const Warehouse = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { control, trigger, getValues, reset } = useForm({
    defaultValues: {
      warehouse_name: '',
      warehouse_code: '',
      facility_id: '',
      e_com_site_code: '',
      status: true
    },
    mode: 'all',
    criteriaMode: 'all'
  });

  const forms = [
    {
      label: 'Create Warehouse',
      form: CreateWarehouse
    }
  ];

  /**
   * Get warehouse information
   */
  const { data = {}, isLoading } = useQuery({
    queryKey: ['warehouse', id],
    queryFn: async () => {
      const res = await getWarehouseDetailsId({ id });

      reset(res?.data);

      return res;
    },
    enabled: !!id
  });

  const warehouse = useMutation({
    mutationFn: (data) => createNewWarehouse(data),
    onSuccess: (res) => {
      if (res.status === SUCCESS) {
        showSuccessToast(res.data.msg || res?.data?.message);
        if (res?.data?.warehouse_count === 1) navigate('/getting-started');
        navigate('/warehouse-zone');
      } else {
        showErrorToast(res.data.msg || res?.data?.message);
      }
    },
    onError: () => {
      showErrorToast();
    }
  });

  const handleClick = (res) => {
    if (res) {
      const { warehouse_name, warehouse_code, facility_id, e_com_site_code, status } = getValues();
      warehouse.mutate({ warehouse_name, warehouse_code, facility_id, e_com_site_code, status });
    }
  };

  return (
    <FormWrapper
      loading={warehouse.isPending || isLoading || id}
      heading="Warehouse"
      buttonClick={() => trigger().then((res) => handleClick(res))}>
      {forms?.map(({ label, form: FormRenderer }, index) => {
        return (
          <Box key={index}>
            <Form heading={label} customStyles={{ maxHeight: '100%' }}>
              <FormRenderer
                control={control}
                loading={warehouse.isPending || isLoading}
                disable={id}
              />
            </Form>
          </Box>
        );
      })}
    </FormWrapper>
  );
};

export default Warehouse;
