import { Box, Button } from '@mui/material';
import { Pagination, Spinner, TableDataRenderer, Tooltip } from 'components/common';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useNavigate } from 'react-router-dom';
import styles from '../styles';

const StorageListingTable = ({ list = [], pageData, pageClick, page, isLoading }) => {
  const [{ width }] = useWindowDimensions();
  const isWebView = width > 1000;
  const navigate = useNavigate();

  const columnDefs = [
    {
      field: 'facility_name',
      headerName: 'Facility',
      minWidth: 250,
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => (
        <Tooltip title={data.facility_name ? data.facility_name : 'No data'} />
      )
    },
    {
      field: 'warehouse_name',
      headerName: 'Warehouse',
      minWidth: 250,
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => (
        <Button onClick={() => navigate(`/warehouse/${data.warehouse_id}`)}>
          {data?.warehouse_name}
        </Button>
      )
    },
    {
      field: 'area_name',
      headerName: 'Area',
      minWidth: 250,
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => <Tooltip title={data.area_name ? data.area_name : 'No data'} />
    },
    {
      field: 'zone_count',
      headerName: 'Zone',
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => <Tooltip title={data.zone_count ? data.zone_count : 'No data'} />
    },
    {
      field: 'aisle_count',
      headerName: 'Aisle',
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => (
        <Tooltip title={data.aisle_count ? data.aisle_count : 'No data'} />
      )
    },
    {
      field: 'shelf_count',
      headerName: 'Shelf',
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => (
        <Tooltip title={data.shelf_count ? data.shelf_count : 'No data'} />
      )
    },
    {
      field: 'rack_count',
      headerName: 'Rack',
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => <Tooltip title={data.rack_count ? data.rack_count : 'No data'} />
    },
    {
      field: 'bin_count',
      headerName: 'Bin',
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => <Tooltip title={data.bin_count ? data.bin_count : 'No data'} />
    }
  ];

  return (
    <Box sx={styles.wrapper}>
      {isLoading ? <Spinner /> : <TableDataRenderer list={list} columnDefs={columnDefs} />}
      <Box sx={styles.paginationWrapper}>
        <Pagination
          count={pageData?.maxPage}
          page={page}
          onChange={(e, pageNumber) => pageClick(e, pageNumber)}
        />
      </Box>
    </Box>
  );
};

export default StorageListingTable;
