const IRN_PATHS = {
  GET_IRN_DETAILS: `/consignment/:acnId/irn`,
  IRN_DETAILS_UPLOAD: '/irn/:acnId/:typeOfFile',
  PUT_IRN_DETAILS: '/irn/:irnId',
  GET_IRN_ITEM_LIST: '/irn/:irnId/items',
  GET_SKU_PARTS_LIST: '/sku/:sku_id',
  PATCH_IRN_DETAILS: '/irn/:irn_id/item/details',
  GET_RETURN_IRN_ITEM_LIST: '/irn/:irnId/item/details',
  PUT_FINAL_RETURN_IRN_ITEM_DETAILS: '/irn/receive/quantity',
  POST_GENERATE_BARCODE: '/irn/generate/barcode',
  POST_VIEW_IRN_STOCK: '/irn/details'
};

export default IRN_PATHS;
