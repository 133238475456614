import { Box } from '@mui/material';
import React from 'react';
import { Input } from '../../../common';
import styles from '../styles';

const AccountInformation = ({ control, loading = false }) => {
  return (
    <Box sx={styles.formWrapper}>
      <Input
        name="company_name"
        label="Company Name"
        isRequired
        disabled
        loading={loading}
        customStyles={styles.input}
        control={control}
        rules={{ required: 'This is a required field' }}
      />
      <Input
        name="email"
        label="E-mail"
        isRequired
        disabled
        loading={loading}
        customStyles={styles.input}
        control={control}
        rules={{
          required: 'This is a required field',
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: 'Please provide a valid email'
          }
        }}
      />
    </Box>
  );
};

export default AccountInformation;
