import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DropDown from 'components/common/FormComponents/Dropdown';
import CustomPopover from 'components/common/PopOver';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectAuthStore } from 'redux/authSlice';
import { Mixpanel } from 'mixpanel';
import styles from './styles';

const activePaths = [
  //Outbound
  '/create-so',
  '/all-orders',
  '/pick',
  '/pack',
  '/manifest',
  '/put-back',
  '/create-ro',
  '/return-manifest'
];

const isStringInArray = (targetString, stringArray) => {
  return stringArray.includes(targetString);
};

const Navbar = ({ showStepper, setShowStepper, yms, warehouse, orderType }) => {
  const location = useLocation();
  const authStore = useSelector(selectAuthStore);
  const navigate = useNavigate();
  const [activeIcon, setActiveIcon] = useState('');

  const { control, watch, setValue } = useForm({
    defaultValues: {
      yms: '',
      warehouse: '',
      orderType: ''
    },
    mode: 'all'
  });

  useEffect(() => {
    setValue('warehouse', warehouse);
    setValue('yms', yms);
    setValue('orderType', orderType);
  }, [yms, warehouse, orderType]);

  const warehouseValue = watch('warehouse');
  const orderTypeValue = watch('orderType');

  useEffect(() => {
    if (warehouseValue && localStorage.getItem('facility_id') !== warehouseValue) {
      localStorage.setItem('facility_id', warehouseValue);
      window?.location?.reload(false);
    }
  }, [warehouseValue]);

  useEffect(() => {
    if (orderTypeValue && localStorage.getItem('order_type') !== orderTypeValue) {
      localStorage.setItem('order_type', orderTypeValue);
      window?.location?.reload(false);
    }
  }, [orderTypeValue]);

  return (
    <Box sx={styles.navBarWrapper}>
      <Box sx={styles.navBarIcon}>
        <Box sx={styles.logoWrapper}>
          <IconButton onClick={() => setShowStepper(!showStepper)} sx={styles.menuWrapper}>
            <MenuIcon sx={styles.menuIcon} />
          </IconButton>
          <Box
            onClick={() => navigate('/')}
            component="img"
            src="/losungLogo.png"
            alt="losung logo"
            sx={styles.navBarLogo}
          />
          {/* <Box sx={styles.divider} /> */}
        </Box>
        <Box sx={styles.dropDownWrapper}>
          <Box sx={styles.dropDown}>
            <DropDown
              name="yms"
              disabled={authStore?.applicationTypeList?.length === 1 ? true : false}
              control={control}
              options={authStore?.applicationTypeList}
            />
          </Box>
          <Box sx={styles.dropDown}>
            <DropDown
              name="warehouse"
              disabled={authStore?.warehouseLocationList?.length <= 1 ? true : false}
              control={control}
              options={authStore?.warehouseLocationList}
            />
          </Box>
          <Box sx={styles.dropDown}>
            <DropDown
              name="orderType"
              disabled={
                authStore?.orderTypeList?.length === 1 ||
                !(
                  location?.pathname?.includes('/all-orders/') ||
                  isStringInArray(location?.pathname, activePaths)
                )
                  ? true
                  : false
              }
              control={control}
              options={authStore?.orderTypeList}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={styles.iconWrapper}>
        <Box sx={styles.mobileDropDown}>
          <CustomPopover icon={<LocalShippingIcon sx={styles.menuIcon} />}>
            <Box sx={styles.mobileDropdown} component="form">
              <Box>
                <DropDown
                  name="yms"
                  label="YMS"
                  disabled={authStore?.applicationTypeList?.length === 1 ? true : false}
                  control={control}
                  options={authStore?.applicationTypeList}
                />
              </Box>
              <Box>
                <DropDown
                  name="warehouse"
                  label="Warehouse"
                  disabled={authStore?.warehouseLocationList?.length <= 1 ? true : false}
                  control={control}
                  options={authStore?.warehouseLocationList}
                />
              </Box>
              <Box>
                <DropDown
                  name="orderType"
                  label="Order Type"
                  disabled={
                    authStore?.orderTypeList?.length === 1 ||
                    !(
                      location?.pathname?.includes('/all-orders/') ||
                      isStringInArray(location?.pathname, activePaths)
                    )
                      ? true
                      : false
                  }
                  control={control}
                  options={authStore?.orderTypeList}
                />
              </Box>
            </Box>
          </CustomPopover>
        </Box>
        <IconButton>
          <NotificationsIcon sx={styles.icon} />
        </IconButton>
        <CustomPopover
          set={'settings'}
          setActiveIcon={setActiveIcon}
          icon={
            <SettingsIcon
              sx={{ ...styles.icon, color: activeIcon === 'settings' ? '#E9B519' : 'black' }}
            />
          }>
          <Box
            onClick={() => {
              Mixpanel.track('Logout');
              Mixpanel.reset();
              localStorage.clear();
              window.location.href = '/sign-in';
            }}
            sx={styles.logOutWrapper}>
            Log Out
          </Box>
        </CustomPopover>
      </Box>
    </Box>
  );
};

export default Navbar;
