const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    padding: {
      xs: '20px',
      sm: '20px 30px'
    },
    border: '1px solid #E1E3E5',
    backgroundColor: '#FFFFFF',
    borderRadius: '0px 0px 12px 12px',
    borderTop: 'none'
  },

  heading: {
    fontSize: '16px',
    fontWeight: 600
  },

  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    rowGap: 3,
    flexWrap: 'wrap',
    marginTop: 3
  },

  input: {
    width: {
      xs: '47%',
      sm: '32%'
    }
  },

  modalRadio: {
    '& .MuiFormGroup-root': {
      display: 'flex',
      flexDirection: 'row'
    }
  },

  actionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    padding: {
      xs: '20px',
      sm: '20px 30px'
    },
    border: '1px solid #E1E3E5',
    backgroundColor: '#FFFFFF',
    borderRadius: '12px 12px 0px 0px'
  },

  actionButtonWrapper: {
    display: 'flex',
    gap: 2
  },

  actionButton: {
    fontWeight: 500,
    cursor: 'pointer',
    borderRadius: '7px',
    padding: '10px 30px'
  },

  contactInfo: {
    marginBottom: '2rem'
  },

  colorRed: {
    color: 'red'
  },

  eyePosition: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: 10
  },

  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  updatePassword: {
    color: 'primary.main',
    cursor: 'pointer',
    textDecoration: 'underline'
  }
};

export default styles;
