import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { setAppDisable } from 'redux/authSlice';

const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem('wms_access_token');
  const isLoggedIn = !!token;
  const wmsRoles = localStorage.getItem('wms_access_roles');
  const dispatch = useDispatch();

  if (wmsRoles) {
    dispatch(
      setAppDisable({
        appDisable: false
      })
    );
  }

  if (isLoggedIn) {
    return children;
  } else {
    return <Navigate to="/sign-in" />;
  }
};

export default PrivateRoute;
