import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  selectAuthStore,
  setWarehouseLocationAndType,
  setWarehouseLocationAndTypeList
} from './redux/authSlice';
import AppRouter from './router/AppRouter';
import theme from './styles/theme';
import { AlertMessage } from './components/common';
import { showToastMessage } from './redux/showMessageSlice';
import { BAD_REQUEST, SUCCESS } from '../src/constants/apiStatusCodes';
import { ERROR } from '../src/constants/constants';
import { getActiveFacilityList } from './redux/APICaller/userFacilityAPIs';
import { showErrorToast, showSuccessToast } from 'components/common/Toaster';

const queryClient = new QueryClient();

const dataKeyNameChange = (arrayOfObj) => {
  const newArrayOfObj = arrayOfObj?.map(
    ({ facility_id: value, location_code: label, ...rest }) => ({
      value,
      label,
      ...rest
    })
  );

  return newArrayOfObj;
};

const ymsOption = [
  {
    label: 'WMS',
    value: 1
  }
];

// const warehouseOption = [
//   {
//     label: 'G5',
//     value: '053e1f13-f6e1-11ed-a897-027ab0f22944'
//   },
//   {
//     label: 'G6',
//     value: '053e24c1-f6e1-11ed-a897-027ab0f22944'
//   },
//   {
//     label: 'G7',
//     value: '053e2584-f6e1-11ed-a897-027ab0f22944'
//   },
//   {
//     label: 'WB-KK',
//     value: '53082f25-2f78-11ee-a897-027ab0f22944'
//   }
// ];

const orderTypeOption = [
  {
    label: 'B2B',
    value: 'B2B'
  },
  {
    label: 'B2C',
    value: 'B2C'
  }
];

const isFacilityExistInList = (arr, str) => {
  let found = false;
  arr.forEach((obj) => {
    if (obj.value === str) {
      found = true;
      return;
    }
  });
  return found;
};

const App = () => {
  const dispatch = useDispatch();
  const authStore = useSelector(selectAuthStore);
  const [warehouseList, setWarehouseList] = useState([]);
  const [facilityFetched, setFacilityFetched] = useState(false);

  const getActiveFacilityDropdownData = async () => {
    const res = await getActiveFacilityList({});

    if (res?.status === SUCCESS) {
      setWarehouseList(dataKeyNameChange(res?.data));
      setFacilityFetched(true);
    } else {
      if (res?.status === BAD_REQUEST) showErrorToast(res?.data?.message);
      else if (res?.status !== 403) showErrorToast();
      if (res?.status === 403) {
        setFacilityFetched(true);
      }
    }
  };

  const isLoggedIn = !!localStorage.getItem('wms_access_token');
  useEffect(() => {
    if (isLoggedIn && !authStore?.warehouseLocationList?.length) getActiveFacilityDropdownData();
  }, [isLoggedIn]);

  useEffect(() => {
    dispatch(
      setWarehouseLocationAndTypeList({
        applicationTypeList: ymsOption,
        warehouseLocationList: warehouseList,
        orderTypeList: orderTypeOption
      })
    );
  }, [warehouseList]);

  useEffect(() => {
    if (authStore?.warehouseLocationList?.length) {
      const isFacilityExist = isFacilityExistInList(
        authStore?.warehouseLocationList,
        localStorage.getItem('facility_id')
      );

      dispatch(
        setWarehouseLocationAndType({
          applicationType: 1,
          warehouseLocation: localStorage.getItem('facility_id')
            ? isFacilityExist
              ? localStorage.getItem('facility_id')
              : authStore?.warehouseLocationList[0]?.value
            : authStore?.warehouseLocationList[0]?.value,
          orderType: localStorage.getItem('order_type')
            ? localStorage.getItem('order_type')
            : authStore?.orderTypeList[0]?.value
        })
      );
      if (!isFacilityExist)
        localStorage.setItem('facility_id', authStore?.warehouseLocationList[0]?.value);
      if (!isFacilityExist) localStorage.setItem('order_type', authStore?.orderTypeList[0]?.value);
    }
  }, [authStore?.warehouseLocationList?.length]);

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <CssBaseline />
        <AppRouter facilityFetched={facilityFetched} />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <AlertMessage />
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
