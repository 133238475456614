import { Box } from '@mui/material';
import React from 'react';
import { Dropdown, Input } from '../../../../common';
import styles from '../styles';

const storageUnits = [
  {
    label: 'Aisle (4)',
    value: '4'
  },
  {
    label: 'Rack (3)',
    value: '3'
  },
  {
    label: 'Shelf (2)',
    value: '2'
  },
  {
    label: 'Bin (1)',
    value: '1'
  }
];

const StorageUnit = ({ control, loading }) => {
  return (
    <Box sx={styles.formWrapper}>
      <Input
        name="capacity"
        label="Capacity"
        control={control}
        customStyles={styles.input}
        // isRequired
        onKeyPress={(event) => {
          if (!/[0-9+]/.test(event.key)) {
            event.preventDefault();
          }
        }}
        loading={loading}
        // rules={{ required: 'This is a required field' }}
      />
      {/* <Input
        name="storageUnitCode"
        label="Storage Unit Code"
        control={control}
        customStyles={styles.input}
        isRequired
        onKeyPress={(event) => {
          if (!/[0-9+]/.test(event.key)) {
            event.preventDefault();
          }
        }}
        loading={loading}
        rules={{
          required: 'This is a required field',
          validate: {
            minDigits: (value) =>
              (value?.length >= 5 && !!value) || 'There should be minimum 5 digits'
          }
        }}
      /> */}
      <Dropdown
        name="storageUnitType"
        label="Storage Unit Type"
        control={control}
        customStyles={styles.input}
        isRequired
        loading={loading}
        options={storageUnits}
        rules={{ required: 'This is a required field' }}
      />
    </Box>
  );
};

export default StorageUnit;
