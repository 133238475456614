import { useEffect, useState } from 'react';

const secPass = (min = 0, sec = 1) => {
  if (sec === 0) return min > 0 ? { min: min - 1, sec: 59 } : { min, sec: 0 };
  else
    return {
      min,
      sec: sec - 1
    };
};

const useTimer = (minutes = 0, seconds = 0) => {
  const [time, setTime] = useState({ min: minutes, sec: seconds });

  const reset = () => {
    setTime({ min: minutes, sec: seconds });
  };

  const isTimeout = () => {
    if (time.min <= 0 && time.sec <= 0) return true;
    else return false;
  };

  useEffect(() => {
    if (!isTimeout()) {
      const id = setTimeout(() => {
        let newTime = secPass(time.min, time.sec);
        setTime(newTime);
      }, 1000);
      return () => clearTimeout(id);
    }
  });

  return [time, reset, isTimeout];
};

export default useTimer;
