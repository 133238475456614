const styles = {
  wrapper: {
    color: 'black',
    fontSize: {
      xs: '13.6px',
      sm: '16px'
    },
    fontWeight: 600,
    backgroundColor: '#FFFAF2',
    boxShadow: 'none',
    borderRadius: '11px',
    width: {
      xs: '170px',
      sm: '160px'
    },
    height: {
      xs: '46px',
      sm: '60px'
    },
    '&:hover': {
      boxShadow: 'none',
      color: 'white',
      backgroundColor: '#a37e11'
    },

    '&.Mui-disabled': {
      backgroundColor: 'white',
      color: 'custom.darkSilver',
      cursor: 'not-allowed',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'custom.darkSilver',
      pointerEvents: 'all'
    }
  }
};

export default styles;
