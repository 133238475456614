const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    width: {
      xs: '80%',
      sm: '90%'
    },
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center'
  },

  heading: {
    textAlign: 'center',
    fontSize: {
      xs: '22px',
      sm: '26px',
      md: '30px'
    },
    fontWeight: 600,
    color: 'red'
    // marginTop: {
    //   xs: '0',
    //   sm: '60px',
    //   md: '50px'
    // }
  },

  subHeading: {
    textAlign: 'center',
    fontSize: {
      xs: '12px',
      sm: '16px',
      md: '20px'
    },
    fontWeight: 600,
    color: '#707070',
    marginTop: '20px'
  },

  subHeading2: {
    textAlign: 'center',
    fontSize: {
      xs: '12px',
      sm: '14px',
      md: '16px'
    },
    fontWeight: 300,
    color: '#707070'
  },

  button: {
    width: {
      xs: '90%',
      sm: '25%',
      md: '20%'
    },
    backgroundColor: 'red',
    height: '34px',
    color: 'white',
    fontSize: {
      xs: '14px',
      sm: '16px'
    },
    opacity: 0.9,
    marginTop: {
      xs: '10px',
      sm: '20px',
      md: '20px'
    },
    '&:hover': {
      backgroundColor: '#d61204',
      color: 'white'
    }
  },

  description: {
    textAlign: 'center',
    fontSize: {
      xs: '12px',
      sm: '14px',
      md: '16px'
    },
    color: '#707070'
  },

  logoWrapper: {
    position: 'absolute',
    top: 10,
    right: 30
  },

  logo: {
    width: {
      xs: '120px',
      sm: '140px',
      md: '170px'
    }
  }
};

export default styles;
