const regex = {
  email: /\S+@\S+\.\S+/,

  password: {
    isUpper: /[A-Z]/,
    isLower: /[a-z]/,
    isNum: /\d/,
    isSymbol: /[^\p{L}\d\s]/u
  }
};

export default regex;
