const PACKAGING_PATHS = {
  GET_SKU_LIST: '/sku/component/PACKAGING',
  GET_SKU_TABLE_LIST: '/lot/lsin/:id',
  CREATE_LOT: '/lot',
  GET_CREATE_PUTLIST: '/lot/details',
  POST_CREATE_PUTLIST: '/lot/putlist',
  GET_PENDING_PUTLIST: '/lot/putlist/:status',
  PRINT_PUTLIST: '/lot/putlist/print',
  PRINT_WID: '/lot/print/wid/:id',
  PRINT_PICKLIST: '/issue/picklist/print',
  PUTLIST_DETAILS: 'lot/putlist/details/:id',
  CONFIRM_PUTAWAY: '/lot/confirm/putaway/:id',
  GET_SBS_LIST: '/sbs',
  CREATE_ISSUE: '/issue',
  PICKLIST_LISTING: '/lot/issue/picklist',
  CREATE_ISSUE_2_SBS: '/issue/issue-to-sbs/:id',
  GET_CONFIRM_PICKING_DETAILS: '/issue/picklist/:id',
  CONFIRM_PICKING_DETAILS: '/issue/picklist/confirm'
};

export default PACKAGING_PATHS;
