import { Box } from '@mui/material';
import { Button, MainContent, Spinner } from 'components/common';
import { BAD_REQUEST, SUCCESS } from 'constants/apiStatusCodes';
import { ERROR } from 'constants/constants';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { GetUserGroupListByUserId } from 'redux/APICaller/userPrivilegeAPI';
import { showToastMessage } from 'redux/showMessageSlice';
import UserGroupTable from './UserGroupTable';
import { showErrorToast, showSuccessToast } from 'components/common/Toaster';
import styles from './styles';

const dataKeyNameChange = (arrayOfObj) => {
  const newArrayOfObj = arrayOfObj?.map(
    ({ group_id: id, group_name: UserGroup, type: Type, status: Status, ...rest }) => ({
      id,
      UserGroup,
      Type,
      Status,
      ...rest
    })
  );
  return newArrayOfObj;
};

const dataKeyNameChange4edit = (arrayOfObj) => {
  const newArrayOfObj = arrayOfObj?.map(({ name: UserGroup, ...rest }) => ({
    UserGroup,
    ...rest
  }));
  return newArrayOfObj;
};

const UserGroups = () => {
  const [general, setGeneral] = useState(false);
  const [userGroups, setUserGroups] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState([]);
  const [userGroupStatusDefaultValue, setUserGroupStatusDefaultValue] = useState('');
  const [trigger, setTrigger] = useState(0);
  const [btnDisable, setBtnDisable] = useState(false);

  const { id, type } = useParams();

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const dataList = [
    {
      id: '66b65e53-c518-43a5-872f-a455b26c9aca',
      name: 'Manager',
      active: false
    },
    {
      id: '48a89e81-44d3-4036-8cb7-f4d5d3cf4721',
      name: 'Admin',
      active: true
    },
    {
      id: 'd7ac68cd-1f41-4ccc-87ee-2d6fbb5ef292',
      name: 'User',
      active: false
    }
  ];

  const APICALL = async (id) => {
    setIsLoading(true);

    const res = await GetUserGroupListByUserId({ userId: id });

    if (res?.status === SUCCESS) {
      const newData = dataKeyNameChange4edit(res?.data?.list);
      setList(newData);
      // Form Default name obj
      const obj = {};
      res?.data?.list?.map((item) => {
        obj[item?.name] = item?.active;
      });

      setUserGroupStatusDefaultValue(obj);
    } else {
      if (res?.status === BAD_REQUEST) showErrorToast(res?.data?.message);
      else showErrorToast();
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (type === 'assign' || type === 'update') APICALL(id);
  }, [id, type]);

  return (
    <MainContent
      heading="User List"
      navigateLabel="Save"
      navigateClick={() => setTrigger((trigger) => trigger + 1)}>
      <Box sx={styles.actionWrapper}>
        <Box sx={styles.actionButtonWrapper}>
          <Box
            sx={{
              ...styles.actionButton,
              backgroundColor: general ? '#FFEFD3' : '#F4F4F4',
              border: general ? '1px solid #FFAA1B' : 'none'
            }}
            onClick={() => {
              setGeneral(true);
              setUserGroups(false);
              if (type === 'update') navigate(`/update-user/${id}`);
              else navigate('/create-user');
            }}>
            General
          </Box>
          <Box
            sx={{
              ...styles.actionButton,
              backgroundColor: userGroups ? '#FFEFD3' : '#F4F4F4',
              border: userGroups ? '1px solid #FFAA1B' : 'none'
            }}>
            User Groups
          </Box>
        </Box>
      </Box>
      {userGroupStatusDefaultValue ? (
        <UserGroupTable
          userId={id}
          list={list}
          isLoading={isLoading}
          userGroupStatusDefaultValue={userGroupStatusDefaultValue}
          triggerChange={trigger}
          btnDisable={btnDisable}
          setBtnDisable={setBtnDisable}
        />
      ) : (
        <Spinner />
      )}
    </MainContent>
  );
};

export default UserGroups;
