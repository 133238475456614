import { Box } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { showErrorToast, showSuccessToast } from 'components/common/Toaster';
import { useNavigate, useParams } from 'react-router-dom';
import { WarehouseListConvertor } from '../../../constants/apiDataChanger';
import { SUCCESS } from '../../../constants/apiStatusCodes';
import { ERROR_MESSAGE, ERROR_MSG, SUCCESS_MSG } from '../../../constants/constants';
import { getWarehouseList } from '../../../redux/APICaller/index';
import {
  createSupplier,
  getSupplierDetailsById,
  updateSupplier
} from '../../../redux/APICaller/masterManagementAPIs';
import { Form, FormWrapper } from '../../common';
import CreateSuppliers from './CreateSuppliers';
import AddWarehouse from './AddWarehouse';

const Suppliers = () => {
  const navigate = useNavigate();
  const [state, setState] = useState('');
  const { suppliersId: supplierId } = useParams();
  const [selectedWerehouse, setSelectedWerehouse] = useState([]);
  const [addWarehouse, setAddWarehouse] = useState([
    {
      warehouseCode: ''
    }
  ]);
  const { control, trigger, getValues, reset, watch, setValue } = useForm({
    defaultValues: {
      supplier_name: '',
      supplier_code: '',
      gst: '',
      address: '',
      state_id: '',
      city: '',
      zip_code: '',
      status: true
    },
    mode: 'all',
    criteriaMode: 'all'
  });

  const dispatch = useDispatch();

  const forms = [
    {
      label: supplierId ? 'Supplier Details' : 'Create Supplier',
      form: CreateSuppliers
    }
  ];

  if (supplierId)
    forms.push({
      label: 'Add warehouse',
      form: AddWarehouse
    });

  /**
   * Get supplier information
   */
  const { data = {}, isLoading } = useQuery({
    queryKey: ['userData', supplierId],
    queryFn: async () => {
      const res = await getSupplierDetailsById({ supplierId });

      setState(res?.data?.state_id);

      reset(res?.data);
      return res;
    },
    enabled: !!supplierId
  });

  /**
   * Create supplier
   */
  const { isPending, mutate } = useMutation({
    mutationFn: (data) => {
      return createSupplier({ ...data, zip_code: Number(data?.zip_code) });
    },
    onSuccess: (res) => {
      if (res?.status === SUCCESS) {
        showSuccessToast(res?.data?.msg || res?.data?.message);
        navigate(`${res?.data?.id}`);
        // navigate('/supplier-list');
      } else {
        showErrorToast(res?.data?.message);
      }
    },
    onError: () => {
      showErrorToast();
    }
  });

  /**
   * Making  update in supplier details for warehouse
   */
  const { isPending: isPending2, mutate: setWarehouseInSupplier } = useMutation({
    mutationFn: (data) => {
      return updateSupplier(data);
    },
    onSuccess: (res) => {
      if (res?.status === SUCCESS) {
        showSuccessToast(res?.data?.msg || res?.data?.message);

        navigate('/supplier-list');
      } else {
        showErrorToast(res?.data?.message);
      }
    },
    onError: () => {
      showErrorToast();
    }
  });

  const getWarehouseList = (addWarehouse) => {
    const list = [];
    addWarehouse?.map((item, index) => {
      list.push({
        warehouse_code: getValues(`warehouseCode-${index}`),
        state_id: getValues(`warehouseState-${index}`),
        city_id: getValues(`warehouseCity-${index}`)
      });
    });
    return list;
  };

  const handleClick = (res) => {
    if (res && supplierId) {
      if (!getWarehouseList(addWarehouse)?.length) {
        showErrorToast('Please add at least one warehouse');
        return;
      }

      setWarehouseInSupplier({
        supplierId: supplierId,
        warehouse_list: getWarehouseList(addWarehouse)
      });
    } else if (res) {
      mutate(getValues());
    }
  };

  return (
    <FormWrapper
      loading={isPending || isLoading || isPending2}
      heading="Manage Suppliers"
      buttonLabel={supplierId ? 'Save' : 'Next'}
      buttonClick={() => trigger().then((res) => handleClick(res))}>
      {forms.map(({ label, form: FormRenderer }, index) => {
        return (
          <Box key={index}>
            <Form
              heading={label}
              customStyles={{ maxHeight: '100%' }}
              linkButtonLabel={index === 1 ? 'View List' : ''}
              linkButtonClick={() => {
                navigate(`/suppliers/${supplierId}/warehouse`);
              }}>
              <FormRenderer
                control={control}
                getValues={getValues}
                watch={watch}
                disabled={supplierId}
                setValue={setValue}
                state={state}
                addWarehouse={addWarehouse}
                setAddWarehouse={setAddWarehouse}
                trigger={trigger}
              />
            </Form>
          </Box>
        );
      })}
    </FormWrapper>
  );
};

export default Suppliers;
