import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, Button } from '@mui/material';
import { Spinner, TableDataRenderer, Tooltip, CustomIconButton } from 'components/common';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useState } from 'react';
import styles from '../styles';

const capitalizeFirstLetter = (inputString) => {
  return inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase();
};

const ManageUserGroupTable = ({
  list = [],
  isLoading,
  deleteDisable,
  deleteUserGroup,
  editUserGroup
}) => {
  const [{ width }] = useWindowDimensions();
  const isWebView = width > 1000;

  const [toggleStates, setToggleStates] = useState({});

  const columnDefs = [
    {
      field: 'UserGroup',
      headerName: 'User Group',
      // headerCheckboxSelection: true,
      // checkboxSelection: true,
      flex: isWebView ? 1 : 0,
      minWidth: 250,
      cellRenderer: ({ data }) => <Tooltip title={data?.UserGroup} />
    },
    {
      field: 'Type',
      headerName: 'Type',
      flex: isWebView ? 1 : 0,
      minWidth: 250,
      cellRenderer: ({ data }) => <Tooltip title={capitalizeFirstLetter(data?.Type)} />
    },
    {
      field: 'Status',
      headerName: 'Status',
      flex: isWebView ? 1 : 0,
      minWidth: 250,
      cellRenderer: ({ data }) => (
        <Button
          sx={{
            backgroundColor: data?.Status === 'ENABLE' ? '#E4FFD8' : '#FFF3D8',
            color: data?.Status === 'ENABLE' ? '#32830E' : '#BE870D',
            width: 80,
            '&:hover': {
              backgroundColor: data?.Status === 'ENABLE' ? '#E4FFD8' : '#FFF3D8',
              color: data?.Status === 'ENABLE' ? '#32830E' : '#BE870D'
            }
          }}>
          {data?.Status}
        </Button>
      )
    },
    {
      field: 'Action',
      headerName: 'Action',
      flex: isWebView ? 1 : 0,
      minWidth: 250,
      cellRenderer: ({ data }) => (
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          {/* <Switch
            checked={toggleStates[data.UserGroup]}
            onChange={() => handleToggle(data.UserGroup)}
            name={`toggle-${data.UserGroup}`}
          /> */}
          <CustomIconButton onClick={() => editUserGroup(data)}>
            <EditOutlinedIcon />
          </CustomIconButton>
          <CustomIconButton disabled={deleteDisable} onClick={() => deleteUserGroup(data)}>
            <DeleteOutlineIcon />
          </CustomIconButton>
        </Box>
      )
    }
  ];

  return (
    <Box sx={styles.wrapper}>
      {isLoading ? <Spinner /> : <TableDataRenderer list={list} columnDefs={columnDefs} />}
    </Box>
  );
};

export default ManageUserGroupTable;
