const styles = {
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap'
  },

  text: { whiteSpace: 'nowrap' },

  fullWidth: { width: '100%' },

  timeLeftText: {
    flexBasis: '100%',
    textAlign: 'center',
    width: '250px',
    height: '21px',
    fontSize: {
      xs: '14px',
      sm: '16px'
    },
    lineHeight: '1.31',
    letterSpacing: '-0.06px',
    color: 'custom.label'
  },

  timer: {
    flexBasis: '100%',
    width: '67px',
    height: '21px',
    marginTop: '15px',

    marginBottom: {
      xs: '40px',
      sm: '30px'
    },
    fontSize: {
      xs: '22px',
      sm: '24px'
    },
    fontWeight: 'bold',
    lineHeight: {
      xs: '0.95',
      sm: '0.88'
    },
    letterSpacing: {
      xs: '-0.09px',
      sm: '-0.1px'
    },
    textAlign: 'center',
    color: 'custom.label'
  },

  otpComponent: {
    flexBasis: '100%',
    textAlign: 'center'
  },

  resendOtp: {
    width: '284px',
    height: {
      xs: '20px',
      sm: '18px'
    },
    textAlign: 'center',
    color: 'custom.label',
    flexBasis: '100%',
    marginTop: '23px',

    '& .resend': {
      textDecoration: 'underline',
      fontWeight: 'bold',
      cursor: 'pointer'
    },

    '& .reset': {
      fontWeight: 'bold'
    }
  },

  otpError: {
    display: 'flex',
    columnGap: '15px',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    marginTop: '37px',

    '& .img': {
      height: '24px',
      width: '24px'
    },

    '& .text': {
      height: '15px',
      fontSize: '12px',
      fontWeight: '500',
      color: '#fa5959'
    }
  },

  skeletonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    width: '80%'
  },

  heading: {
    fontSize: {
      xs: '26px',
      sm: '28px',
      md: '30px'
    },
    color: '#000000',
    fontWeight: 600
  },

  description: {
    fontSize: {
      xs: '14px',
      md: '16px'
    },
    color: '#707070'
  },

  otpWrapper: {
    display: 'flex',
    gap: '20px'
  },

  otp: {
    width: '28%',
    height: '70px',
    textAlign: 'center',
    borderRadius: '12px',
    border: '2px solid #EDEDED',
    fontSize: '30px'
  },

  inputWrapper: {
    display: 'flex',
    gap: '30px',
    flexDirection: 'column',
    alignItems: 'center'
  },

  splitWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },

  input: {
    width: '100%'
  },

  splitInput: {
    width: '46%'
  },

  authWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  linkers: {
    color: '#E9B519',
    cursor: 'pointer',
    fontSize: {
      xs: '12px',
      sm: '14px'
    }
  },

  button: {
    width: '100%'
  },

  footer: {
    marginTop: '-1.5rem',
    color: '#707070',
    textAlign: 'center',
    fontSize: {
      xs: '12px',
      sm: '14px'
    }
  },

  footerLoading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

export default styles;
