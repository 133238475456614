const styles = {
  wrapper: {
    display: 'flex',
    gap: '5px',
    fontSize: {
      xs: '14px',
      sm: '16px'
    }
  }
};

export default styles;
