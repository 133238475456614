import { Box } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Accordion, Dropdown, FormWrapper } from 'components/common';
import LinkButton from 'components/common/FormComponents/Button/LinkButton';
import { showErrorToast, showSuccessToast } from 'components/common/Toaster';
import { SUCCESS } from 'constants/apiStatusCodes';
import { shortenString } from 'constants/common';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createZone,
  getAreaListByWarehouseId,
  getWarehouseListByFacilityId
} from 'redux/APICaller/masterManagementAPIs';
import { selectAuthStore } from 'redux/authSlice';
import { selectAppStore } from 'redux/appSlice';
import CreateZone from './CreateZoneForm';
import ZoneTable from './ZoneTable';
import styles from './styles';

const getLabelById = (array, id) => {
  const foundObj = array.find((obj) => obj?.value === id);
  return foundObj ? foundObj?.label : null;
};

const WarehouseZone = () => {
  const { facilityId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { productCategoryList } = useSelector(selectAppStore);
  const { warehouseLocationList: facilityList, warehouseLocation } = useSelector(selectAuthStore);
  const [addZone, setAddZone] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [warehouseList, setWarehouseList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [warehouseId, setWarehouseId] = useState('');
  const [areaId, setAreaId] = useState('');

  // Current Page's ZoneList
  const [tableRows, setTableRows] = useState([]);

  // While adding select first item default
  useEffect(() => {
    if (!facilityId && facilityList?.length) {
      navigate(`${warehouseLocation || facilityList[0]?.value}`);
    }
  }, [facilityId, facilityList]);

  // If facility changes from header
  useEffect(() => {
    if (facilityId && facilityList?.length && warehouseLocation !== facilityId) {
      // window.location.href = `/warehouse-zone/${warehouseLocation}`;
      navigate(`/warehouse-zone/${warehouseLocation}`);
    }
  }, [warehouseLocation]);

  const { control, getValues, reset, watch, trigger, setValue } = useForm({
    defaultValues: {
      zone_name: '',
      picklist_quantity: '',
      category_id: '',
      status: true,
      facility: ''
    },
    mode: 'all',
    criteriaMode: 'all'
  });
  const watchFacility = watch('facility');

  useEffect(() => {
    if (watchFacility) {
      setTableRows([]);
    }
  }, [watchFacility]);

  const handleChange = (panel, id) => (_, isExpanded) => {
    if (warehouseId != id) setAreaList([]);
    setWarehouseId(id);
    setExpanded(isExpanded ? panel : false);
  };

  const { data = { data: [] }, isLoading } = useQuery({
    queryKey: ['warehouseList', watchFacility],
    queryFn: async () => {
      const res = await getWarehouseListByFacilityId({ facilityId: watchFacility });

      setWarehouseList(res?.data);
      return res;
    },
    enabled: !!watchFacility
  });

  const { areaListData = { data: [] }, isLoading: areaListIsLoading } = useQuery({
    queryKey: ['areaList', warehouseId],
    queryFn: async () => {
      const res = await getAreaListByWarehouseId({ warehouseId });
      setAreaList(res?.data?.list);
      return res;
    },
    enabled: !!warehouseId
  });

  useEffect(() => {
    if (facilityId && facilityList?.length && facilityId === warehouseLocation) {
      setValue('facility', facilityId);
    }
  }, [facilityId, facilityList, warehouseLocation]);

  useEffect(() => {
    if (facilityId && warehouseList?.length) {
      setWarehouseId(warehouseList[0]?.id);
      setExpanded(`panel${0}`);
    }
  }, [warehouseList]);

  useEffect(() => {
    if (facilityId && areaList?.length) {
      setAreaId(areaList[0]?.area_id);
    }
  }, [areaList]);

  /**
   * Create Zone
   */
  const { isPending, mutate } = useMutation({
    mutationFn: (data) => {
      return createZone(data);
    },
    onSuccess: (res, req) => {
      if (res?.status === SUCCESS) {
        showSuccessToast(res?.data?.message);
        reset({
          zone_name: '',
          picklist_quantity: '',
          category_id: '',
          status: true,
          facility: watchFacility
        });

        // New zone added
        const tempTableRows = [...tableRows];
        tempTableRows.unshift({
          zone_id: res?.data?.id,
          ZoneName: req?.zone_name,
          area_id: req?.area_id,
          Category: getLabelById(productCategoryList, req?.category_id)
        });
        setTableRows(tempTableRows);
      } else {
        showErrorToast(res?.data?.message);
      }
    },
    onError: () => {
      showErrorToast();
    }
  });

  const handleClick = (res) => {
    if (res) {
      const { zone_name, picklist_quantity, category_id, status } = getValues();

      mutate({
        area_id: areaId,
        zone_name,
        picklist_quantity: Number(picklist_quantity),
        category_id,
        status
      });
    }
  };

  const handleSaveClick = () => {
    trigger().then((res) => handleClick(res));
  };

  const btnOnClick = () => {
    if (addZone)
      reset({
        zone_name: '',
        picklist_quantity: '',
        category_id: '',
        status: true,
        facility: watchFacility
      });
    setAddZone(!addZone);
  };

  return (
    <Box sx={styles.wrapper}>
      <FormWrapper
        heading="Warehouse"
        customStyles={styles.warehouseWrapper}
        childrenStyles={styles.warehouseWrapperChildren}>
        <Box sx={styles.listContainer}>
          <Box sx={styles.dropDownWrapper}>
            <Dropdown
              name="facility"
              control={control}
              options={facilityList}
              selectClass="selectClass"
            />
          </Box>
          <Box sx={styles.accordianContentWrapper}>
            {watchFacility &&
              warehouseList?.map(({ warehouseName, id }, idx) => {
                return (
                  <Box key={idx} sx={styles.accordianWrapper}>
                    <Accordion
                      expanded={expanded === `panel${idx}`}
                      handleChange={handleChange(`panel${idx}`, id)}
                      heading={shortenString(warehouseName, 25)}
                      description={areaList?.map(({ area_name, area_id }, id) => {
                        return (
                          <Box
                            key={id}
                            sx={{
                              ...styles.accordianItem,
                              color: areaId === area_id ? 'primary.main' : 'black'
                            }}
                            onClick={() => {
                              setAreaId(area_id);
                              reset({
                                zone_name: '',
                                picklist_quantity: '',
                                category_id: '',
                                status: true,
                                facility: watchFacility
                              });
                              setAddZone(false);
                            }}>
                            {shortenString(area_name, 25)}
                          </Box>
                        );
                      })}
                    />
                  </Box>
                );
              })}
          </Box>
        </Box>
      </FormWrapper>
      <FormWrapper
        heading={areaId ? 'Zones' : ''}
        buttonLabel="Add Warehouse"
        as={'LinkButton'}
        customStyles={styles.zoneWrapper}
        buttonClick={() => navigate(`/warehouse`)}>
        {areaId && (
          <>
            <ZoneTable
              tableRows={tableRows}
              setTableRows={setTableRows}
              areaId={areaId}
              facilityId={watchFacility}
              warehouseId={warehouseId}
              addZone={addZone}
              btnOnClick={btnOnClick}
            />
          </>
        )}
        {addZone && <CreateZone control={control} handleSaveClick={handleSaveClick} />}
      </FormWrapper>
    </Box>
  );
};

export default WarehouseZone;
