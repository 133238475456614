import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, Button } from '@mui/material';
import {
  Pagination,
  Spinner,
  TableDataRenderer,
  Tooltip,
  CustomIconButton
} from 'components/common';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useNavigate } from 'react-router-dom';
import styles from '../styles';

const ManageUserTable = ({
  list = [],
  pageData,
  pageClick,
  page,
  isLoading,
  setUnitSelectedList
}) => {
  const [{ width }] = useWindowDimensions();
  const isWebView = width > 1000;

  const navigate = useNavigate();

  const columnDefs = [
    {
      field: 'ID',
      headerName: 'ID',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      flex: isWebView ? 1 : 0,
      minWidth: 250,
      cellRenderer: ({ data }) => <Box sx={styles.lcanId}>{data?.ID}</Box>
    },
    {
      field: 'Name',
      headerName: 'Name',
      minWidth: 250,
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => <Tooltip title={data?.Name} />
    },
    {
      field: 'Email',
      headerName: 'Email',
      minWidth: 300,
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => <Box sx={styles.lcanId}>{data?.Email}</Box>
    },
    { field: 'LastLogin', headerName: 'Last Login', flex: isWebView ? 1 : 0, minWidth: 250 },
    {
      field: 'Phone',
      headerName: 'Phone',
      minWidth: 250,
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => <Tooltip title={data?.Phone} />
    },
    {
      field: 'Status',
      headerName: 'Status',
      flex: isWebView ? 1 : 0,
      minWidth: 250,
      cellRenderer: ({ data }) => (
        <Button
          sx={{
            backgroundColor: data?.Status === 'Enable' ? '#E4FFD8' : '#FFF3D8',
            color: data?.Status === 'Enable' ? '#32830E' : '#BE870D',
            width: 80,
            '&:hover': {
              backgroundColor: data?.Status === 'Enable' ? '#E4FFD8' : '#FFF3D8',
              color: data?.Status === 'Enable' ? '#32830E' : '#BE870D'
            }
          }}>
          {data?.Status}
        </Button>
      )
    },
    {
      field: 'Edit',
      headerName: 'Edit',
      flex: isWebView ? 1 : 0,
      minWidth: 250,
      cellRenderer: ({ data }) => (
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <CustomIconButton onClick={() => navigate(`/update-user/${data?.id}`)}>
            <EditOutlinedIcon />
          </CustomIconButton>
        </Box>
      )
    }
  ];

  const selectionChanged = (param) => {
    const selectedRows = param?.api?.getSelectedRows();
    setUnitSelectedList(selectedRows);
  };

  return (
    <Box sx={styles.wrapper}>
      {isLoading ? (
        <Spinner />
      ) : (
        <TableDataRenderer
          list={list}
          columnDefs={columnDefs}
          onSelectionChanged={selectionChanged}
        />
      )}
      <Box sx={styles.paginationWrapper}>
        <Pagination
          count={pageData?.maxPage}
          page={page}
          onChange={(e, pageNumber) => pageClick(e, pageNumber)}
        />
      </Box>
    </Box>
  );
};

export default ManageUserTable;
