import { Box } from '@mui/material';
import React from 'react';
import styles from './styles';

const SuccessPage = ({
  heading = 'Your account is successfully created.',
  description = 'We have just sent the password reset link to your email'
}) => {
  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.heading}>{heading}</Box>
      <Box>
        <Box component="img" src="/success.png" sx={styles.image} />
      </Box>
      <Box sx={styles.description}>{description}</Box>
      <Box sx={styles.logoWrapper}>
        <Box component="img" src="/losungLogo.png" alt="losung_logo" sx={styles.logo} />
      </Box>
    </Box>
  );
};

export default SuccessPage;
