const styles = {
  formWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    rowGap: '20px'
  },

  input: {
    width: '46%'
  },

  subMiniInputs: {
    width: '40%'
  },

  miniInputs: {
    width: '28%'
  },

  validityCheck: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    height: '40px',
    marginTop: '2.8rem',
    width: '40px',
    cursor: 'pointer',
    color: 'white',
    backgroundColor: 'primary.main',
    textAlign: 'center'
  },

  addButton: {
    fontSize: '16px',
    width: '100%',
    color: 'primary.main',
    textDecoration: 'underline',
    cursor: 'pointer'
  },

  number: {
    width: '40px',
    border: '1px solid',
    textAlign: 'center',
    color: 'primary.main',
    borderRadius: '50px',
    fontSize: '16px',
    borderColor: 'custom.connector',
    marginTop: '40px'
  },

  aisleWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 3,
    justifyContent: 'space-between'
  },

  aisleContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  },

  inputContainer: {
    width: '100%',
    gap: '15px',
    display: 'flex',
    justifyContent: 'space-between'
  },

  rackWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  }
};

export default styles;
