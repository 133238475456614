import { Box } from '@mui/material';
import React from 'react';
import styles from '../styles';

const TransferIdRenderer = ({ id }) => {
  return (
    <Box sx={styles.lcanId} component="a" href={`/view-transfer/${id}`} target="_blank">
      {id}
    </Box>
  );
};

export default TransferIdRenderer;
