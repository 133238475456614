const DOCK_PATHS = {
  // START_LOADING_UNLOADING: `/dock/truck/:truckNumber/process`,
  // COMPLETE_UNLOADING: `/dock/truck/:truckNumber/unload`,
  // COMPLETE_LOADING: `/dock/truck/:truckNumber/load`,
  // UNDOCK_TRUCK: `/dock/truck/:truckNumber/undock`
  START_LOADING_UNLOADING: `/dock/truck/process`,
  COMPLETE_UNLOADING: `/dock/truck/unload`,
  COMPLETE_LOADING: `/dock/truck/load`,
  UNDOCK_TRUCK: `/dock/truck/undock`
};

export default DOCK_PATHS;
