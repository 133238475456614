import { Box, Button } from '@mui/material';
import { MainContent, Pagination, Spinner, TableDataRenderer, Tooltip } from 'components/common';
import { showErrorToast } from 'components/common/Toaster';
import { BAD_REQUEST, SUCCESS } from 'constants/apiStatusCodes';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFacilityList } from 'redux/APICaller/userFacilityAPIs';
import styles from './styles';
import useDisplayScale from 'hooks/useDisplayScale';

const FacilityList = () => {
  const [list, setList] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [pageData, setPageData] = useState({
    currentPage: 1,
    maxPage: 0
  });
  const displayScale = useDisplayScale();
  const navigate = useNavigate();

  const [{ width }] = useWindowDimensions();
  const isWebView = width > 1000;

  const facilityDataChanger = (apiData = []) => {
    if (Array.isArray(apiData)) {
      const newResponse = apiData.map(
        ({
          facility_name: FacilityName,
          location_code: FacilityCode,
          status: Status,
          ...rest
        }) => ({
          FacilityName,
          FacilityCode,
          Status,
          ...rest
        })
      );
      return newResponse;
    } else {
      return [];
    }
  };

  const APICALL = async (page = 1) => {
    setLoading(true);
    const res = await getFacilityList({
      page: page,
      limit: 15
    });

    if (res.status === SUCCESS) {
      if (page === 1 || page === 'reset') {
        setList([
          {
            page: 1,
            array: facilityDataChanger(res?.data)
          }
        ]);
      } else setList([...list, { page, array: facilityDataChanger(res?.data?.data) }]);
      setPageData({ maxPage: res?.data?.total_pages, currentPage: page === 'reset' ? 1 : page });
      if (initialLoad) setInitialLoad(false);
    } else {
      if (res?.status === BAD_REQUEST) showErrorToast(res?.data?.message);
      else showErrorToast();
    }
    setLoading(false);
  };

  const isDataExist = (page) => {
    let found = false;
    list?.map((item) => {
      if (item.page === page) {
        found = true;
      }
    });
    return found;
  };

  const apiCallWithPage = (page) => {
    if (!isDataExist(page)) APICALL(page);
    else setPageData({ ...pageData, currentPage: page });
  };

  const setRowsWithPage = (page = 1) => {
    list?.map((item) => {
      if (item.page === page) {
        setTableRows(item.array);
      }
    });
  };

  useEffect(() => {
    setRowsWithPage(pageData.currentPage);
  }, [pageData.currentPage, list]);

  useEffect(() => {
    APICALL();
  }, []);

  const columnDefs = [
    {
      field: 'FacilityName',
      headerName: 'Facility Name',
      minWidth: 250,
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => (
        <Tooltip title={data.FacilityName ? data.FacilityName : 'No data'} />
      )
    },
    {
      field: 'FacilityCode',
      headerName: 'Facility Code',
      minWidth: 250,
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => (
        <Tooltip title={data.FacilityCode ? data.FacilityCode : 'No data'} />
      )
    },
    {
      field: 'Status',
      headerName: 'Status',
      minWidth: 250,
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => (
        <Button
          sx={{
            backgroundColor: data.Status ? '#E4FFD8' : '#FFF3D8',
            color: data.Status ? '#32830E' : '#BE870D',
            width: 70,
            '&:hover': {
              backgroundColor: data.Status ? '#E4FFD8' : '#FFF3D8',
              color: data.Status ? '#32830E' : '#BE870D'
            }
          }}>
          {data.Status ? 'Enable' : 'Disable'}
        </Button>
      )
    },
    {
      field: 'Navigator',
      headerName: '',
      minWidth: 250,
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => {
        return (
          <Box>
            <Button
              sx={{
                '&:hover': {
                  backgroundColor: '#FFEFD3'
                }
              }}
              onClick={() => navigate(`/facility/${data.facility_id}`)}>
              View Facility
            </Button>
            <Button
              sx={{
                marginLeft: '20px',
                '&:hover': {
                  backgroundColor: '#FFEFD3'
                }
              }}
              onClick={() => navigate(`/warehouse-zone/${data.facility_id}`)}>
              View Warehouse
            </Button>
          </Box>
        );
      }
    }
  ];

  // const listData = facilityDataChanger(list.data);

  return (
    <MainContent
      heading="Facilities"
      navigateClick={() => navigate('/facility')}
      navigateLabel="Add Facility">
      <Box sx={styles.wrapper}>
        {loading ? <Spinner /> : <TableDataRenderer list={tableRows} columnDefs={columnDefs} />}
        <Box sx={styles.paginationWrapper}>
          <Pagination
            count={pageData.maxPage}
            page={pageData.currentPage}
            onChange={(e, pageNumber) => {
              apiCallWithPage(pageNumber);
            }}
          />
        </Box>
      </Box>
    </MainContent>
  );
};

export default FacilityList;
