import { Box } from '@mui/material';
import React from 'react';
import { Input } from '../../../common';
import styles from '../styles';

const ContactInformation = ({ control, loading }) => {
  return (
    <Box sx={styles.formWrapper}>
      <Input
        name="first_name"
        label="First Name"
        control={control}
        customStyles={{ width: { xs: '47%', md: '20%' } }}
        loading={loading}
      />
      <Input
        name="last_name"
        label="Last Name"
        control={control}
        customStyles={{ width: { xs: '47%', md: '20%' } }}
        loading={loading}
      />
      <Input
        name="phone_number"
        label="Phone Number"
        control={control}
        customStyles={styles.input}
        loading={loading}
        onKeyPress={(event) => {
          if (!/[0-9+]/.test(event.key)) {
            event.preventDefault();
          }
        }}
        rules={{
          pattern: {
            value: /^(?:\+\d{1,3})?\d{10}$/,
            message: 'Please provide a valid phone number'
          }
        }}
      />
      <Input name="contact_full_address" label="Full Address" control={control} loading={loading} />
    </Box>
  );
};

export default ContactInformation;
