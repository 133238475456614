import { useLocation } from 'react-router-dom';
import SetUpPassword from './setupPassword';

const setup = () => {
  const location = useLocation();
  if (location?.pathname?.includes('/setup-password/'))
    return <SetUpPassword title={'Setup your password'} />;
  else return <SetUpPassword title={'Re-Set your password'} />;
};

export default setup;
