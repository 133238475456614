/**
 * Complete Loading Modal Data
 */

export const completeLoadingModal = [
  {
    name: 'trailerNumber',
    label: 'Trailer Number',
    type: 'input',
    isRequired: true,
    disabled: true
  },
  {
    name: 'consignmentType',
    label: 'Consignment type',
    type: 'input',
    isRequired: true,
    disabled: true
  },
  {
    name: 'dock',
    label: 'Dock',
    disabled: true,
    type: 'input',
    isRequired: true
  },
  {
    name: 'noOfInvoice',
    label: 'Number Of Invoice',
    disabled: true,
    type: 'input',
    isRequired: true
  },
  {
    name: 'noOfPackageLoader',
    label: 'Number Of Package Loaded',
    type: 'input',
    isRequired: true,
    disabled: false
  }
];

/**
 * Complete Unloading Modal Data
 */

export const completeUnloadingModal = [
  {
    name: 'trailerNumber',
    label: 'Trailer Number',
    type: 'input',
    isRequired: true,
    disabled: true
  },
  {
    name: 'consignmentType',
    label: 'Consignment type',
    type: 'input',
    isRequired: true,
    disabled: true
  },
  {
    name: 'dock',
    label: 'Dock',
    disabled: true,
    type: 'input',
    isRequired: true
  },
  {
    name: 'onDockRejected',
    label: 'On Dock Rejected',
    disabled: false,
    type: 'dropdown',
    isRequired: true,
    options: [
      {
        label: 'Yes',
        value: 'yes'
      },
      {
        label: 'No',
        value: 'no'
      }
    ]
  },
  {
    name: 'numberOfUnitsRejected',
    label: 'Number Of Packages Rejected',
    type: 'input',
    isRequired: true,
    disabled: false,
    hide: true
  },
  {
    name: 'dockRejectionReason',
    label: 'Dock Rejection Reason',
    type: 'dropdown',
    isRequired: false,
    disabled: false,
    hide: true,
    options: [
      {
        label: 'Invoice sanity failure',
        value: 'Invoice sanity failure'
      },
      {
        label: 'Box/Product damage',
        value: 'Box/Product damage'
      }
    ]
  }
];

/**
 * Create Manual ACN
 */

export const createManualACN = [
  {
    name: 'consignmentType',
    label: 'Consignment type',
    type: 'dropDown',
    isRequired: true,
    disabled: false,
    options: [
      {
        label: 'Inbound',
        value: 'Inbound'
      },
      {
        label: 'Outbound',
        value: 'Outbound'
      }
    ]
  },
  {
    isHide: true,
    name: 'poNumber',
    label: 'PO Number',
    type: 'dynamicAutocomplete',
    maxLength: 20,
    disabled: false,
    isRequired: true
  },
  {
    isHide: true,
    name: 'brand',
    label: 'Supplier',
    disabled: true,
    type: 'input',
    isRequired: true
  },

  {
    name: 'shipFromWarehouse',
    label: 'Ship from warehouse detail',
    type: 'dropDown',
    disabled: true,
    isRequired: true,
    options: []
  },
  {
    name: 'shipToWarehouse',
    label: 'Ship to warehouse detail',
    type: 'dropDown',
    disabled: true,
    isRequired: true,
    options: []
  },
  {
    name: 'plannedEDA',
    label: 'Planned EDA',
    type: 'datetime-local',
    isRequired: true
  },
  {
    name: 'locationCode',
    label: 'Location Code',
    disabled: true,
    type: 'input',
    maxLength: 20,
    isRequired: true
  },
  {
    isHide: false,
    name: 'channel',
    label: 'Channel',
    type: 'input',
    isRequired: true,
    disabled: true,
    options: [
      {
        label: 'Distribution',
        value: 'distribution'
      },
      {
        label: 'Sub-Contracting',
        value: 'sub-contracting'
      },
      {
        label: 'C&F',
        value: 'c&f'
      }
    ]
  },
  {
    name: 'category',
    label: 'Category',
    type: 'input',
    isRequired: false,
    disabled: true,
    options: []
  },
  {
    name: {
      nameOne: 'enterNumberOfUnit',
      nameTwo: 'enterNumberOfPackage'
    },
    inputType: 'number',
    label: 'Item Details',
    type: 'twoInput',
    isRequired: false,
    disabled: false
  },
  {
    isHide: true,
    name: 'invoiceNo',
    label: 'Invoice No',
    type: 'input',
    maxLength: 20,
    isRequired: true,
    inputType: 'text'
  }
];

/**
 * Receive Trailer on Dock
 */

export const receiveTrailerOnDock = [
  {
    name: 'truckNumber',
    label: 'Truck Number',
    type: 'input',
    isRequired: true,
    disabled: true
  },
  {
    name: 'consignmentType',
    label: 'Consignment type',
    type: 'input',
    isRequired: true,
    disabled: true,
    options: [
      {
        label: 'Inbound',
        value: 'Inbound'
      },
      {
        label: 'Outbound',
        value: 'Outbound'
      }
    ]
  },
  {
    name: 'chooseDock',
    label: 'Choose Dock',
    disabled: false,
    type: 'dropDown',
    isRequired: true,
    options: []
  },
  {
    hide: true,
    name: 'numberOfInvoice',
    label: 'Number Of Invoice',
    type: 'input',
    isRequired: true,
    disabled: true
  }
];

/**
 * Release from dock
 */

export const releaseFromDock = [
  {
    name: 'trailerNumber',
    label: 'Trailer Number',
    type: 'input',
    isRequired: true,
    disabled: true
  },
  {
    name: 'consignmentType',
    label: 'Consignment type',
    type: 'input',
    isRequired: true,
    disabled: true
  },
  {
    name: 'dock',
    label: 'Dock',
    disabled: true,
    type: 'input',
    isRequired: true
  },
  {
    name: 'numberOfInvoice',
    label: 'Number of Invoice',
    type: 'dropdown',
    isRequired: true,
    disabled: false,
    options: [
      {
        label: '1',
        value: '1'
      },
      {
        label: '2',
        value: '2'
      },
      {
        label: '3',
        value: '3'
      },
      {
        label: '4',
        value: '4'
      },
      {
        label: '5',
        value: '5'
      },
      {
        label: '6',
        value: '6'
      },
      {
        label: '7',
        value: '7'
      },
      {
        label: '8',
        value: '8'
      },
      {
        label: '9',
        value: '9'
      },
      {
        label: '10',
        value: '10'
      },
      {
        label: '11',
        value: '11'
      },
      {
        label: '12',
        value: '12'
      },
      {
        label: '13',
        value: '13'
      },
      {
        label: '14',
        value: '14'
      },
      {
        label: '15',
        value: '15'
      },
      {
        label: '16',
        value: '16'
      },
      {
        label: '17',
        value: '17'
      },
      {
        label: '18',
        value: '18'
      },
      {
        label: '19',
        value: '19'
      },
      {
        label: '20',
        value: '20'
      }
    ]
  },
  {
    name: 'numberOfPackage',
    label: 'Number of package loaded',
    type: 'input',
    inputType: 'number',
    isRequired: true,
    disabled: false
  },
  {
    name: 'isTrailerSealIntact',
    label: 'Is trailer seal intact ?',
    type: 'dropdown',
    isRequired: true,
    disabled: false,
    options: [
      {
        label: 'Yes',
        value: 'yes'
      },
      {
        label: 'No',
        value: 'no'
      }
    ]
  }
];

/**
 * Start Loading / Unloading
 */

export const startLoadingUnloading = [
  {
    name: 'trailerNumber',
    label: 'Trailer Number',
    type: 'input',
    isRequired: true,
    disabled: true
  },
  {
    name: 'consignmentType',
    label: 'Consignment type',
    type: 'input',
    isRequired: true,
    disabled: true
  },
  {
    name: 'dock',
    label: 'Dock',
    disabled: true,
    type: 'input',
    isRequired: true
  },
  {
    name: 'numberOfLoaderUnloader',
    label: 'Number Of unloader/loader',
    type: 'dropdown',
    isRequired: true,
    disabled: false,
    options: [
      {
        label: '1',
        value: '1'
      },
      {
        label: '2',
        value: '2'
      },
      {
        label: '3',
        value: '3'
      },
      {
        label: '4',
        value: '4'
      },
      {
        label: '5',
        value: '5'
      },
      {
        label: '6',
        value: '6'
      },
      {
        label: '7',
        value: '7'
      },
      {
        label: '8',
        value: '8'
      },
      {
        label: '9',
        value: '9'
      },
      {
        label: '10',
        value: '10'
      },
      {
        label: '11',
        value: '11'
      },
      {
        label: '12',
        value: '12'
      },
      {
        label: '13',
        value: '13'
      },
      {
        label: '14',
        value: '14'
      },
      {
        label: '15',
        value: '15'
      },
      {
        label: '16',
        value: '16'
      },
      {
        label: '17',
        value: '17'
      },
      {
        label: '18',
        value: '18'
      },
      {
        label: '19',
        value: '19'
      },
      {
        label: '20',
        value: '20'
      }
    ]
  },
  {
    name: 'delayReason',
    label: 'Delay Reason',
    type: 'dropdown',
    isRequired: true,
    disabled: false,
    options: [
      {
        label: 'Manpower unavailable',
        value: 'Manpower unavailable'
      },
      {
        label: 'None',
        value: 'none'
      }
    ]
  }
];

/**
 * Sample Card Data
 */

export const sampleCardData = [
  {
    heading: 'Order Details',
    list: [
      {
        label: 'ACN ID',
        value: 'LCN2223'
      },
      {
        label: 'Created on',
        value: '18-02-23'
      },
      {
        label: 'Status',
        value: 'Dispatched'
      },
      {
        label: 'Purchase order',
        value: 'PO-23/23'
      },
      {
        label: 'Planned unit',
        value: '112'
      },
      {
        label: 'Total value',
        value: 'INR 34,500'
      }
    ]
  }
];
