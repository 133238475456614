import Box from '@mui/material/Box';
import styles from './styles';

const NoData = () => {
  return (
    <Box sx={styles.wrapper}>
      <Box component="img" src="/noData.png" alt="noData" sx={styles.image} />
      <Box sx={styles.text}>No Data Found</Box>
    </Box>
  );
};

export default NoData;
