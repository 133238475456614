import SUPPLIER_PATHS from '../../constants/APIPaths/MasterManagement';
import { getAPI, postAPI, putAPI } from '../../helpers/API';

const {
  POST_SUPPLIER,
  GET_SUPPLIER_LIST,
  GET_CATEGORY_LIST,
  GET_STORAGE_LISTING_DETAILS,
  GET_SUPPLIER_DETAILS_BY_ID,
  GET_WAREHOUSE_LIST_BY_SUPPLIER_ID,
  GET_AREA_LIST_BY_WAREHOUSE_ID,
  GET_ZONE_LIST_BY_AREA_ID,
  POST_ZONE,
  GET_USER_STATE,
  POST_ZONE_STORAGE,
  POST_SUPPLIER_UPDATE,
  GET_WAREHOUSE_DETAILS_BY_ID,
  GET_WAREHOUSE_LIST_BY_SUPPLIER
} = SUPPLIER_PATHS;

export function createSupplier(data) {
  return postAPI({
    url: POST_SUPPLIER,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

export function updateSupplier(data) {
  return postAPI({
    url: POST_SUPPLIER_UPDATE.replace(':supplierId', data?.supplierId),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

export function getSupplierList(data) {
  return getAPI({
    url: GET_SUPPLIER_LIST,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

export function getCategoryList(data) {
  return getAPI({
    url: GET_CATEGORY_LIST,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

export function getAreaList(data) {
  return getAPI({
    url: GET_CATEGORY_LIST,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

export function getSupplierDetailsById(data) {
  return getAPI({
    url: GET_SUPPLIER_DETAILS_BY_ID.replace(':supplierId', data?.supplierId),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

export function getStorageListingDetails(data) {
  return getAPI({
    url: GET_STORAGE_LISTING_DETAILS,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

export function getWarehouseListByFacilityId(data) {
  return getAPI({
    url: GET_WAREHOUSE_LIST_BY_SUPPLIER_ID.replace(':facilityId', data?.facilityId),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

export function getAreaListByWarehouseId(data) {
  return getAPI({
    url: GET_AREA_LIST_BY_WAREHOUSE_ID.replace(':warehouseId', data?.warehouseId),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

export function getZoneListByAreaId(data) {
  return getAPI({
    url: GET_ZONE_LIST_BY_AREA_ID.replace(':areaId', data?.areaId),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

export function createZone(data) {
  return postAPI({
    url: POST_ZONE,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

export function getUserState(data) {
  return getAPI({
    url: GET_USER_STATE,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

export function createZoneStorage(data) {
  return postAPI({
    url: POST_ZONE_STORAGE,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

export function getWarehouseDetailsId(data) {
  return getAPI({
    url: GET_WAREHOUSE_DETAILS_BY_ID.replace(':warehouseId', data?.id),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

export function getWarehouseListBySupplier(data) {
  return getAPI({
    url: GET_WAREHOUSE_LIST_BY_SUPPLIER.replace(':supplierId', data?.id),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}
