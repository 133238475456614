import { BROWSE_INVENTORY_PATHS } from '../../constants/apiPaths';
import { getAPI, postAPI } from '../../helpers/API';

const { POST_INVENTORY_LIST, POST_INVENTORY_LIST_CSV_DOWNLOAD, GET_ZONE_LIST, GET_AREA_LIST } =
  BROWSE_INVENTORY_PATHS;

/**
 * Find Inventory List
 * @param {*} data
 * @returns
 */
export function inventoryList(data) {
  return postAPI({
    url: POST_INVENTORY_LIST,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Search Inventory CSV Download
 * @param {*} data
 * @returns
 */
export function searchInventoryCSVDownload(data) {
  return postAPI({
    url: POST_INVENTORY_LIST_CSV_DOWNLOAD,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Get Zone List
 * @param {*} data
 * @returns
 */
export function getZoneList(data) {
  return getAPI({
    url: GET_ZONE_LIST,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

/**
 * Get Area List
 * @param {*} data
 * @returns
 */
export function getAreaList(data) {
  return getAPI({
    url: GET_AREA_LIST,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}
