import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { MainContent, Pagination, Spinner, TableDataRenderer, Tooltip } from 'components/common';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { BAD_REQUEST, SUCCESS } from 'constants/apiStatusCodes';
import { showErrorToast } from 'components/common/Toaster';
import { getWarehouseListBySupplier } from 'redux/APICaller/masterManagementAPIs';
import { useParams } from 'react-router-dom';
import styles from './styles';

const SupplierWarehouseList = () => {
  const { id } = useParams();
  const [list, setList] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [pageData, setPageData] = useState({
    currentPage: 1,
    maxPage: 0
  });

  const [{ width }] = useWindowDimensions();
  const isWebView = width > 1000;

  const APICALL = async (page = 1) => {
    setLoading(true);
    const res = await getWarehouseListBySupplier({
      page: page - 1,
      size: 15,
      id
    });

    if (res.status === SUCCESS) {
      if (page === 1 || page === 'reset') {
        setList([
          {
            page: 1,
            array: res?.data?.list
          }
        ]);
      } else setList([...list, { page, array: res?.data?.list }]);
      setPageData({ maxPage: res?.data?.total_pages, currentPage: page === 'reset' ? 1 : page });
      if (initialLoad) setInitialLoad(false);
    } else {
      if (res?.status === BAD_REQUEST) showErrorToast(res?.data?.message);
      else showErrorToast();
    }
    setLoading(false);
  };

  const isDataExist = (page) => {
    let found = false;
    list?.map((item) => {
      if (item.page === page) {
        found = true;
      }
    });
    return found;
  };

  const apiCallWithPage = (page) => {
    if (!isDataExist(page)) APICALL(page);
    else setPageData({ ...pageData, currentPage: page });
  };

  const setRowsWithPage = (page = 1) => {
    list?.map((item) => {
      if (item.page === page) {
        setTableRows(item.array);
      }
    });
  };

  useEffect(() => {
    setRowsWithPage(pageData.currentPage);
  }, [pageData.currentPage, list]);

  useEffect(() => {
    APICALL();
  }, []);

  const columnDefs = [
    {
      field: 'warehouse_code',
      headerName: 'Warehouse Code',
      minWidth: 250,
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => (
        <Tooltip title={data.warehouse_code ? data.warehouse_code : 'No data'} />
      )
    },
    {
      field: 'warehouse_state',
      headerName: 'Warehouse State',
      minWidth: 250,
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => (
        <Tooltip title={data.warehouse_state ? data.warehouse_state : 'No data'} />
      )
    },
    {
      field: 'warehouse_city',
      headerName: 'Warehouse City',
      minWidth: 250,
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => (
        <Tooltip title={data.warehouse_city ? data.warehouse_city : 'No data'} />
      )
    }
  ];

  return (
    <MainContent heading="Supplier Warehouse" description="">
      <Box sx={styles.wrapper}>
        {loading ? <Spinner /> : <TableDataRenderer list={tableRows} columnDefs={columnDefs} />}
        <Box sx={styles.paginationWrapper}>
          <Pagination
            count={pageData.maxPage}
            page={pageData.currentPage}
            onChange={(_, pageNumber) => {
              apiCallWithPage(pageNumber);
            }}
          />
        </Box>
      </Box>
    </MainContent>
  );
};

export default SupplierWarehouseList;
