const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    height: '100%',
    gap: '30px'
  },

  headerWrapper: {
    display: 'flex',
    alignItems: {
      xs: 'flex-start',
      sm: 'center'
    },
    rowGap: '20px',
    flexDirection: {
      xs: 'column',
      sm: 'row'
    },
    justifyContent: 'space-between'
  },

  headingContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },

  buttonWrapper: {
    display: 'flex',
    gap: 2
  },

  navigateButton: {
    height: '34px',
    fontSize: '14px',
    fontWeight: 400,
    borderRadius: '5px',
    minWidth: '110px',
    width: 'max-content'
  },

  downloadButton: {
    height: '34px',
    fontSize: '14px',
    border: '1px solid #000000',
    background: 'transparent',
    borderRadius: '5px',
    color: '#000000',
    fontWeight: 400,
    width: '110px',
    '&:hover': {
      color: '#FFFFFF',
      background: '#8D8D8D',
      border: 'none'
    }
  },

  heading: {
    fontSize: {
      xs: '20px',
      sm: '24px'
    },
    fontWeight: 600
  },

  description: {
    fontSize: {
      xs: '12px',
      sm: '14px'
    },
    fontWeight: 400
  },

  childrenWrapper: {
    height: '100%',
    width: '100%',
    boxSizing: 'border-box'
  }
};

export default styles;
