import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Autocomplete, Box, Checkbox, FormControl, FormHelperText, TextField } from '@mui/material';
import { getError } from 'constants/common';
import { Controller } from 'react-hook-form';
import styles from './styles';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CheckBoxAutoComplete = ({
  name,
  control,
  label,
  fullWidth = true,
  errors,
  freeSolo = false,
  rules,
  options = [],
  customStyles,
  variant = 'standard',
  className,
  loading = false,
  searchLoading = false,
  onInputChange,
  ...rest
}) => {
  const error = getError(name, errors);
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => {
        return (
          <FormControl
            sx={{ ...styles.wrapper, ...customStyles }}
            fullWidth={fullWidth}
            variant={variant}
            className={className}>
            <Autocomplete
              multiple
              loading={searchLoading}
              options={options}
              fullWidth={fullWidth}
              value={field.value}
              onChange={(_, newValue) => {
                if (!Array.isArray(newValue)) {
                  typeof newValue === 'string'
                    ? field.onChange(newValue)
                    : field.onChange(newValue.value);
                } else field.onChange(newValue);
              }}
              getOptionLabel={(option) => option.label}
              disableCloseOnSelect
              renderOption={(props, option, { selected }) => (
                <Box component="li" {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option?.label}
                </Box>
              )}
              renderToggle={({ onToggle, open }) => (
                <KeyboardArrowDownIcon
                  onClick={onToggle}
                  sx={{ transform: open ? 'rotate(180deg)' : 'none' }}
                />
              )}
              renderInput={(params) => (
                <Box ref={params.InputProps.ref}>
                  <TextField
                    label={label}
                    error={!!error}
                    fullWidth={fullWidth}
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: styles.chipsWrapper
                    }}
                  />
                </Box>
              )}
            />
            {error && <FormHelperText>{error.message}</FormHelperText>}
          </FormControl>
        );
      }}
      {...rest}
    />
  );
};

export default CheckBoxAutoComplete;
