import USER_FACILITY_PATHS from '../../constants/APIPaths/UserFacility';
import { getAPI, postAPI } from '../../helpers/API';

const { POST_FACILITY, GET_FACILITY_DETAILS, GET_FACILITY_LIST, GET_ACTIVE_FACILITY_LIST } =
  USER_FACILITY_PATHS;

export function getFacilityList(data) {
  return getAPI({
    url: GET_FACILITY_LIST,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

export function getFacilityDetailsById(data) {
  return getAPI({
    url: GET_FACILITY_DETAILS.replace(':facilityId', data?.facilityId),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

export function createFacility(data) {
  return postAPI({
    url: POST_FACILITY,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

export function getActiveFacilityList(data) {
  return getAPI({
    url: GET_ACTIVE_FACILITY_LIST,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}
