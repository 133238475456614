const styles = {
  wrapper: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    overflow: 'auto',
    position: 'relative',
    boxSizing: 'border-box'
  },

  imageWrapper: {
    display: {
      xs: 'none',
      md: 'flex'
    },
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    width: '65%',
    height: '100%'
  },

  image: {
    height: '100%',
    width: '100%',
    objectFit: 'cover'
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: {
      xs: '100%',
      md: '40%'
    },
    padding: '1rem 1rem 2rem',
    overflow: 'auto'
  },

  children: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  logoWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '1.5rem'
  },

  logo: {
    width: {
      xs: '120px',
      sm: '140px',
      md: '170px'
    }
  }
};

export default styles;
