import { Box } from '@mui/material';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { memo } from 'react';
import NoData from '../NoData';
import useDisplayScale from 'hooks/useDisplayScale';
import styles from './styles';

const TableDataRenderer = ({
  list,
  columnDefs,
  rowHeight = 120,
  onSelectionChanged,
  rowSelection = 'multiple',
  onGridReady,
  getRowHeight
}) => {
  const displayScale = useDisplayScale();

  return list?.length ? (
    <Box
      className="ag-theme-alpine"
      sx={{
        ...styles.wrapper,
        ...(displayScale <= 1 ? { height: 550, minHeight: 550 } : {})
      }}>
      <AgGridReact
        rowSelection={rowSelection}
        rowData={list || []}
        enableCellTextSelection
        columnDefs={columnDefs}
        rowMultiSelectWithClick
        onGridReady={onGridReady}
        suppressRowClickSelection
        wrapText
        rowHeight={rowHeight}
        animateRows
        suppressAutoSize
        autoHeight
        onSelectionChanged={onSelectionChanged}
        getRowHeight={getRowHeight}
      />
    </Box>
  ) : (
    <NoData />
  );
};

export default memo(TableDataRenderer);
