import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import Box from '@mui/material/Box';
import { CustomPopover, Pagination, Spinner, TableDataRenderer, Tooltip } from 'components/common';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useNavigate } from 'react-router-dom';
import styles from '../styles';

const SearchACNTable = ({ list = [], pageData, pageClick, isLoading, page }) => {
  const navigate = useNavigate();

  const [{ width }] = useWindowDimensions();
  const isWebView = width > 1000;

  const acnIdRowClick = (acnId, status) => {
    if (status === 'Consignment dispatched') navigate(`accept/${acnId}`);
    if (status === 'Consignment accepted' || status === 'Gate in')
      navigate(`allow-gate-in/${acnId}`);
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function trimAndCapitalize(status) {
    let parts = status.split(' ');
    let result = '';

    // Removing "Consignment" from the status
    for (let i = 0; i < parts.length; i++) {
      if (parts[i].toLowerCase() !== 'consignment') {
        result += parts[i] + ' ';
      }
    }

    // Capitalizing the first letter of the remaining word
    if (result.length > 0) {
      result = capitalizeFirstLetter(result.trim());
    }

    return result;
  }

  const columnDefs = [
    {
      field: 'ACNId',
      headerName: 'ACN ID/ PO No.',
      minWidth: 250,
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => {
        return (
          <Box sx={styles.tableLinkers}>
            <Box sx={styles.linkers}>
              ACN No:{' '}
              <Box
                _id="acn-no"
                component="a"
                className="links"
                href={`/view-consignment/${data?.ACNId}`}
                target="_blank">
                {data?.ACNId}
              </Box>
            </Box>
            <Box sx={styles.linkers}>
              PO No:{' '}
              <Box
                _id="po-no"
                className="links"
                component="a"
                href={`/view-orders/${data?.PONo}`}
                target="_blank">
                {data?.PONo}
              </Box>
            </Box>
          </Box>
        );
      }
    },
    {
      field: 'PlannedEDA',
      headerName: 'Planned EDA',
      flex: isWebView ? 1 : 0,
      minWidth: 250,
      cellRenderer: ({ data }) => <Tooltip title={data?.PlannedEDA} _id={'planned-eda'} />
    },
    {
      field: 'ConsgType',
      headerName: 'Consg Type',
      flex: isWebView ? 1 : 0,
      minWidth: 250,
      cellRenderer: ({ data }) => <Tooltip title={data?.ConsgType} _id={'consg-type'} />
    },
    {
      field: 'ItemDetails',
      headerName: 'Item Details',
      minWidth: 250,
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => {
        return (
          <Box sx={styles.tableLinkers}>
            <Box _id={'qty'} sx={styles.linkers}>
              Qty: {data.no_of_units || 0}
            </Box>
            <Box _id={'packages'} sx={styles.linkers}>
              Packages: {data.no_of_packages || 0}
            </Box>
          </Box>
        );
      }
    },
    {
      field: 'ShipToAddress',
      headerName: 'Ship to Address',
      minWidth: 250,
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => <Tooltip _id={'ship-to-address'} title={data.ShipToAddress} />
    },
    {
      field: 'ConsignmentStatus',
      headerName: 'Consignment Status',
      minWidth: 300,
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => {
        const status = trimAndCapitalize(data.ConsignmentStatus);
        return (
          <Box
            _id={'consignment-status'}
            sx={{
              ...styles.statusButton,
              backgroundColor: status === 'Rejected' ? '#FFE5E5A7' : '#E5F8FFA7',
              color: status === 'Rejected' ? '#F72021' : '#229CF7',
              borderColor: status === 'Rejected' ? '#F72121' : '#219CF7'
            }}>
            {status}
          </Box>
        );
      }
    },
    {
      field: 'Action',
      headerName: 'Action',
      minWidth: 250,
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data: cellData }) => {
        if (
          cellData?.ConsignmentStatus === 'Consignment rejected' ||
          cellData?.ConsignmentStatus === 'Gate in rejected' ||
          cellData?.ConsignmentStatus === 'In bay' ||
          cellData?.ConsignmentStatus === 'Gate out'
        )
          return 'No Action';
        const label = cellData.Action;
        return (
          <CustomPopover
            _id={`menu-button`}
            iconStyles={styles.actionWrapper}
            icon={<MoreVertOutlinedIcon sx={styles.actionIcon} />}>
            <Box
              _id={`menu-item-${label}`}
              onClick={() => acnIdRowClick(cellData?.ACNId, cellData?.ConsignmentStatus)}
              sx={styles.action}>
              {label}
            </Box>
          </CustomPopover>
        );
      }
    }
  ];

  return (
    <Box sx={styles.wrapper}>
      {isLoading ? (
        <Spinner />
      ) : (
        <TableDataRenderer rowHeight={120} list={list} columnDefs={columnDefs} />
      )}
      <Box sx={styles.paginationWrapper}>
        <Pagination
          count={pageData?.maxPage}
          page={page}
          onChange={(e, pageNumber) => pageClick(e, pageNumber)}
        />
      </Box>
    </Box>
  );
};

export default SearchACNTable;
