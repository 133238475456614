import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Accordion as MatAccordian } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import styles from './styles';

const Accordion = ({ heading, description, expanded, handleChange, customStyles }) => {
  return (
    <MatAccordian
      disableGutters
      elevation={0}
      square
      expanded={expanded}
      onChange={handleChange}
      sx={{ ...styles.wrapper, ...customStyles }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={styles.icon} />}
        sx={{ ...styles.summary, backgroundColor: expanded ? '#FFFAF2' : 'white' }}>
        <Box sx={styles.heading}>{heading}</Box>
      </AccordionSummary>
      <AccordionDetails sx={styles.details}>
        <Box sx={styles.description}>{description}</Box>
      </AccordionDetails>
    </MatAccordian>
  );
};

export default Accordion;
