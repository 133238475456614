import { Box } from '@mui/material';
import { MainContent, Pagination, Spinner, TableDataRenderer, Tooltip } from 'components/common';
import useWindowDimensions from 'hooks/useWindowDimensions';
import styles from './styles';

const RackList = ({ isLoading }) => {
  const [{ width }] = useWindowDimensions();
  const isWebView = width > 1000;

  const columnDefs = [
    {
      field: 'rack_name',
      headerName: 'Rack Name',
      minWidth: 250,
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => <Tooltip title={data.rack_name ? data.rack_name : 'No data'} />
    },
    {
      field: 'aisle_name',
      headerName: 'Aisle Name',
      minWidth: 250,
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => <Tooltip title={data.aisle_name ? data.aisle_name : 'No data'} />
    },
    {
      field: 'aisle_code',
      headerName: 'Aisle Code',
      minWidth: 250,
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => <Tooltip title={data.aisle_code ? data.aisle_code : 'No data'} />
    }
  ];

  return (
    <MainContent heading="Rack Details" description="Rack listing page">
      <Box sx={styles.wrapper}>
        {isLoading ? <Spinner /> : <TableDataRenderer list={[]} columnDefs={columnDefs} />}
        <Box sx={styles.paginationWrapper}>
          <Pagination
            count={10}
            page={1}
            //   onChange={(e, pageNumber) => pageClick(e, pageNumber)}
          />
        </Box>
      </Box>
    </MainContent>
  );
};

export default RackList;
