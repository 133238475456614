const styles = {
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    padding: {
      xs: '20px',
      sm: '20px 30px'
    },
    border: '1px solid #E1E3E5',
    backgroundColor: '#FFFFFF',
    borderRadius: '12px'
  },

  heading: {
    fontSize: {
      xs: '14px',
      sm: '16px'
    },
    fontWeight: 'bold'
  },

  dataWrapper: {
    width: {
      xs: '48%',
      sm: '30%',
      md: '20%'
    },
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    fontSize: '14px'
  },

  dataHeading: {
    fontWeight: 600
  },

  cardWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: 3
  },

  card: {
    display: 'flex'
  },

  label: {
    fontSize: {
      xs: '14px',
      sm: '16px'
    },
    width: '50%',
    padding: '15px 5px',
    borderRight: '1px solid',
    borderColor: 'custom.gray',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },

  value: {
    fontSize: {
      xs: '14px',
      sm: '16px'
    },
    width: '50%',
    padding: '15px 5px',
    borderColor: 'custom.gray',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
    // pb: '1em'
  }
};

export default styles;
