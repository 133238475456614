import { Box } from '@mui/material';
import { BAD_REQUEST, SUCCESS } from 'constants/apiStatusCodes';
import { ERROR, SUCCESS as SUCCESS_MSG } from 'constants/constants';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  GetUserGroupEditDetails,
  GetUserGroupList,
  GetUserRollList,
  deleteUserGroup
} from 'redux/APICaller/userPrivilegeAPI';
import { showErrorToast, showSuccessToast } from 'components/common/Toaster';
import CreateUserGroup from './userGroupCreateModal';
import { showToastMessage } from 'redux/showMessageSlice';
import { Button, MainContent } from '../../../common';
import ManageUserGroupTable from './Table';
import styles from './styles';

const dataKeyNameChange = (arrayOfObj) => {
  const newArrayOfObj = arrayOfObj?.map(
    ({ group_id: id, group_name: UserGroup, type: Type, status: Status, ...rest }) => ({
      id,
      UserGroup,
      Type,
      Status,
      ...rest
    })
  );
  return newArrayOfObj;
};

const ManageUserGroup = () => {
  const [open, setOpen] = useState(false);
  const [list, setList] = useState([]);
  const [rollList, setRollList] = useState([]);
  const [rollDefaultValue, setRollDefaultValue] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [deleteDisable, setDeleteDisable] = useState(false);
  const [editGroupDetails, setEditGroupDetails] = useState();

  const dispatch = useDispatch();

  const tempList = [
    {
      group_id: '2c77390b-b3f9-403a-be96-f87132894d3e',
      group_name: 'Anonymous',
      type: 'VENDOR',
      status: 'ENABLE'
    },
    {
      group_id: '48a89e81-44d3-4036-8cb7-f4d5d3cf4721',
      group_name: 'Admin',
      type: 'VENDOR',
      status: 'DISABLE'
    },
    {
      group_id: '66b65e53-c518-43a5-872f-a455b26c9aca',
      group_name: 'Manager',
      type: 'VENDOR',
      status: 'ENABLE'
    },
    {
      group_id: 'd7ac68cd-1f41-4ccc-87ee-2d6fbb5ef292',
      group_name: 'User',
      type: 'VENDOR',
      status: 'ENABLE'
    }
  ];

  const APICALL = async () => {
    setIsLoading(true);

    const res = await GetUserGroupList();

    if (res?.status === SUCCESS) {
      const newData = dataKeyNameChange(res?.data?.list);
      setList(newData);
    } else {
      if (res?.status === BAD_REQUEST) showErrorToast(res?.data?.message);
      else showErrorToast();
    }
    setIsLoading(false);
  };

  const getRollList = async () => {
    const res = await GetUserRollList();

    if (res?.status === SUCCESS) {
      const newList = [
        {
          role_id: 'a02fc5d5-d205-11ee-978d-b07b2566fd46',
          name: 'ROLE_bundling',
          role_keycloak_id: '76485654-b684-4c71-b0fd-417795f74890'
        },
        {
          role_id: 'a02fcab7-d205-11ee-978d-b07b2566fd46',
          name: 'ROLE_gate_yard_dock',
          role_keycloak_id: '00e4f088-02bb-4392-84cb-ae95e93fb14e'
        },
        {
          role_id: 'a02fcd5e-d205-11ee-978d-b07b2566fd46',
          name: 'ROLE_inbound',
          role_keycloak_id: '81adeec9-f61c-4c28-9fcd-e176382936de'
        },
        {
          role_id: 'a02fce3e-d205-11ee-978d-b07b2566fd46',
          name: 'ROLE_inventory',
          role_keycloak_id: '823076fe-23aa-46c7-a231-e41adc4228d3'
        },
        {
          role_id: 'a02fcf09-d205-11ee-978d-b07b2566fd46',
          name: 'ROLE_outbound',
          role_keycloak_id: '52881cc2-1603-4cfd-855f-765672886acf'
        },
        {
          role_id: 'a02fcfb4-d205-11ee-978d-b07b2566fd46',
          name: 'ROLE_packaging',
          role_keycloak_id: '28b9c239-640f-4d67-a4cd-7b63ce841c97'
        },
        {
          role_id: 'a02fd0be-d205-11ee-978d-b07b2566fd46',
          name: 'ROLE_returns',
          role_keycloak_id: 'c8d36e3b-dae1-43d4-ac49-06f0d80c3210'
        }
      ];

      setRollList(res?.data);
      // Form Default name obj
      const obj = {};
      res?.data?.map((item) => {
        obj[item?.name] = '';
      });

      setRollDefaultValue(obj);
    } else {
      if (res?.status === BAD_REQUEST) showErrorToast(res?.data?.message);
      else showErrorToast();
    }
  };

  useEffect(() => {
    APICALL();
    getRollList();
  }, []);

  const removeItemFromList = (ids) => {
    const newList = [];
    list?.forEach((item) => {
      let found = false;

      ids?.forEach((id) => {
        if (item?.id.toString() === id.toString()) {
          found = true;
        }
      });
      if (!found) {
        newList?.push(item);
      }
    });
    setList(newList);
  };

  const deleteUserGroupFun = async (group) => {
    setDeleteDisable(true);
    const res = await deleteUserGroup({ group_ids: [group?.id] });

    if (res?.status === SUCCESS) {
      removeItemFromList([group?.id]);
      showSuccessToast(res?.data?.message);
    } else {
      if (res?.status === BAD_REQUEST) showErrorToast(res?.data?.message);
      else showErrorToast();
    }
    setDeleteDisable(false);
  };

  const generateResponseObject = (array1, array2) => {
    const responseObject = {};

    for (let i = 0; i < array1.length; i++) {
      const item1 = array1[i];
      const existsInArray2 = array2.some((item2) => item2.name === item1.name);

      // Add key-value pair to the response object based on existence in the second array
      responseObject[item1.name] = existsInArray2 ? 'fullAccess' : 'noAccess';
    }

    return responseObject;
  };

  const editDetails = {
    group_name: 'Admin',
    type: 'VENDOR',
    status: 'ENABLE',
    roles: [
      {
        role_id: 'a02fcab7-d205-11ee-978d-b07b2566fd46',
        name: 'ROLE_gate_yard_dock',
        role_keycloak_id: '00e4f088-02bb-4392-84cb-ae95e93fb14e'
      },
      {
        role_id: 'a02fd0be-d205-11ee-978d-b07b2566fd46',
        name: 'ROLE_returns',
        role_keycloak_id: 'c8d36e3b-dae1-43d4-ac49-06f0d80c3210'
      },
      {
        role_id: 'a02fcd5e-d205-11ee-978d-b07b2566fd46',
        name: 'ROLE_inbound',
        role_keycloak_id: '81adeec9-f61c-4c28-9fcd-e176382936de'
      },
      {
        role_id: 'a02fcf09-d205-11ee-978d-b07b2566fd46',
        name: 'ROLE_outbound',
        role_keycloak_id: '52881cc2-1603-4cfd-855f-765672886acf'
      }
    ]
  };

  const editUserGroupFun = async ({ id }) => {
    const res = await GetUserGroupEditDetails({ id });

    if (res?.status === SUCCESS) {
      setEditGroupDetails({ ...res?.data, id });
      setOpen(true);
    } else {
      if (res?.status === BAD_REQUEST) showErrorToast(res?.data?.message);
      else showErrorToast();
    }
  };

  return (
    <MainContent
      heading="User Group"
      navigateLabel="Create New Group"
      navigateClick={() => {
        setEditGroupDetails('');
        setOpen(true);
      }}>
      <ManageUserGroupTable
        list={list}
        isLoading={isLoading}
        deleteDisable={deleteDisable}
        deleteUserGroup={deleteUserGroupFun}
        editUserGroup={editUserGroupFun}
      />

      {open && (
        <CreateUserGroup
          isOpen={open}
          setOpen={setOpen}
          rollDefaultValue={{
            userGroup: editGroupDetails ? editGroupDetails?.group_name : '',
            type: editGroupDetails ? editGroupDetails?.type : 'VENDOR',
            groupStatus: editGroupDetails ? editGroupDetails?.status : 'ENABLE',
            applyAll: '',
            ...(editGroupDetails
              ? generateResponseObject(rollList, editGroupDetails?.roles)
              : rollDefaultValue)
          }}
          rollList={rollList}
          APICALL={APICALL}
          editGroupDetails={editGroupDetails}
        />
      )}
    </MainContent>
  );
};

export default ManageUserGroup;
