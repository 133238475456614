import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import { Box } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { Checkbox, DynamicButton, Input, Password } from 'components/common';
import { DefaultSkeletton } from 'components/common/WebSkeleton';
import { INTERNAL_SERVER_ERROR, SUCCESS } from 'constants/apiStatusCodes';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { userLogin } from 'redux/APICaller/authAPIs';
import { showErrorToast, showSuccessToast } from 'components/common/Toaster';
import { Mixpanel } from 'mixpanel';
import packageJson from '../../../../../package.json';
import styles from '../styles';

const extractAndSortRoles = (roleArray) => {
  if (!roleArray || !roleArray?.length) return 'No-role-assigned';
  return roleArray
    .map((role) => role.replace('ROLE_', ''))
    .sort()
    .join(',');
};

const SignIn = () => {
  const navigate = useNavigate();

  const {
    control,
    formState: { errors },
    trigger,
    getValues
  } = useForm({
    defaultValues: {
      username: '',
      password: ''
    },
    mode: 'all',
    criteriaMode: 'all'
  });

  const { mutate, isPending } = useMutation({
    mutationFn: (data) => userLogin(data),
    onSuccess: (res) => {
      if (res?.status === SUCCESS) {
        if (res?.data?.message) showSuccessToast(res?.data?.message);
        localStorage.setItem('wms_access_token', res.data.access_token);
        localStorage.setItem('wms_refresh_token', res.data.refresh_token);
        localStorage.setItem(
          'wms_access_roles',
          `${
            res?.data?.roles?.length && res?.data?.type_of_user === 'Vendor'
              ? `${res?.data?.roles},${res?.data?.type_of_user},Master`
              : res?.data?.roles
          }`
        );
        localStorage.setItem('type_of_user', res.data.type_of_user);
        Mixpanel.people.set({
          $userType: res?.data?.type_of_user,
          $role: extractAndSortRoles(res?.data?.roles)
        });
        Mixpanel.track('Successful login');

        if (res?.data?.type_of_user === 'User') navigate(`/`);
        else navigate(`/getting-started`);
        if (!res?.data?.roles?.length) {
          window.location.reload();
        }
      } else if (res?.status !== INTERNAL_SERVER_ERROR) {
        showErrorToast(res?.data?.message);
        Mixpanel.track('Login fail');
      }
    },
    onError: () => {
      showErrorToast();
    }
  });

  const handleClick = (res) => {
    if (res) {
      const { username, password } = getValues();
      Mixpanel.reset();
      Mixpanel.identify(username);
      Mixpanel.people?.set({
        $email: username,
        $Version: packageJson?.buildVersion
      });
      mutate({ username, password });
    }
  };

  return (
    <Box sx={styles.wrapper}>
      <Box>
        <Box sx={styles.heading}>Hi, Welcome Back</Box>
        <Box sx={styles.description}>Loren epsom simply dummy text for text use.</Box>
      </Box>
      <Box sx={styles.inputWrapper}>
        <Input
          name="username"
          type="email"
          label="Email"
          placeholder="Email Address"
          loading={isPending}
          customStyles={styles.input}
          startIcon={<MailOutlinedIcon />}
          control={control}
          errors={errors}
          rules={{
            required: 'This is a required field',
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: 'Please provide a valid email'
            }
          }}
        />
        <Password
          name="password"
          label="Password"
          customStyles={styles.input}
          placeholder="Password"
          loading={isPending}
          startIcon={<LockOutlinedIcon />}
          control={control}
          errors={errors}
          rules={{ required: 'This is a required field' }}
        />
      </Box>
      <Box sx={styles.authWrapper}>
        <DefaultSkeletton loading={isPending}>
          <Checkbox
            customStyles={{
              fontSize: {
                xs: '14px',
                sm: '16px'
              }
            }}
            name="rememberMe"
            label="Remember Me"
            control={control}
          />
        </DefaultSkeletton>
        <DefaultSkeletton loading={isPending}>
          <Box sx={styles.linkers} onClick={() => navigate('/forgot-password')}>
            Forgot Password?
          </Box>
        </DefaultSkeletton>
      </Box>
      <Box sx={styles.buttonWrapper}>
        <DynamicButton
          label="Log in"
          customStyles={styles.button}
          disabled={isPending}
          onClick={() => trigger().then((res) => handleClick(res))}
        />
      </Box>
      <Box sx={styles.footer}>
        <DefaultSkeletton loading={isPending} customStyles={styles.footerLoading}>
          <Box>
            Don’t have an account?{' '}
            <Box component="span" sx={styles.linkers} onClick={() => navigate('/sign-up')}>
              Sign up
            </Box>
          </Box>
        </DefaultSkeletton>
      </Box>
    </Box>
  );
};

export default SignIn;
