import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectAuthStore } from 'redux/authSlice';
import { stepList } from '../Data/constant';
import StepOpener from '../StepOpener';
import { Mixpanel } from 'mixpanel';
import styles from './styles';

const convertToKebabCase = (str) => {
  return str
    .toLowerCase()
    .replace(/[^\w\s-]/g, '') // Remove special characters except hyphens and spaces
    .replace(/\s+/g, '-'); // Replace spaces with hyphens
};

const StepList = () => {
  const { appDisable } = useSelector(selectAuthStore);

  const navigate = useNavigate();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [expanded, setExpanded] = useState(false);

  // Use this when roles come from backend
  let roles = localStorage.getItem('wms_access_roles');
  let type_of_user = localStorage.getItem('type_of_user'); // Vendor || User

  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleClick = (event, index) => {
    setAnchorEl((prev) => ({
      ...prev,
      [index]: prev && prev[index] ? null : event.currentTarget
    }));
  };

  const handleClose = () => {
    setAnchorEl(null);
    setExpanded(false);
  };

  const accessRoles = roles ? roles.split(',') : [];

  /**
   * Filtering list based on Roles
   */

  const filterStepperList = () => {
    return stepList?.filter((step) => {
      if (appDisable) {
        return stepList;
      }
      const hasRequiredRoles = [
        ...(type_of_user === 'Vendor' ? ['ROLE_onboarding'] : []),
        ...accessRoles
      ].includes(step.role);

      return hasRequiredRoles;
    });
  };

  const isRouteMatch = (locationPathname, route) => {
    if (locationPathname === '/' && route === '/') {
      return true;
    }
    if (route === '/' && locationPathname !== '/') return false;
    return locationPathname.includes(route);
  };

  const filteredStepperList = filterStepperList();

  return (
    <Box sx={styles.wrapper}>
      {filteredStepperList?.map(({ heading, icon, subHeading, nestedHeading }, index) => (
        <Box key={index} sx={styles.container}>
          <IconButton
            _id={`${convertToKebabCase(heading)}-module`}
            sx={styles.iconButton}
            onClick={(event) => handleClick(event, index)}>
            <Box component="img" src={icon} alt="logo" />
          </IconButton>
          <Box
            sx={{
              ...styles.heading,
              textDecoration: anchorEl && anchorEl[index] ? 'underline' : 'none',
              color: anchorEl && anchorEl[index] ? 'primary.main' : 'black'
            }}>
            {heading}
          </Box>
          <StepOpener anchorEl={anchorEl && anchorEl[index]} handleClose={handleClose}>
            <Box>
              {subHeading &&
                subHeading.map(({ label, route }, idx) => {
                  const checkActivePath = isRouteMatch(location.pathname, route);

                  return (
                    <Box
                      _id={`${convertToKebabCase(heading)}-${convertToKebabCase(label)}`}
                      key={idx}
                      onClick={() => {
                        if (appDisable) return false;
                        navigate(route);
                        handleClose();
                        Mixpanel.track('Tab clicked', {
                          path: `${filteredStepperList[index]?.heading} -> ${subHeading[idx]?.label}`,
                          device: 'desktop'
                        });
                      }}
                      sx={{
                        ...styles.details,
                        backgroundColor: checkActivePath ? '#FFF8EB' : 'transparent',
                        cursor: appDisable ? 'not-allowed' : 'pointer'
                      }}>
                      {label}
                      {checkActivePath && <Box sx={styles.leftBar} />}
                    </Box>
                  );
                })}
              {nestedHeading &&
                nestedHeading.map(({ label, components }, subIndex) => (
                  <Accordion
                    square
                    key={subIndex}
                    disableGutters
                    elevation={0}
                    sx={styles.mainAccordian}
                    expanded={expanded === `panel${subIndex}`}
                    onChange={handleChange(`panel${subIndex}`)}>
                    <AccordionSummary
                      _id={`${convertToKebabCase(heading)}-${convertToKebabCase(label)}`}
                      expandIcon={<ExpandMoreIcon sx={styles.icon} />}>
                      <Box sx={styles.label}>{label}</Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      {components.map(({ label, route }, idx) => {
                        const checkActivePath = isRouteMatch(location.pathname, route);

                        return (
                          <Box
                            key={idx}
                            _id={`${convertToKebabCase(heading)}-${convertToKebabCase(
                              nestedHeading[subIndex]?.label
                            )}-${convertToKebabCase(label)}`}
                            onClick={() => {
                              if (appDisable) return false;
                              navigate(route);
                              handleClose();
                              Mixpanel.track('Tab clicked', {
                                path: `${filteredStepperList[index]?.heading} -> ${nestedHeading[subIndex]?.label} -> ${components[idx]?.label}`,
                                device: 'desktop'
                              });
                            }}
                            sx={{
                              ...styles.details,
                              backgroundColor: checkActivePath ? '#FFF8EB' : 'transparent',
                              cursor: appDisable ? 'not-allowed' : 'pointer'
                            }}>
                            {label}
                            {checkActivePath && <Box sx={styles.leftBar} />}
                          </Box>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                ))}
            </Box>
          </StepOpener>
        </Box>
      ))}
    </Box>
  );
};

export default StepList;
