const styles = {
  wrapper: {
    width: '100%'
  },

  tabsWrapper: {
    '& .MuiTab-root': {
      minWidth: '138px',
      backgroundColor: '#F4F4F4',
      borderRadius: '7px',
      color: 'black',
      fontSize: '15px',
      textTransform: 'capitalize'
    },

    '& .Mui-selected': {
      color: 'black',
      border: '1px solid',
      borderColor: 'primary.main',
      backgroundColor: '#FFEFD3'
    },

    '& .MuiTabs-indicator': {
      display: 'none'
    },

    '& .MuiTabs-flexContainer': {
      gap: '20px'
    }
  },

  tab: {
    fontSize: {
      xs: '14px',
      sm: '16px'
    }
  },

  tabsContainer: {
    marginTop: '2rem'
  }
};

export default styles;
