import { Box } from '@mui/material';
import { Dropdown, Input, Modal, Radio } from 'components/common';
import { showErrorToast, showSuccessToast } from 'components/common/Toaster';
import { BAD_REQUEST, SUCCESS } from 'constants/apiStatusCodes';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { CreateUserGroup, UpdateUserGroup } from 'redux/APICaller/userPrivilegeAPI';
import { status } from './constants';
import styles from './styles';

const extractNames = (arrayOfObjects) => {
  // Use map to extract names from each object
  const namesArray = arrayOfObjects?.map((obj) => obj?.name);

  return namesArray;
};

const formatRoleName = (role) => {
  // Remove the "ROLE_" prefix
  const roleNameWithoutPrefix = role.replace(/^ROLE_/, '');

  // Split the remaining string by underscores
  const words = roleNameWithoutPrefix.split('_');

  // Capitalize the first letter of each word
  const formattedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));

  // Join the words back together with spaces
  const formattedRoleName = formattedWords.join(' ');

  return formattedRoleName;
};

const CreateUserGroups = ({
  isOpen,
  setOpen,
  rollDefaultValue,
  rollList,
  APICALL,
  editGroupDetails
}) => {
  const dispatch = useDispatch();
  const { control, watch, setValue, getValues, trigger } = useForm({
    defaultValues: rollDefaultValue,
    mode: 'all',
    criteriaMode: 'all'
  });
  const [btnDisable, setBtnDisable] = useState(false);

  const applyAll = watch('applyAll');

  useEffect(() => {
    if (applyAll) {
      if (applyAll === 'fullAccess') {
        rollList?.map(({ role_id, name }) => {
          setValue(name, 'fullAccess');
        });
      } else if (applyAll === 'noAccess') {
        rollList?.map(({ role_id, name }) => {
          setValue(name, 'noAccess');
        });
      }
    }
  }, [applyAll]);

  const rollChange = watch(extractNames(rollList));

  const checkRollArrayEquality = (arr) => {
    // Check if all elements in the array are the same
    const allSame = arr.every((item) => item === arr[0]);

    // Return the result based on the check
    return allSame ? arr[0] : 'mixAccess';
  };

  useEffect(() => {
    if (rollChange?.length) {
      const state = checkRollArrayEquality(rollChange);

      if (state && state === 'mixAccess' && getValues('applyAll')) {
        setValue('applyAll', '');
      } else if (state && state === 'fullAccess') {
        setValue('applyAll', 'fullAccess');
      } else if (state && state === 'noAccess') {
        setValue('applyAll', 'noAccess');
      }
    }
  }, [checkRollArrayEquality(rollChange)]);

  const createUsergroupApi = useCallback(
    async (customPayload) => {
      setBtnDisable(true);
      const res = await CreateUserGroup({
        ...customPayload
      });
      if (res.status === SUCCESS) {
        showSuccessToast(res?.data?.message);
        APICALL();
        setOpen(false);
      } else {
        if (res?.status === BAD_REQUEST || res?.status === 409) showErrorToast(res?.data?.message);
        else showErrorToast();
      }
      setBtnDisable(false);
    },
    [dispatch]
  );

  const updateUsergroupApi = useCallback(
    async (customPayload) => {
      setBtnDisable(true);
      const res = await UpdateUserGroup({
        ...customPayload
      });
      if (res.status === SUCCESS) {
        APICALL();
        setOpen(false);
        showSuccessToast(res?.data?.message);
      } else {
        if (res?.status === BAD_REQUEST || res?.status === 409) showErrorToast(res?.data?.message);
        else showErrorToast();
      }
      setBtnDisable(false);
    },
    [dispatch]
  );

  const finishExecution = useCallback(
    (res) => {
      if (res) {
        if (!checkRollArrayEquality(rollChange)) showErrorToast('Please select at least one Roll');
        else {
          const group_name = getValues('userGroup')?.trim();
          const type = getValues('type');
          const status = getValues('groupStatus');
          const roles = [];
          rollList?.map(({ role_id, name, role_keycloak_id }, index) => {
            if (getValues(name) === 'fullAccess') {
              roles.push({ role_id, name, role_keycloak_id });
            }
          });
          const payload = {
            group_name,
            type,
            status,
            roles
          };
          if (editGroupDetails) updateUsergroupApi({ ...payload, id: editGroupDetails?.id });
          else createUsergroupApi(payload);
        }
      }
    },
    [rollChange, editGroupDetails]
  );

  const triggerValidation = useCallback(() => {
    trigger().then((res) => finishExecution(res));
  }, [trigger, finishExecution]);

  const isThreeDigits1 = (value) => {
    return value?.length < 3 ? 'User Group name should be of more than 3 latter' : true;
  };

  return (
    <Modal
      open={isOpen}
      handleClose={() => setOpen(false)}
      buttonLabel={editGroupDetails ? 'Update' : 'Create'}
      onSuccess={() => {
        triggerValidation();
      }}
      customStyles={{ height: '80%' }}
      disabled={btnDisable}
      heading={editGroupDetails ? 'Edit Usergroup' : 'Create New Usergroup'}>
      <Box sx={styles.modalWrapper}>
        <Box sx={styles.modalInput}>
          <Input
            name="userGroup"
            label="User Group"
            isRequired
            control={control}
            rules={{
              required: 'This is required field',
              validate: {
                isThreeDigits: (value) => {
                  isThreeDigits1(value);
                }
              }
            }}
          />
          <Dropdown
            name="type"
            label="Type"
            isRequired
            control={control}
            disabled
            options={[{ label: 'Vendor', value: 'VENDOR' }]}
          />
        </Box>
        <Box sx={styles.modalFlex}>
          <Box>
            Status{' '}
            <Box component="span" sx={styles.required}>
              *
            </Box>
          </Box>
          <Radio
            name="groupStatus"
            control={control}
            options={status}
            customStyles={styles.modalRadio}
          />
        </Box>
        <Box>
          <Box sx={styles.modalFlex}>
            <Box sx={{ width: '50%' }}>Apply Value to All</Box>
            <Radio
              name="applyAll"
              control={control}
              options={[
                {
                  label: 'Full Access',
                  value: 'fullAccess'
                },
                {
                  label: 'No Access',
                  value: 'noAccess'
                }
              ]}
              customStyles={styles.modalRadio}
            />
          </Box>
          {rollList?.map(({ role_id, name }, index) => {
            return (
              <Box key={index} sx={styles.modalFlex}>
                <Box sx={{ width: '50%' }}>{formatRoleName(name)}</Box>
                <Radio
                  name={name}
                  control={control}
                  options={[
                    {
                      label: 'Full Access',
                      value: 'fullAccess'
                    },
                    {
                      label: 'No Access',
                      value: 'noAccess'
                    }
                  ]}
                  customStyles={styles.modalRadio}
                />
              </Box>
            );
          })}
        </Box>
      </Box>
    </Modal>
  );
};

export default CreateUserGroups;
