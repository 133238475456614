import { useState, useEffect } from 'react';

function useDisplayScale() {
  const [displayScale, setDisplayScale] = useState(window.devicePixelRatio);

  useEffect(() => {
    const handleResize = () => {
      setDisplayScale(window.devicePixelRatio);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return displayScale;
}

export default useDisplayScale;
