import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import styles from './styles';

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: styles.toolTip
      }
    }
  }
});

const ErrorMessage = ({ errorMsg = 'Invalid' }) => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={styles.wrapper}>
        <Tooltip arrow title={errorMsg} placement="top">
          <IconButton>
            <InfoOutlinedIcon sx={styles.icon} />
          </IconButton>
        </Tooltip>
        <Box sx={styles.message}>{errorMsg}</Box>
      </Box>
    </ThemeProvider>
  );
};

export default ErrorMessage;
