const styles = {
  wrapper: {
    '& .MuiInputBase-input.Mui-disabled': {
      cursor: 'not-allowed'
    },

    '& .MuiInputBase-input': {
      padding: '4px 6px 5px'
    },

    '& .user-input': {
      // borderColor: '#dedede'
    }
  },

  input: {
    position: 'relative',
    '& .user-input': {
      // borderColor: '#dedede'
    }
  },

  label: {
    '& .MuiFormLabel-asterisk': {
      color: 'red'
    }
  },

  searchIconWrapper: {
    marginRight: '-11px',
    height: {
      xs: '34px',
      sm: '38px'
    }
  },

  closeIconWrapper: {
    position: 'absolute',
    bottom: {
      xs: 2,
      sm: 4
    }
  },

  icon: {
    color: '#232F3E'
  }
};

export default styles;
