const RETURN_PATHS = {
  CREATE_RO: `/return/sales/order/import`,
  RMA_LIST_B2C: `/return/sales/order/rma/B2C/details`,
  RMA_LIST_B2B: `/return/sales/order/rma/B2B/details`,
  RMA_LIST_EXPORT_B2C: `/return/sales/order/rma/B2C/details/export`,
  RMA_LIST_EXPORT_B2B: `/return/sales/order/rma/B2B/details/export`,
  MANIFEST_LIST_B2C: `/return/sales/order/manifest/B2C/details`,
  MANIFEST_LIST_B2B: `/return/sales/order/manifest/B2B/details`,
  RECEIVE_MANIFEST_LIST_B2B: `/return/sales/order/manifest/B2B/receive`,
  RECEIVE_MANIFEST_LIST_B2C: `/return/sales/order/manifest/B2C/receive`,
  SCAN_RMA_ID: `/return/sales/order/validate`,
  CREATE_RETURN_MANIFEST_B2C: `/return/sales/order/manifest`,
  CREATE_RETURN_MANIFEST_B2B: `/return/sales/order/b2b/manifest`,
  RETURN_MANIFEST_DETAILS_BY_ID_B2C: '/return/sales/order/manifest/:id',
  RETURN_MANIFEST_POD_FILE_BY_ID_B2C: '/return/sales/order/manifest/pod/:id',
  RETURN_MANIFEST_POD_FILE_UPLOAD_BY_ID_B2C: '/return/sales/order/manifest/pod',
  RETURN_MANIFEST_ATTACHMENT_BY_ID_B2C: '/return/sales/order/manifest/items/attachments/:id',
  RETURN_MANIFEST_ATTACHMENT_DOWNLOAD_BY_ID_B2C:
    '/return/sales/order/manifest/items/attachments/download/:id',
  RETURN_MANIFEST_DETAILS_BY_ID_B2B: '/return/sales/order/manifest/:id/invoices',
  RETURN_MANIFEST_POD_FILE_UPLOAD_BY_ID_B2B: '/return/sales/order/invoice/pod',
  RETURN_PUTBACK: '/return/sales/order/putlist/list',
  RETURN_MANIFEST_DETAILS_LIST_BY_ID_B2C: '/return/sales/order/manifest/details/b2b',
  RETURN_CREATE_PUTBACK: '/return/sales/order/putlist',
  RETURN_MANIFEST_POD_DETAILS_BY_ID_B2B: '/return/sales/order/manifest/item/pod/:id',
  RETURN_MANIFEST_POD_DETAILS_FILE_DOWNLOAD_BY_ID_B2B: '/return/sales/order/download/pod/b2b/:id',
  RECEIVE_RETURN_MANIFEST_B2C: '/return/sales/order/manifest/items/:id',
  RECEIVE_RETURN_MANIFEST_B2B: '/return/sales/order/manifest/:id',
  RECEIVE_RETURN_MANIFEST_SUBMIT_B2C: '/return/sales/order/manifest/qc-check',
  RECEIVE_RETURN_MANIFEST_WID_PRINT_B2C: '/return/sales/order/print/wid/:id',
  RECEIVE_PENDING_PUTBACK: '/return/sales/order/putlist/:id',
  RECEIVE_RETURN_MANIFEST_SUBMIT_B2B: '/return/sales/order/manifest/details',
  RETURN_STAGING_INVEBTORY_LIST: '/return/sales/order/inventory/staging',
  RETURN_STAGING_INVEBTORY_LIST_CSV: '/return/sales/order/inventory/staging/export',
  RETURN_PRINT_PUTLIST: '/return/sales/order/putlist/print',
  CONFIRM_PUTAWAY: '/return/sales/order/confirm/putaway/:id',
  CREATE_PUTBACK: '/return/sales/order/putlist',
  QA_FAIL_FILE_UPLOAD: '/file/upload/image_or_video'
};

export default RETURN_PATHS;
