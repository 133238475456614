import AREA_PATHS from '../../constants/APIPaths/Area';
import { getAPI, postAPI } from '../../helpers/API';

const { CREATE_AREA, GET_AREA_BY_ID } = AREA_PATHS;

export function createArea(data) {
  return postAPI({
    url: CREATE_AREA,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

export function getAreaByID(data) {
  return getAPI({
    url: GET_AREA_BY_ID.replace(':id', data?.id),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}
