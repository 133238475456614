import DoneIcon from '@mui/icons-material/Done';
import { Box } from '@mui/material';
import React, { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Dropdown, Input } from '../../../../common';
import styles from '../styles';
import { useDispatch } from 'react-redux';
import { ERROR_MESSAGE, ERROR_MSG, SUCCESS_MSG } from '../../../../../constants/constants';
import { showToastMessage } from '../../../../../redux/showMessageSlice';
import DeleteIcon from '@mui/icons-material/Delete';
import { showErrorToast, showSuccessToast } from 'components/common/Toaster';

const transformData = (inputArray) => {
  return inputArray?.map((item) => ({
    label: item?.link ? `${item?.link}-${item?.code}` : item?.code,
    value: item?.link ? `${item?.link}-${item?.code}` : item?.code
  }));
};

const Shelf = ({ loading, setShelfList, rackList, storageType }) => {
  const dispatch = useDispatch();

  const [shelfData, setShelfData] = useState([
    {
      shelfCode: ''
    }
  ]);

  const { control, trigger, getValues, setValue } = useForm({
    defaultValues: {},
    mode: 'all',
    criteriaMode: 'all'
  });

  const addShelf = () => {
    if (
      shelfData?.length &&
      !shelfData[shelfData?.length - 1]?.delete &&
      !getValues(`${'shelf'}-${shelfData?.length - 1}`)
    ) {
      return;
    }
    const newShelfData = [
      ...shelfData,
      {
        rackCode: ''
      }
    ];
    setShelfData(newShelfData);
  };

  const saveShelfPayload = (shelfData) => {
    const list = [];
    shelfData?.forEach((shelf, index) => {
      if (getValues(`${'shelf'}-${index}`)) {
        list.push({
          code: getValues(`${'shelf'}-${index}`),
          link: getValues(`${'shelf-link'}-${index}`) ? getValues(`${'shelf-link'}-${index}`) : ''
        });
      }
    });

    setShelfList(list);
  };

  const saveShelf = (val, index) => {
    if (val) {
      // check if value already exist
      let valueFound = false;
      shelfData?.forEach((shelf, ind) => {
        if (ind !== index) {
          if (
            getValues(`${'shelf'}-${ind}`) === getValues(`${'shelf'}-${index}`) &&
            getValues(`${'shelf-link'}-${ind}`) === getValues(`${'shelf-link'}-${index}`)
          )
            valueFound = true;
        }
      });

      if (valueFound) {
        showErrorToast('Shelf  already exist');
        return;
      }

      const tempRackData = shelfData?.map((shelf, ind) => {
        if (index === ind) return { ...shelf, save: true };
        else return shelf;
      });

      setShelfData(tempRackData);
      saveShelfPayload(tempRackData);
    }
  };

  const deleteShelf = (index) => {
    setValue(`${'shelf'}-${index}`, '');

    const tempRackData = shelfData?.map((shelf, ind) => {
      if (index === ind) return { ...shelf, delete: true };
      else return shelf;
    });

    setShelfData(tempRackData);
    saveShelfPayload(tempRackData);
  };

  return (
    <Box sx={styles.formWrapper}>
      {shelfData.map((shelf, index) => {
        if (shelf?.delete) return null;
        else
          return (
            <Box key={index} sx={styles.rackWrapper}>
              <Input
                name={`${'shelf'}-${index}`}
                label="Shelf Code"
                control={control}
                customStyles={storageType == 2 ? { width: '90%' } : styles.input}
                isRequired
                // onKeyPress={(event) => {
                //   if (!/[0-9+]/.test(event.key)) {
                //     event.preventDefault();
                //   }
                // }}
                loading={loading}
                rules={{ required: 'This is a required field' }}
                disabled={shelf?.save || index < shelfData?.length - 1}
              />
              {storageType != 2 ? (
                <Dropdown
                  name={`${'shelf-link'}-${index}`}
                  label="Link Rack"
                  control={control}
                  customStyles={styles.input}
                  isRequired
                  options={transformData(rackList)}
                  loading={loading}
                  rules={{ required: 'This is a required field' }}
                  disabled={shelf?.save || index < shelfData?.length - 1}
                />
              ) : null}
              {shelf?.save ? (
                <Box
                  sx={styles.validityCheck}
                  onClick={() => {
                    deleteShelf(index);
                  }}>
                  <DeleteIcon />
                </Box>
              ) : (
                <Box
                  sx={styles.validityCheck}
                  onClick={() => {
                    trigger().then((res) => saveShelf(res, index));
                  }}>
                  <DoneIcon />
                </Box>
              )}
            </Box>
          );
      })}
      {shelfData[shelfData?.length - 1]?.save && (
        <Box sx={styles.addButton} onClick={addShelf}>
          + Add Shelf
        </Box>
      )}
    </Box>
  );
};

export default Shelf;
