import { Box, Drawer } from '@mui/material';
import { Spinner } from 'components/common';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { Suspense, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAuthStore } from 'redux/authSlice';
import MobileList from './MobileList';
import Navbar from './Navbar';
import StepList from './StepList';
import styles from './styles';

const Dashboard = ({ children, facilityFetched }) => {
  const [{ width }] = useWindowDimensions();
  const isMobile = width < 900;
  const [showStepper, setShowStepper] = useState(false);
  const authStore = useSelector(selectAuthStore);

  useEffect(() => {
    setShowStepper(!isMobile);
  }, [isMobile]);

  const drawer = (
    <Drawer
      variant="persistent"
      anchor="left"
      open={showStepper}
      elevation={5}
      PaperProps={{ variant: 'outlined' }}
      sx={styles.drawerPaper}>
      {isMobile ? <MobileList setShowStepper={setShowStepper} /> : <StepList />}
      {/* <StepperList setShowStepper={setShowStepper} /> */}
    </Drawer>
  );

  return (
    <Box sx={styles.appFrame}>
      {drawer}
      <Navbar
        showStepper={showStepper}
        setShowStepper={setShowStepper}
        yms={authStore?.applicationType}
        warehouse={authStore?.warehouseLocation}
        orderType={authStore?.orderType}
      />
      {facilityFetched ? (
        <Suspense fallback={<Spinner />}>
          <Box
            sx={{
              ...styles.drawerContent,
              opacity: showStepper && isMobile ? 0.3 : 1,
              width: {
                xs: '100%',
                md: showStepper ? `calc(100% - 120px)` : '100%'
              },
              marginLeft: {
                xs: '0',
                md: showStepper ? '120px' : 0
              }
            }}>
            <Box sx={styles.content} onClick={() => isMobile && setShowStepper(false)}>
              {children}
            </Box>
          </Box>
        </Suspense>
      ) : (
        <Spinner />
      )}
    </Box>
  );
};

export default Dashboard;
