import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styles from '../styles';

export const B2BRenderer = (data) => {
  const navigate = useNavigate();
  return (
    <Box sx={styles.orderIDWrapper}>
      <Box sx={styles.orderId} onClick={() => navigate(`${data?.order_id}/${data?.Channel}`)}>
        OD- {data?.order_id}
      </Box>
      <Box sx={styles.shipmentId}>Shipment ID: {data?.order_no}</Box>
    </Box>
  );
};

export const B2CRenderer = (data, isB2C) => {
  const navigate = useNavigate();
  return (
    <Box sx={styles.orderIDWrapper}>
      <Box
        sx={styles.orderId}
        onClick={() =>
          navigate(`${data?.order_id}/${data?.order_code || data?.order_no}/${data?.Channel}`)
        }>
        Order Id: {data?.order_no}
      </Box>
      {isB2C ? (
        <Box sx={styles.shipmentId}>Shipment Id: {data?.shipment_code}</Box>
      ) : (
        <Box sx={styles.shipmentId}>Sales Order Id: {data?.order_code}</Box>
      )}
    </Box>
  );
};
