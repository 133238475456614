const styles = {
  mainPopover: {
    '& .MuiPopover-paper': {
      boxShadow: '0px 0px 13px #00000015',
      border: '1px solid #EAEBEE',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      borderRadius: '12px',
      padding: '8px',
      width: {
        xs: 220,
        sm: 240
      },
      maxHeight: 300,
      marginLeft: '120px',
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    }
  }
};

export default styles;
