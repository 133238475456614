import { USER_PRIVILEGE_PATHS } from '../../constants/apiPaths';
import { getAPI, patchAPI, postAPI, putAPI } from '../../helpers/API';

const {
  GET_USER_GROUP_LIST,
  GET_USER_ROLL_LIST,
  DELETE_USER_GROUP,
  CREATE_USER,
  UPDATE_USER,
  GET_USER,
  CREATE_USER_GROUP,
  GET_USER_GROUP_EDIT_DETAILS,
  UPDATE_USER_GROUP,
  GET_USER_LIST,
  UPDATE_USER_GROUP_STATUS,
  GET_USER_GROUP_LIST_BY_USER_ID
} = USER_PRIVILEGE_PATHS;

export function GetUserGroupList(data) {
  return getAPI({
    url: GET_USER_GROUP_LIST,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

export function GetUserGroupEditDetails(data) {
  return getAPI({
    url: GET_USER_GROUP_EDIT_DETAILS.replace(':id', data?.id),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

export function CreateUserGroup(data) {
  return postAPI({
    url: CREATE_USER_GROUP,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

export function GetUserRollList(data) {
  return getAPI({
    url: GET_USER_ROLL_LIST,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

export function deleteUserGroup(data) {
  return patchAPI({
    url: DELETE_USER_GROUP,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

export function createNewUser(data) {
  return postAPI({
    url: CREATE_USER,
    data,
    isPrivate: true
  });
}

export function updateUser(data) {
  return patchAPI({
    url: UPDATE_USER,
    data,
    isPrivate: true
  });
}

export function getUser(data) {
  return getAPI({
    url: GET_USER.replace(':id', data?.id),
    data,
    isPrivate: true
  });
}

export function UpdateUserGroup(data) {
  return putAPI({
    url: UPDATE_USER_GROUP.replace(':id', data?.id),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

export function GetUserList(data) {
  return getAPI({
    url: GET_USER_LIST,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: false
  });
}

export function UpdateBulkUserStatus(data) {
  return patchAPI({
    url: UPDATE_USER_GROUP_STATUS,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: false
  });
}

export function GetUserGroupListByUserId(data) {
  return getAPI({
    url: GET_USER_GROUP_LIST_BY_USER_ID.replace(':userId', data?.userId),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: false
  });
}

export function associateUsersTogroup(data) {
  return postAPI({
    url: GET_USER_GROUP_LIST_BY_USER_ID.replace(':userId', data?.userId),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: false
  });
}
