import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { stateListConverter, cityListConverter } from '../../../../constants/apiDataChanger';
import { getStateList, getCityList } from '../../../../redux/APICaller/userProfileAPIs';
import { Dropdown, Input, Radio } from '../../../common';
import styles from '../styles';

const CreateSuppliers = ({
  disabled,
  control,
  loading = false,
  watch,
  setValue,
  state,
  addWarehouse,
  setAddWarehouse,
  trigger,
  getValues
}) => {
  const { suppliersId } = useParams();

  const { data: stateData = { data: [] } } = useQuery({
    queryKey: ['state'],
    queryFn: () => {
      return getStateList({ id: 'india' });
    }
    // enabled: !suppliersId
  });

  const { data: cityData = { data: [] } } = useQuery({
    queryKey: [watch(`warehouseState-${addWarehouse?.length - 1}`)],
    queryFn: async () => {
      const res = await getCityList({ id: watch(`warehouseState-${addWarehouse?.length - 1}`) });
      const tempAddWarehouse = [...addWarehouse];
      tempAddWarehouse[tempAddWarehouse?.length - 1].cityList = res?.data;
      setAddWarehouse(tempAddWarehouse);
      return res;
    },
    enabled: !!watch(`warehouseState-${addWarehouse?.length - 1}`)
  });

  const addWarehouseWrap = () => {
    const newWarehouse = [
      ...addWarehouse,
      {
        warehouseCode: ''
      }
    ];
    setAddWarehouse(newWarehouse);
  };

  useEffect(() => {
    if (getValues(`warehouseCity-${addWarehouse?.length - 1}`)) {
      setValue(`warehouseCity-${addWarehouse?.length - 1}`, '');
    }
  }, [watch(`warehouseState-${addWarehouse?.length - 1}`)]);

  return (
    <Box sx={styles.formWrapper}>
      {suppliersId && (
        <Fragment>
          {addWarehouse?.map((input, index) => (
            <Fragment key={index}>
              <Input
                disabled={index < addWarehouse?.length - 1}
                loading={loading}
                name={`warehouseCode-${index}`}
                label="Warehouse Code"
                customStyles={styles.input}
                control={control}
                isRequired
                rules={{ required: 'This is a required field' }}
              />
              <Dropdown
                disabled={index < addWarehouse?.length - 1}
                loading={loading}
                name={`warehouseState-${index}`}
                label="Warehouse State"
                control={control}
                customStyles={styles.input}
                isRequired
                options={stateListConverter(stateData?.data)}
                rules={{ required: 'This is a required field' }}
              />
              <Dropdown
                disabled={!watch(`warehouseState-${index}`) || index < addWarehouse?.length - 1}
                loading={loading}
                name={`warehouseCity-${index}`}
                label="Warehouse City"
                control={control}
                customStyles={styles.input}
                isRequired
                options={cityListConverter(input.cityList)}
                rules={{ required: 'This is a required field' }}
              />
            </Fragment>
          ))}
          <Box
            sx={styles.addButton}
            onClick={() =>
              trigger().then((res) => {
                if (res) addWarehouseWrap();
              })
            }>
            + Add more warehouse
          </Box>
        </Fragment>
      )}
    </Box>
  );
};

export default CreateSuppliers;
