const INBOUND_RETURN_ORDER_PATHS = {
  GET_IRN_LIST_DETAILS: `/order/return/:irnId/products`,
  GET_RO_LIST: '/order/return/filter',
  GET_FIND_RO_CSV_DOWNLOAD: '/order/return/filter/export',
  CREATE_RO: `/order/return/create`,
  GET_RO_DETAILS: `/order/return/:ROid`,
  GET_RO_ITEM_LIST: `/order/return/:ROid/products_list`,
  VIEW_PO_DETAILS_UPLOAD: '/order/return/upload/:ROid',
  GET_RO_ITEM_LIST_CSV_DOWNLOAD: `/order/return/:ROid/products_list/export`
};

export default INBOUND_RETURN_ORDER_PATHS;
