import { Box } from '@mui/material';
import React, { Fragment, useState } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import { Dropdown, Input } from '../../../../common';
import styles from '../styles';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { showErrorToast, showSuccessToast } from 'components/common/Toaster';
import { ERROR_MESSAGE, ERROR_MSG, SUCCESS_MSG } from '../../../../../constants/constants';

import DeleteIcon from '@mui/icons-material/Delete';

const transformData = (inputArray) => {
  return inputArray?.map((item) => ({
    label: item?.code,
    value: item?.code
  }));
};

const Rack = ({ loading, setRackList, aisleList, storageType }) => {
  const dispatch = useDispatch();
  const [rackData, setRackData] = useState([
    {
      rackCode: ''
    }
  ]);

  const { control, trigger, getValues, setValue } = useForm({
    defaultValues: {},
    mode: 'all',
    criteriaMode: 'all'
  });

  const addRack = () => {
    if (
      rackData?.length &&
      !rackData[rackData?.length - 1]?.delete &&
      !getValues(`${'rack'}-${rackData?.length - 1}`)
    ) {
      return;
    }
    const newBinData = [
      ...rackData,
      {
        rackCode: ''
      }
    ];
    setRackData(newBinData);
  };

  const saveRackPayload = (rackData) => {
    const list = [];
    rackData?.forEach((rack, index) => {
      if (getValues(`${'rack'}-${index}`)) {
        list.push({
          code: getValues(`${'rack'}-${index}`),
          link: getValues(`${'rack-link'}-${index}`) ? getValues(`${'rack-link'}-${index}`) : ''
        });
      }
    });

    setRackList(list);
  };

  const saveRack = (val, index) => {
    if (val) {
      // check if value already exist
      let valueFound = false;
      rackData?.forEach((rack, ind) => {
        if (ind !== index) {
          if (
            getValues(`${'rack'}-${ind}`) === getValues(`${'rack'}-${index}`) &&
            getValues(`${'rack-link'}-${ind}`) === getValues(`${'rack-link'}-${index}`)
          )
            valueFound = true;
        }
      });

      if (valueFound) {
        showErrorToast('Rack name already exist');
        return;
      }

      const tempRackData = rackData?.map((rack, ind) => {
        if (index === ind) return { ...rack, save: true };
        else return rack;
      });

      setRackData(tempRackData);
      saveRackPayload(tempRackData);
    }
  };

  const deleteRock = (index) => {
    setValue(`${'rock'}-${index}`, '');

    const tempRackData = rackData?.map((rock, ind) => {
      if (index === ind) return { ...rock, delete: true };
      else return rock;
    });

    setRackData(tempRackData);
    saveRackPayload(tempRackData);
  };

  return (
    <Box sx={styles.formWrapper}>
      {rackData.map((rack, index) => {
        if (rack?.delete) return null;
        else
          return (
            <Box key={index} sx={styles.rackWrapper}>
              <Input
                name={`${`rack`}-${index}`}
                label="Rack Code"
                control={control}
                customStyles={storageType == 3 ? { width: '90%' } : styles.input}
                isRequired
                // onKeyPress={(event) => {
                //   if (!/[0-9+]/.test(event.key)) {
                //     event.preventDefault();
                //   }
                // }}
                loading={loading}
                rules={{ required: 'This is a required field' }}
                disabled={rack?.save || index < rackData?.length - 1}
              />
              {storageType != 3 ? (
                <Dropdown
                  name={`${`rack-link`}-${index}`}
                  label="Link Aisle"
                  control={control}
                  customStyles={styles.input}
                  isRequired
                  options={transformData(aisleList)}
                  loading={loading}
                  rules={{ required: 'This is a required field' }}
                  disabled={rack?.save || index < rackData?.length - 1}
                />
              ) : null}
              {rack?.save ? (
                <Box
                  sx={styles.validityCheck}
                  onClick={() => {
                    deleteRock(index);
                  }}>
                  <DeleteIcon />
                </Box>
              ) : (
                <Box
                  sx={styles.validityCheck}
                  onClick={() => {
                    trigger().then((res) => saveRack(res, index));
                  }}>
                  <DoneIcon />
                </Box>
              )}
            </Box>
          );
      })}
      {rackData[rackData?.length - 1]?.save && (
        <Box sx={styles.addButton} onClick={addRack}>
          + Add Rack
        </Box>
      )}
    </Box>
  );
};

export default Rack;
