import { Box, FormHelperText, InputBase } from '@mui/material';
import { useEffect, useState } from 'react';
import Button from '../Button';
import styles from './styles';

const getFileExtensions = (mimeTypes) => {
  const mimeTypeToExtension = {
    'application/pdf': 'PDF',
    'application/doc': 'DOC',
    'text/csv': 'CSV',
    'image/jpeg': 'JPG',
    'video/mp4': 'MP4'
    // Add more mappings here
  };

  const extensions = [];

  mimeTypes?.forEach((mimeType) => {
    const extension = mimeTypeToExtension[mimeType];
    if (extension) {
      extensions.push(extension);
    }
  });

  return extensions;
};

const FileUpload = ({
  file,
  fileSize = 1,
  allowedFileTypes = ['application/pdf'],
  isDisabled,
  isFileSelected,
  fileUploadApi,
  isFileUploaded,
  uploadState,
  ctaLabel = 'Upload',
  tempFileName,
  tempFileNameOnClick,
  extraProps
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  const handleFileSelect = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > fileSize * 1024 * 1024) {
        setErrorMsg(`File size should be less than ${fileSize}mb`);
      } else {
        setSelectedFile(selectedFile);
        setErrorMsg(null);
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('file', selectedFile);
    fileUploadApi(formData, selectedFile?.name, extraProps);
  };

  useEffect(() => {
    if (selectedFile) {
      if (!allowedFileTypes.includes(selectedFile.type)) {
        setErrorMsg('File type not supported.');
        return;
      }
    }
  }, [selectedFile, allowedFileTypes]);

  return (
    <>
      {isFileSelected || isFileUploaded ? (
        <Box>
          File:{' '}
          <Box
            sx={styles.fileName}
            component="a"
            href={file?.fileUrl}
            target="_blank"
            rel="noreferrer">
            {file?.fileName}
          </Box>
        </Box>
      ) : (
        <Box component="form" onSubmit={handleSubmit} sx={styles.wrapper}>
          <Box>
            <Box sx={styles.fileUploadLabel}>{`${getFileExtensions(
              allowedFileTypes
            )?.toString()} | ${fileSize} MB (maximum)`}</Box>
            <InputBase
              type="file"
              onChange={handleFileSelect}
              disabled={isDisabled || uploadState}
              sx={styles.input}
            />
          </Box>
          {errorMsg && <Box sx={styles.errorMessage}>{errorMsg}</Box>}
          {uploadState && <Box sx={styles.uploading}>{uploadState}</Box>}
          {tempFileName && (
            <Box
              onClick={() => {
                if (tempFileNameOnClick) {
                  tempFileNameOnClick(extraProps);
                }
              }}
              sx={tempFileNameOnClick ? { cursor: 'pointer' } : styles.uploading}>
              {tempFileName}
            </Box>
          )}
          <Button
            type="submit"
            disabled={!allowedFileTypes.includes(selectedFile?.type) || errorMsg || uploadState}
            label={ctaLabel}
            customStyles={styles.button}
          />
        </Box>
      )}
    </>
  );
};

export default FileUpload;
