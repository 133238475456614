const COMMON_PATHS = {
  GET_ACCEPT_CONSIGNMENT_DETAILS: `/consignment/:acnId`,
  ACN_LIST_CSV_DOWNLOAD: '/consignment/export',
  GET_BAYLIST: '/bay',
  GET_BRAND_LIST: '/brand',
  GET_WAREHOUSE_LIST: '/warehouse',
  COMMON_LIST_CSV_DOWNLOAD: 'truck/listing/export',
  GET_CATEGORY_LIST: '/category',
  GET_SUPPLIER_LIST: '/supplier',
  GET_RO_LIST_DETAILS: `/order/return/:ROid/products`,
  POST_CREATE_PO_FILE_UPLOAD: '/order/import',
  GET_ZONE_LIST: '/facility/zone',
  GET_CHANNEL_LIST: '/sales/channel',
  GET_CARRIER_LIST: '/return/sales/order/carrier'
};

export default COMMON_PATHS;
