import { Box } from '@mui/material';
import React, { useEffect, useCallback, useState } from 'react';
import useWindowDimensions from '../../../../../../hooks/useWindowDimensions';
import { Dropdown, Spinner, TableDataRenderer, Tooltip } from '../../../../../common';
import styles from '../styles';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { showErrorToast, showSuccessToast } from 'components/common/Toaster';
import { associateUsersTogroup } from '../../../../../../redux/APICaller/userPrivilegeAPI';
import { BAD_REQUEST, SUCCESS } from '../../../../../../constants/apiStatusCodes';

function hasTrueValue(boolArray) {
  return boolArray.includes(true);
}

const extractNames = (arrayOfObjects) => {
  // Use map to extract names from each object
  const namesArray = arrayOfObjects?.map((obj) => obj?.UserGroup);

  return namesArray;
};

const UserGroupTable = ({
  userId,
  list = [],
  isLoading,
  userGroupStatusDefaultValue,
  triggerChange,
  btnDisable,
  setBtnDisable
}) => {
  const dispatch = useDispatch();

  const { control, watch, trigger, getValues } = useForm({
    defaultValues: userGroupStatusDefaultValue,
    mode: 'all',
    criteriaMode: 'all'
  });

  const [{ width }] = useWindowDimensions();
  const isWebView = width > 1000;

  const statusOptions = [
    {
      label: 'Active',
      value: true
    },
    {
      label: 'Declined',
      value: false
    }
  ];

  const columnDefs = [
    {
      field: 'UserGroup',
      headerName: 'User Groups',
      flex: isWebView ? 1 : 0,
      minWidth: 250,
      cellRenderer: ({ data }) => <Tooltip title={data?.UserGroup} />
    },
    {
      field: 'Status',
      headerName: 'Status',
      minWidth: 250,
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => {
        return (
          <Box sx={{ width: 200 }}>
            <Dropdown name={data?.UserGroup} control={control} options={statusOptions} />
          </Box>
        );
      }
    }
  ];

  const updateUsergroupApi = useCallback(
    async (customPayload) => {
      setBtnDisable(true);
      const res = await associateUsersTogroup({
        ...customPayload
      });
      if (res.status === SUCCESS) {
        showSuccessToast(res?.data?.message);
      } else {
        if (res?.status === BAD_REQUEST || res?.status === 409) showErrorToast(res?.data?.message);
        else showErrorToast();
      }
      setBtnDisable(false);
    },
    [dispatch]
  );

  const userGroupStatusChange = watch(extractNames(list));

  const finishExecution = useCallback(
    (res) => {
      if (res) {
        if (!hasTrueValue(userGroupStatusChange))
          showErrorToast('Please Active at least one group');
        else {
          const ids = [];
          list?.map(({ id, UserGroup, active }, index) => {
            if (getValues(UserGroup)) {
              ids.push(id);
            }
          });
          const payload = {
            list: ids,
            userId
          };
          updateUsergroupApi(payload);
        }
      }
    },
    [userGroupStatusChange, userId]
  );

  const triggerValidation = useCallback(() => {
    trigger().then((res) => finishExecution(res));
  }, [trigger, finishExecution]);

  useEffect(() => {
    if (triggerChange) {
      triggerValidation();
    }
  }, [triggerChange]);

  return (
    <Box sx={styles.finalWrapper}>
      {isLoading ? <Spinner /> : <TableDataRenderer list={list} columnDefs={columnDefs} />}
    </Box>
  );
};

export default UserGroupTable;
