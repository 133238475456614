const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    height: '100%'
  },

  formWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 3
  },

  input: {
    width: '40%'
  },

  paginationWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    height: '100%'
  }
};

export default styles;
