const styles = {
  formWrapper: {
    position: 'relative',
    margin: '0 auto',
    padding: {
      xs: '0 5.1%',
      sm: '0 6.9%',
      md: '0 35px'
    },
    overflowY: 'auto',
    height: 'min-content',
    maxHeight: '400px',
    backgroundColor: 'white',
    borderRadius: '16px',
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px'
  },

  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px 0px'
  },

  heading: {
    fontSize: {
      xs: '18px',
      sm: '20px'
    },
    fontWeight: 600
  },

  description: {
    fontSize: {
      xs: '13px',
      sm: '15px'
    }
  },

  divider: {
    position: 'absolute',
    left: 0,
    right: 0,
    border: '1px solid #EAEBEE'
  },

  child: {
    padding: '30px 0px'
  },

  button: {
    height: {
      xs: '36px',
      sm: '40px'
    },
    width: 'min-content',
    minWidth: '120px'
  },

  linkButton: {
    height: {
      xs: '30px',
      sm: '26px'
    },
    borderRadius: '13px',
    fontSize: '12px',
    width: 'min-content',
    minWidth: '85px',
    color: '#FFAA1B'
  }
};

export default styles;
