import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BAD_REQUEST, SUCCESS } from '../../../../constants/apiStatusCodes';
import { ERROR_MSG } from '../../../../constants/constants';
import { getZoneListByAreaId } from '../../../../redux/APICaller/masterManagementAPIs';
import { showToastMessage } from '../../../../redux/showMessageSlice';
import Table from './table';
import { showErrorToast, showSuccessToast } from 'components/common/Toaster';

const dataKeyNameChange = (arrayOfObj) => {
  const newArrayOfObj = arrayOfObj?.map(
    ({ zone_name: ZoneName, category_name: Category, storage_type: StorageType, ...rest }) => ({
      ZoneName,
      Category,
      StorageType,
      ...rest
    })
  );

  return newArrayOfObj;
};

const ZoneTable = ({
  areaId,
  facilityId,
  warehouseId,
  addZone,
  btnOnClick,
  tableRows,
  setTableRows
}) => {
  const dispatch = useDispatch();

  const [pageData, setPageData] = useState({
    currentPage: 1,
    maxPage: 0
  });
  const [initialLoad, setInitialLoad] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  const isDataExist = (page) => {
    let found = false;
    list?.map((item) => {
      if (item.page === page) {
        found = true;
      }
    });
    return found;
  };

  const APICALL = useCallback(
    async (page = 1, areaId) => {
      setLoading(true);
      const customPayload = { areaId };

      const res = await getZoneListByAreaId({
        page: page - 1,
        size: 15,
        ...customPayload
      });
      if (res.status === SUCCESS) {
        if (page === 1) {
          const newData = dataKeyNameChange(res?.data?.list);
          setList([{ page: 1, array: newData }]);
        } else setList([...list, { page, array: dataKeyNameChange(res?.data?.list) }]);
        setPageData({ maxPage: res?.data?.total_pages, currentPage: page });

        if (initialLoad) setInitialLoad(false);
      } else {
        if (res?.status === BAD_REQUEST) showErrorToast(res?.data?.message);
        else showErrorToast();
      }
      setLoading(false);
    },
    [list, setPageData, setList, setLoading, dispatch, initialLoad]
  );

  const apiCallWithPage = useCallback(
    (page) => {
      if (!isDataExist(page)) APICALL(page, areaId);
      else setPageData({ ...pageData, currentPage: page });
    },
    [isDataExist, APICALL, setPageData, areaId]
  );

  const setRowsWithPage = useCallback(
    (page = 1) => {
      list?.map((item) => {
        if (item.page === page) {
          setTableRows(item.array);
        }
      });
    },
    [list, setTableRows, setPageData]
  );

  useEffect(() => {
    setRowsWithPage(pageData.currentPage);
  }, [pageData.currentPage, list]);

  useEffect(() => {
    if (areaId) {
      setList([]);
      setTableRows([]);
      APICALL(1, areaId);
    }
  }, [areaId]);

  return (
    <Table
      list={tableRows}
      pageData={pageData}
      pageClick={(_, pageNumber) => apiCallWithPage(pageNumber)}
      isLoading={isLoading}
      page={pageData.currentPage}
      areaId={areaId}
      facilityId={facilityId}
      warehouseId={warehouseId}
      addZone={addZone}
      btnOnClick={btnOnClick}
    />
  );
};

export default ZoneTable;
