import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { stateListConverter, cityListConverter } from '../../../../constants/apiDataChanger';
import { getStateList, getCityList } from '../../../../redux/APICaller/userProfileAPIs';
import { Dropdown, Input, Radio } from '../../../common';
import styles from '../styles';

const CreateSuppliers = ({
  disabled,
  control,
  loading = false,
  watch,
  setValue,
  state,
  addWarehouse,
  setAddWarehouse,
  trigger
}) => {
  const { suppliersId } = useParams();

  const { data: stateData = { data: [] } } = useQuery({
    queryKey: ['stateList'],
    queryFn: () => {
      return getStateList({ id: 'india' });
    }
    // enabled: !suppliersId
  });

  const { data: cityData = { data: [] } } = useQuery({
    queryKey: ['city', watch(`warehouseState-${addWarehouse?.length - 1}`)],
    queryFn: () => {
      return getCityList({ id: watch(`warehouseState-${addWarehouse?.length - 1}`) });
    },
    enabled: !!watch(`warehouseState-${addWarehouse?.length - 1}`)
  });

  useEffect(() => {
    if (state && disabled) {
      setValue('state_id', state);
    }
  }, [state]);

  return (
    <Box sx={styles.formWrapper}>
      <Input
        disabled={disabled}
        name="supplier_name"
        label="Suppliers Name"
        control={control}
        customStyles={styles.input}
        isRequired
        loading={loading}
        rules={{ required: 'This is a required field' }}
      />
      <Input
        loading={loading}
        disabled={disabled}
        name="supplier_code"
        label="Suppliers Code"
        control={control}
        customStyles={styles.input}
        isRequired
        rules={{ required: 'This is a required field' }}
      />
      <Input
        loading={loading}
        disabled={disabled}
        name="gst"
        label="GST"
        control={control}
        customStyles={styles.input}
        isRequired
        rules={{ required: 'This is a required field' }}
      />
      <Input
        loading={loading}
        disabled={disabled}
        name="address"
        label="Full Address"
        control={control}
        isRequired
        rules={{ required: 'This is a required field' }}
      />
      <Dropdown
        disabled={disabled}
        loading={loading}
        name="state_id"
        label="State"
        control={control}
        customStyles={styles.input}
        isRequired
        options={disabled ? [{ value: state, label: state }] : stateListConverter(stateData?.data)}
        rules={{ required: 'This is a required field' }}
      />
      <Input
        disabled={disabled}
        loading={loading}
        name="city"
        label="City"
        control={control}
        customStyles={styles.input}
        isRequired
        rules={{ required: 'This is a required field' }}
      />
      <Input
        loading={loading}
        disabled={disabled}
        name="zip_code"
        label="Zip Code"
        control={control}
        customStyles={styles.input}
        isRequired
        onKeyPress={(event) => {
          if (!/[0-9+]/.test(event.key)) {
            event.preventDefault();
          }
        }}
        rules={{
          validate: {
            minDigits: (value) =>
              (value?.length >= 5 && !!value) || 'There should be minimum 5 digits'
          }
        }}
      />
      <Radio
        loading={loading}
        disabled={disabled}
        name="status"
        label="Status"
        isRequired
        control={control}
        customStyles={styles.radioButton}
        options={[
          {
            label: 'Enable',
            value: true
          }
          // {
          //   label: 'Disable',
          //   value: false
          // }
        ]}
      />
    </Box>
  );
};

export default CreateSuppliers;
