import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  areaListConverter,
  facilityWarehouseConverter
} from '../../../../constants/apiDataChanger';
import { selectAuthStore } from '../../../../redux/authSlice';
import { Dropdown } from '../../../common';
import styles from '../styles';

const StorageListingFilters = ({ control, watch, data, setData, warehouseList, areaList }) => {
  const authStore = useSelector(selectAuthStore);

  const facility = watch('facility');
  const warehouse = watch('warehouse');
  const area = watch('area');

  const facilityWarehouseList = facilityWarehouseConverter(warehouseList);
  const dropdownAreaList = areaListConverter(areaList);

  useEffect(() => {
    setData({ ...data, facility });
  }, [facility]);

  useEffect(() => {
    setData({ ...data, warehouse });
  }, [warehouse]);

  useEffect(() => {
    setData({ ...data, area });
  }, [area]);

  return (
    <Box sx={styles.formWrapper}>
      <Dropdown
        name="facility"
        label="Facility"
        disabled
        control={control}
        options={authStore.warehouseLocationList}
        customStyles={styles.input}
      />
      <Dropdown
        name="warehouse"
        label="Warehouse"
        control={control}
        options={facilityWarehouseList}
        customStyles={styles.input}
      />
      <Dropdown
        name="area"
        label="Area"
        control={control}
        options={dropdownAreaList}
        customStyles={styles.input}
      />
    </Box>
  );
};

export default StorageListingFilters;
