import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { DynamicButton, Form } from 'components/common';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserState } from 'redux/APICaller/masterManagementAPIs';
import styles from './styles';

const GettingStarted = () => {
  const navigate = useNavigate();
  let type_of_user = localStorage.getItem('type_of_user'); // Vendor || User

  const [userState, setUserState] = useState({
    profile: false,
    profileIsComplete: false,

    facility: true,
    facilityIsComplete: false,

    warehouse: true,
    warehouseIsComplete: false,

    product: true,
    productIsComplete: false
  });

  // Use this when roles come from backend
  let roles = localStorage.getItem('wms_access_roles');

  const { data = { data: {} }, isLoading } = useQuery({
    queryKey: ['warehouseList'],
    queryFn: async () => {
      const res = await getUserState({});
      // if (
      //   res?.data?.is_profile_created &&
      //   res?.data?.is_facility_created &&
      //   res?.data?.is_warehouse_setup
      // ) {
      //   navigate('/');
      // }

      let newObj = { ...userState };
      if (res?.data?.is_profile_created) {
        newObj = { ...newObj, profileIsComplete: true, profile: false, facility: false };
      }
      if (res?.data?.is_facility_created) {
        newObj = { ...newObj, facilityIsComplete: true, facility: false, warehouse: false };
      }
      if (res?.data?.is_warehouse_setup) {
        newObj = { ...newObj, warehouseIsComplete: true, warehouse: false, product: false };
      }
      setUserState(newObj);
    }
  });

  const stepsData = [
    {
      heading: 'Create Your Profile',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      imageUrl: '/user.svg',
      buttonLabel: 'Complete Profile',
      backgroundColor: '#D9FFE3',
      route: '/user-profile',
      isCompleted: userState?.profileIsComplete,
      disabled: !roles || userState?.profile
    },
    {
      heading: 'Facility',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      imageUrl: '/facility.svg',
      buttonLabel: 'Create Facility',
      backgroundColor: '#D9F9FF',
      route: '/facility',
      isCompleted: userState?.facilityIsComplete,
      disabled: !roles || userState?.facility
    },
    {
      heading: 'Setup Warehouse',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      imageUrl: '/garage.svg',
      buttonLabel: 'Add Warehouse',
      backgroundColor: '#146BF51F',
      route: '/warehouse',
      isCompleted: userState?.warehouseIsComplete,
      disabled: !roles || userState?.warehouse
    },
    {
      heading: 'Create Product/Items',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      imageUrl: '/product.svg',
      buttonLabel: 'Add Items',
      backgroundColor: '#F3FFD9',
      route: '/create-product',
      isCompleted: userState?.productIsComplete,
      disabled: !roles || userState?.product
    }
  ];

  return (
    <Box>
      {!roles && <Box sx={styles.error}>User not approved !</Box>}
      {type_of_user === 'Vendor' && (
        <Form
          heading="Getting Started"
          customStyles={{ maxHeight: '100%' }}
          description="4 Steps to complete">
          <Box>
            {stepsData.map(
              (
                {
                  heading,
                  description,
                  imageUrl,
                  buttonLabel,
                  backgroundColor,
                  route,
                  isCompleted,
                  disabled
                },
                index
              ) => {
                return (
                  <Box key={index}>
                    <Box
                      sx={{
                        ...styles.dataWrapper,
                        marginTop: {
                          xs: index === 0 ? 0 : '1.5em',
                          sm: index === 0 ? 0 : '2em',
                          md: index === 0 ? 0 : '2.5em'
                        },
                        marginBottom: {
                          xs: index === stepsData.length - 1 ? 0 : '1.5em',
                          sm: index === stepsData.length - 1 ? 0 : '2em',
                          md: index === stepsData.length - 1 ? 0 : '2.5em'
                        }
                      }}>
                      <Box sx={styles.headerWrapper}>
                        <Box
                          component="img"
                          src={imageUrl}
                          alt="image"
                          sx={{
                            ...styles.image,
                            backgroundColor,
                            filter: disabled ? 'grayscale(100%)' : 'grayscale(0%)',
                            cursor: disabled ? 'not-allowed' : 'pointer'
                          }}
                        />
                        <Box>
                          <Box sx={styles.heading}>{heading}</Box>
                          <Box sx={styles.description}>{description}</Box>
                        </Box>
                      </Box>
                      {isCompleted ? (
                        <Box
                          component="img"
                          src="/completed.svg"
                          alt="completed"
                          sx={{ marginRight: '3.5%' }}
                        />
                      ) : (
                        <DynamicButton
                          label={buttonLabel}
                          disabled={disabled}
                          customStyles={styles.button}
                          onClick={() => {
                            if (disabled) return;
                            navigate(route);
                          }}
                        />
                      )}
                    </Box>
                    {index !== stepsData.length - 1 && <Box sx={styles.divider} />}
                  </Box>
                );
              }
            )}
          </Box>
        </Form>
      )}
    </Box>
  );
};

export default GettingStarted;
