import { RECEIVE_ON_DOCK_PATHS } from '../../constants/apiPaths';
import { getAPI, postAPI } from '../../helpers/API';

const { GET_DOCK_LIST, ASSIGN_DOCK, GET_INVOICE_DETAILS } = RECEIVE_ON_DOCK_PATHS;

// Assign dock
export function assignDock(data) {
  return postAPI({
    url: ASSIGN_DOCK,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

// Yard List
export function getDockList(data) {
  return getAPI({
    url: GET_DOCK_LIST,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

// Yard List
export function getInvoiceDetails(data) {
  return getAPI({
    url: GET_INVOICE_DETAILS.replace(':tripId', data?.tripId),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}
