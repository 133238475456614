const PUTAWAYS_PATHS = {
  GET_PUTTER_LIST: '/facility/putters',
  PUT_CREATE_PUTAWAY_LIST: '/inventory/items/confirm/putaway',
  GET_COMPLETE_PUT_LIST: `/inventory/items/:putlistID`,
  // GET_VERIFY_SHELF_CODE: `/inventory/available/:shelfCode`,
  GET_VERIFY_SHELF_CODE: `/inventory/available`,
  PUT_COMPLETE_PUTLIST: `/inventory/items/complete/putaway/:putlistID`,
  GET_PUTLIST_DETAILS: `/inventory/putlist/:putlistId`,
  GET_PUTLIST_LIST: `/inventory/putlist/:putlistId/details`,
  POST_FIND_PUTAWAY_LIST: '/inventory/putaway/details',
  GET_PUT_AWAY_TRANSFER_LIST: '/inventory/transfer/picked/items/:transferID',
  PUT_CONFIRM_PUTAWAY: '/inventory/transfer/confirm/putaway'
};

export default PUTAWAYS_PATHS;
