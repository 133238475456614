import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import MatModal from '@mui/material/Modal';
import { DynamicButton } from '..';
import styles from './styles';

const Modal = ({
  open,
  heading,
  children,
  handleClose,
  onSuccess,
  buttonLabel,
  hideAcnBtn = false,
  disabled,
  customStyles
}) => {
  return (
    <MatModal
      open={open}
      disableAutoFocus
      disableEnforceFocus
      disableRestoreFocus
      onClose={handleClose}
      sx={styles.mainWrapper}
      closeAfterTransition>
      <Box sx={{ ...styles.wrapper, ...customStyles }}>
        <Box sx={styles.headerWrapper}>
          <Box sx={styles.heading}>{heading}</Box>
          <IconButton sx={styles.iconWrapper} onClick={handleClose}>
            <CloseIcon sx={styles.closeIcon} />
          </IconButton>
        </Box>
        <Box sx={styles.divider} />
        <Box sx={styles.children}>{children}</Box>
        <Box sx={styles.divider} />
        <Box sx={styles.buttonWrapper}>
          {!hideAcnBtn && (
            <DynamicButton
              label={buttonLabel}
              onClick={onSuccess}
              disabled={disabled}
              customStyles={styles.button}
            />
          )}
        </Box>
      </Box>
    </MatModal>
  );
};

export default Modal;
