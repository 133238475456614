import { Box } from '@mui/material';
import React from 'react';
import styles from '../styles';

const POIdRenderer = (cellData) => {
  const RO_ID = cellData?.ReturnOrderID ? cellData?.ReturnOrderID : cellData;

  return (
    <Box sx={styles.lcanId} component="a" href={`/view-return-order/${RO_ID}`} target="_blank">
      {RO_ID}
    </Box>
  );
};

export default POIdRenderer;
