import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, Button } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import {
  FormWrapper,
  MainContent,
  Spinner,
  TableDataRenderer,
  Tooltip,
  CustomIconButton
} from 'components/common';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useNavigate } from 'react-router-dom';
import { getAreaList } from 'redux/APICaller/browseInventoryAPIs';
import styles from './styles';

const AreaList = () => {
  const navigate = useNavigate();

  const [{ width }] = useWindowDimensions();
  const isWebView = width > 1000;

  const { data: list = { data: [] }, isLoading } = useQuery({
    queryKey: 'area',
    queryFn: () => getAreaList()
  });

  const columnDefs = [
    {
      field: 'area_id',
      headerName: 'Area ID',
      minWidth: 250,
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => <Tooltip title={data?.area_id} dataLength={15} />
    },
    {
      field: 'area_name',
      headerName: 'Area Name',
      minWidth: 250,
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => <Tooltip title={data.area_name ? data.area_name : 'No data'} />
    },
    {
      field: 'facility',
      headerName: 'Facility',
      minWidth: 250,
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => <Tooltip title={data.facility ? data.facility : 'No data'} />
    },
    {
      field: 'warehouse',
      headerName: 'Warehouse',
      minWidth: 250,
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) =>
        data.warehouse ? <Tooltip title={data.warehouse} dataLength={15} /> : 'No data'
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 250,
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => (
        <Button
          sx={{
            backgroundColor: data.status ? '#E4FFD8' : '#FFF3D8',
            color: data.status ? '#32830E' : '#BE870D',
            width: 80,
            '&:hover': {
              backgroundColor: data.status ? '#E4FFD8' : '#FFF3D8',
              color: data.status ? '#32830E' : '#BE870D'
            }
          }}>
          {data.status ? 'Enable' : 'Disable'}
        </Button>
      )
    },
    {
      field: 'Navigator',
      headerName: 'Action',
      minWidth: 250,
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => (
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <CustomIconButton onClick={() => navigate(`/manage-area/${data?.area_id}`)}>
            <EditOutlinedIcon />
          </CustomIconButton>
        </Box>
      )
    }
  ];

  return (
    <MainContent
      heading="Area"
      navigateClick={() => navigate('/manage-area')}
      navigateLabel="Add Area">
      <Box sx={styles.wrapper}>
        {isLoading ? <Spinner /> : <TableDataRenderer list={list.data} columnDefs={columnDefs} />}
      </Box>
    </MainContent>
  );
};

export default AreaList;
