/* eslint-disable */
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Box, IconButton, MenuItem, Popover, Select } from '@mui/material';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { dateToString, range } from '../utils';
import styles from './styles';

const CustomSelect = ({ value, options, onChange, ...rest }) => {
  return (
    <Select
      aria-labelledby="change year"
      sx={styles.select}
      variant="outlined"
      value={value}
      onChange={onChange}
      MenuProps={{
        PaperProps: {
          sx: styles.paper
        },
        MenuListProps: {
          sx: styles.menu
        }
      }}
      {...rest}>
      {options?.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
};

const Calendar = ({ maxDate, minDate, field }) => {
  const [calendarAnchor, setCalendarAnchor] = useState(null);

  const years = range(minDate?.getFullYear(), maxDate?.getFullYear(), 1);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  const handleClick = (event) => {
    setCalendarAnchor(event?.currentTarget);
  };

  const handleClose = () => {
    setCalendarAnchor(null);
  };

  const open = !!calendarAnchor;
  const id = open ? 'calendar-popover' : undefined;

  return (
    <Box>
      <IconButton onClick={handleClick} sx={styles.calendarIconBtn}>
        <CalendarMonthIcon />
      </IconButton>
      <Popover
        aria-label="calendar"
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={calendarAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={styles.calendarWrapper}>
        <DatePicker
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled
          }) => (
            <Box sx={styles.customHeader}>
              <IconButton
                aria-label="calender-left-button"
                sx={styles.arrowIcon}
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}>
                <ArrowBackIcon />
              </IconButton>

              <CustomSelect
                onChange={({ target: { value } }) => changeYear(+value)}
                value={date?.getFullYear()}
                options={years}
              />

              <CustomSelect
                value={months[date?.getMonth()]}
                onChange={({ target: { value } }) => changeMonth(months?.indexOf(value))}
                options={months}
              />

              <IconButton
                aria-label="calender-right-button"
                sx={styles.arrowIcon}
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}>
                <ArrowForwardIcon />
              </IconButton>
            </Box>
          )}
          selected={field?.value ? new Date(`${field.value}T00:00:00`) : null}
          onChange={(date) => {
            field.onChange(dateToString(date));
            handleClose();
          }}
          inline
          maxDate={maxDate}
          minDate={minDate}
        />
      </Popover>
    </Box>
  );
};

export default Calendar;
