import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, FormHelperText, IconButton, InputBase, InputLabel } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { getError } from 'constants/common';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { InputSkeletton } from '../../WebSkeleton';
import styles from './styles';

const DropDown = ({
  label,
  name,
  control,
  errors,
  loading = false,
  customStyles,
  options,
  rules,
  fullWidth = true,
  disabled,
  className,
  isRequired,
  selectClass,
  defaultValue,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const openDropDown = () => setOpen(true);
  const closeDropDown = () => setOpen(false);

  if (loading) {
    return (
      <Box className={className} sx={customStyles}>
        <InputSkeletton label={label} />
      </Box>
    );
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, formState: { errors } }) => {
        const error = getError(name, errors);
        const showError = !!error?.message;
        return (
          <FormControl
            disabled={disabled}
            sx={{ ...styles.wrapper, ...customStyles }}
            fullWidth={fullWidth}
            className={className}>
            {label && (
              <InputLabel
                _id={`${name}-label`}
                required={isRequired}
                shrink
                className="label"
                sx={styles.label}>
                {label}
              </InputLabel>
            )}
            <Select
              _id={`${name}-value`}
              className={selectClass}
              defaultValue={defaultValue || ''}
              open={open}
              disabled={disabled}
              onOpen={openDropDown}
              onClose={closeDropDown}
              sx={styles.select}
              value={field.value || defaultValue}
              onBlur={field.onBlur}
              error={showError}
              fullWidth={fullWidth}
              input={<InputBase className={className} {...rest} />}
              MenuProps={{
                PaperProps: {
                  sx: styles.paper
                },
                MenuListProps: {
                  sx: styles.menu
                }
              }}
              IconComponent={() => (
                <IconButton
                  disabled={disabled}
                  sx={styles.dropDownIconWrapper}
                  onClick={openDropDown}>
                  <ArrowBackIosIcon
                    sx={
                      open
                        ? {
                            ...styles.dropDownIconUp,
                            color: disabled ? 'custom.disabledLabel' : 'inherit'
                          }
                        : {
                            ...styles.dropDownIconDown,
                            color: disabled ? 'custom.disabledLabel' : 'inherit'
                          }
                    }
                  />
                </IconButton>
              )}
              {...rest}
              {...field}>
              {options.map(({ label, value, disabled = false, selected }, index) => (
                <MenuItem
                  _id={`${name}-options`}
                  selected={selected}
                  disabled={disabled}
                  sx={styles.menuItem}
                  value={value}
                  key={`${label + '-' + index}`}>
                  <Box sx={styles.text}>{label}</Box>
                </MenuItem>
              ))}
            </Select>
            {error && <FormHelperText sx={styles.errorMessage}>{error.message}</FormHelperText>}
          </FormControl>
        );
      }}
      {...rest}
    />
  );
};

export default DropDown;
