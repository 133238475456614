const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    height: '100%'
  },

  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },

  heading: {
    fontSize: '24px',
    fontWeight: 600
  },

  button: {
    height: '34px',
    fontSize: '14px',
    fontWeight: 400,
    borderRadius: '5px',
    minWidth: '110px',
    width: 'max-content'
  }
};

export default styles;
