import { Box } from '@mui/material';
import styles from './styles';
import LoadingButton from '@mui/lab/LoadingButton';
import { useCallback, useState } from 'react';

const header = `WARNING: This action will clear all data associated with this web app`;

const actionList = [
  `Cookies`,
  `Local storage`,
  `Cache storage`,
  `Session storage`
  // `IndexedDB databases`,
  // `Service workers`,
  // `Push notifications`
];

const clearAll = (setClearCalling, setClearCalled) => {
  // Array to hold all the promises
  const clearPromises = [];

  // Clear Cache
  if ('caches' in window) {
    const cachePromise = caches
      .keys()
      .then(function (names) {
        return Promise.all(names.map((name) => caches.delete(name)));
      })
      .catch((error) => console.error('Error clearing caches:', error));
    clearPromises.push(cachePromise);
  }

  // Clear Local Storage
  localStorage.clear();

  // Clear Session Storage
  sessionStorage.clear();

  // Clear IndexedDB
  if ('indexedDB' in window) {
    const indexedDBPromise = indexedDB
      .databases()
      .then(function (databases) {
        return Promise.all(databases.map((db) => indexedDB.deleteDatabase(db.name)));
      })
      .catch((error) => console.error('Error clearing IndexedDB:', error));
    clearPromises.push(indexedDBPromise);
  }

  // Clear Cookies
  document.cookie.split(';').forEach(function (cookie) {
    document.cookie = cookie
      .replace(/^ +/, '')
      .replace(/=.*/, '=;expires=' + new Date(0).toUTCString() + ';path=/');
  });

  // Unregister Service Workers
  if ('serviceWorker' in navigator) {
    const serviceWorkerPromise = navigator.serviceWorker
      .getRegistrations()
      .then(function (registrations) {
        return Promise.all(registrations.map((registration) => registration.unregister()));
      })
      .catch((error) => console.error('Error unregistering service workers:', error));
    clearPromises.push(serviceWorkerPromise);
  }

  // Unsubscribe from Push Notifications
  if ('serviceWorker' in navigator && 'PushManager' in window) {
    const pushNotificationPromise = navigator.serviceWorker
      .getRegistrations()
      .then(function (registrations) {
        return Promise.all(
          registrations.map((registration) =>
            registration.pushManager.getSubscription().then(function (subscription) {
              if (subscription) {
                return subscription.unsubscribe();
              }
            })
          )
        );
      })
      .catch((error) => console.error('Error unsubscribing from push notifications:', error));
    clearPromises.push(pushNotificationPromise);
  }

  // Wait for all promises to resolve and then print "All clear"
  Promise.all(clearPromises.map((p) => p.catch((error) => console.error(error)))).then(() => {
    setClearCalling(false);
    setClearCalled(true);
    setTimeout(() => {
      window.location.href = `/`;
    }, 3000);
  });
};

const ClearAll = () => {
  const [clearCalled, setClearCalled] = useState(false);
  const [clearCalling, setClearCalling] = useState(false);

  const handleConfirmClick = useCallback(() => {
    if (!clearCalling) {
      setClearCalling(true);
      clearAll(setClearCalling, setClearCalled);
    }
  }, [clearCalling, setClearCalling, setClearCalled]);

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.heading}>{header}</Box>
      <Box sx={styles.subHeading}>-: This includes :-</Box>
      {actionList?.map((action, ind) => {
        return (
          <Box key={ind} sx={styles.subHeading2}>
            {action}
          </Box>
        );
      })}

      {clearCalled ? (
        <Box sx={styles.subHeading}>Cleared all cache, Redirecting to login page</Box>
      ) : (
        <LoadingButton
          type="button"
          onClick={() => handleConfirmClick()}
          sx={styles.button}
          disabled={clearCalled}
          loading={clearCalling}
          loadingPosition="center"
          variant="contained">
          {clearCalled ? 'All cleared' : 'Confirm'}
        </LoadingButton>
      )}
      <Box sx={styles.logoWrapper}>
        <Box component="img" src="/losungLogo.png" alt="losung_logo" sx={styles.logo} />
      </Box>
    </Box>
  );
};

export default ClearAll;
