import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectAuthStore } from 'redux/authSlice';
import { mobileList } from '../Data/mobileData';
import { Mixpanel } from 'mixpanel';
import styles from './styles';

const MobileList = ({ setShowStepper }) => {
  const { appDisable } = useSelector(selectAuthStore);

  const navigate = useNavigate();
  const location = useLocation();

  const [expanded, setExpanded] = useState(false);
  const [mainExpand, setMainExpand] = useState(false);

  // Use this when roles come from backend
  let roles = localStorage.getItem('wms_access_roles');
  let type_of_user = localStorage.getItem('type_of_user'); // Vendor || User

  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleMainChange = (panel) => (_, isExpanded) => {
    setMainExpand(isExpanded ? panel : false);
  };

  const accessRoles = roles ? roles.split(',') : [];

  /**
   * Filtering list based on Roles
   */

  const filterStepperList = () => {
    return mobileList?.filter((step) => {
      if (appDisable) {
        return mobileList;
      }
      const hasRequiredRoles = [
        ...(type_of_user === 'Vendor' ? ['ROLE_onboarding'] : []),
        ...accessRoles
      ].includes(step.role);

      return hasRequiredRoles;
    });
  };

  const isRouteMatch = (locationPathname, route) => {
    if (locationPathname === '/' && route === '/') {
      return true;
    }
    if (route === '/' && locationPathname !== '/') return false;
    return locationPathname.includes(route);
  };

  const filteredStepperList = filterStepperList();

  return (
    <Box sx={styles.wrapper}>
      {filteredStepperList.map(({ heading, icon, subHeading, nestedHeading }, index) => (
        <Accordion
          key={index}
          sx={styles.mainAccordian}
          expanded={mainExpand === `panel${index}`}
          onChange={handleMainChange(`panel${index}`)}>
          <AccordionSummary expandIcon={<ExpandMoreIcon sx={styles.icon} />}>
            <Box key={index} sx={styles.container}>
              <IconButton sx={styles.iconButton}>
                <Box component="img" src={icon} alt="logo" />
              </IconButton>
              <Box sx={styles.heading}>{heading}</Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={styles.nestedContainer}>
              {subHeading &&
                subHeading.map(({ label, route }, idx) => {
                  const checkActivePath = isRouteMatch(location.pathname, route);
                  return (
                    <Box
                      key={idx}
                      onClick={() => {
                        if (appDisable) return false;
                        navigate(route), setShowStepper(false);
                        Mixpanel.track('Tab clicked', {
                          path: `${filteredStepperList[index]?.heading} -> ${subHeading[idx]?.label}`,
                          device: 'phone'
                        });
                      }}
                      sx={{
                        ...styles.details,
                        backgroundColor: checkActivePath ? '#F5DEB6' : 'transparent',
                        cursor: appDisable ? 'not-allowed' : 'pointer'
                      }}>
                      {label}
                      {checkActivePath && <Box sx={styles.leftBar} />}
                    </Box>
                  );
                })}
              {nestedHeading &&
                nestedHeading.map(({ label, components }, subIndex) => (
                  <Accordion
                    key={subIndex}
                    sx={styles.mainAccordian}
                    expanded={expanded === `panel${subIndex}`}
                    onChange={handleChange(`panel${subIndex}`)}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon sx={styles.icon} />}>
                      <Box sx={styles.label}>{label}</Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      {components.map(({ label, route }, idx) => {
                        const checkActivePath = isRouteMatch(location.pathname, route);
                        return (
                          <Box
                            key={idx}
                            onClick={() => {
                              if (appDisable) return false;
                              navigate(route), setShowStepper(false);
                              Mixpanel.track('Tab clicked', {
                                path: `${filteredStepperList[index]?.heading} -> ${nestedHeading[subIndex]?.label} -> ${components[idx]?.label}`,
                                device: 'phone'
                              });
                            }}
                            sx={{
                              ...styles.details,
                              backgroundColor: checkActivePath ? '#F5DEB6' : 'transparent',
                              cursor: appDisable ? 'not-allowed' : 'pointer'
                            }}>
                            {label}
                            {checkActivePath && <Box sx={styles.leftBar} />}
                          </Box>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                ))}
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default MobileList;
