import { Box } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Form, FormWrapper, MainContent } from 'components/common';
import { showErrorToast, showSuccessToast } from 'components/common/Toaster';
import { BAD_REQUEST, SUCCESS, SUCCESS_CREATED } from 'constants/apiStatusCodes';
import { ERROR_MSG } from 'constants/constants';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createFacility,
  getActiveFacilityList,
  getFacilityDetailsById
} from 'redux/APICaller/userFacilityAPIs';
import { setWarehouseLocationAndTypeList } from 'redux/authSlice';
import { showToastMessage } from 'redux/showMessageSlice';
import FacilityDetails from './FacilityDetails';

const dataKeyNameChange = (arrayOfObj) => {
  const newArrayOfObj = arrayOfObj?.map(
    ({ facility_id: value, location_code: label, ...rest }) => ({
      value,
      label,
      ...rest
    })
  );

  return newArrayOfObj;
};

const Facility = () => {
  const { facilityId } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const { control, trigger, getValues, reset, watch, setValue } = useForm({
    defaultValues: {
      facility_name: '',
      country: '',
      state: '',
      city: '',
      street: '',
      location_code: '',
      zip_code: '',
      postal_address: '',
      taluk: '',
      full_address: '',
      status: true
    },
    mode: 'all',
    criteriaMode: 'all'
  });

  const forms = [
    {
      label: 'Facility Details',
      form: FacilityDetails
    }
  ];

  const getActiveFacilityDropdownData = async () => {
    const res = await getActiveFacilityList({});
    if (res?.status === SUCCESS) {
      dispatch(
        setWarehouseLocationAndTypeList({
          warehouseLocationList: dataKeyNameChange(res?.data)
        })
      );
    } else {
      if (res?.status === BAD_REQUEST)
        dispatch(showToastMessage({ messageType: ERROR_MSG, message: res?.data?.message }));
      else dispatch(showToastMessage({ messageType: ERROR_MSG }));
    }
  };

  /**
   * Get facility information
   */
  const { data = {}, isLoading } = useQuery({
    queryKey: ['userData', facilityId],
    queryFn: async () => {
      const res = await getFacilityDetailsById({ facilityId });

      setCountry(res?.data?.country);
      setState(res?.data?.state);
      setCity(res?.data?.city);
      reset(res?.data);

      return res;
    },
    enabled: !!facilityId
  });

  /**
   * Making  update in facility details
   */
  const { isPending, mutate } = useMutation({
    mutationFn: (data) => {
      return createFacility(data);
    },
    onSuccess: (res) => {
      if (res?.status === SUCCESS_CREATED) {
        getActiveFacilityDropdownData();
        showSuccessToast(res?.data?.message);
        if (res?.data?.facility_count === 1) navigate('/getting-started');
        else navigate('/facility-list');
      } else {
        showErrorToast(res?.data?.message);
      }
    },
    onError: () => {
      showErrorToast();
    }
  });

  const handleClick = (res) => {
    if (res) {
      mutate(getValues());
    }
  };

  return (
    <FormWrapper
      loading={facilityId || isPending || isLoading}
      heading="Facility"
      buttonClick={() => trigger().then((res) => handleClick(res))}>
      {forms?.map(({ label, form: FormRenderer }, index) => {
        return (
          <Box key={index}>
            <Form heading={label} customStyles={{ maxHeight: '100%' }}>
              <FormRenderer
                control={control}
                loading={isPending || isLoading}
                getValues={getValues}
                watch={watch}
                disabled={facilityId}
                setValue={setValue}
                country={country}
                state={state}
                city={city}
              />
            </Form>
          </Box>
        );
      })}
    </FormWrapper>
  );
};

export default Facility;
