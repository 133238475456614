const styles = {
  otpWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',

    '& .MuiInputBase-input.Mui-disabled': {
      cursor: 'not-allowed'
    }
  },

  otpBox: {
    display: 'flex',
    justifyContent: 'space-between',
    height: {
      xs: '42px',
      sm: '52px'
    },
    columnGap: {
      xs: '11px',
      sm: '21px'
    },

    '& .Mui-disabled.MuiInputBase-formControl': {
      borderColor: 'custom.disabled'
    }
  },

  otpField: {
    borderRadius: {
      xs: '11.7px',
      sm: '13px'
    },
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.08)',
    borderWidth: '2.6px',
    width: {
      xs: '41px',
      sm: '52px'
    },
    height: {
      xs: '42px',
      sm: '52px'
    },
    fontSize: {
      xs: '19.9px',
      sm: '22.1px'
    },
    fontWeight: '500',
    letterSpacing: {
      xs: '-0.08px',
      sm: '-0.09px'
    },
    color: 'custom.label',

    '& .MuiInputBase-input': {
      textAlign: 'center'
    }
  }
};

export default styles;
