import { lazy } from 'react';

/**
 * Authentication Pages
 */
import ManageUser from '../Pages/UserPrivilege/ManageUser';
import ManageUserGroup from '../Pages/UserPrivilege/ManageUserGroup';
import ForgotPassword from './Auth/ForgotPassword';
import OtpVerification from './Auth/OtpVerification';
import ResetPassword from './Auth/ResetPassword';
import SignIn from './Auth/SignIn';
import CreateUser from './UserPrivilege/ManageUser/CreateUser';
import UserGroups from './UserPrivilege/ManageUser/UserGroups';

/**
 * Private Pages with seperate bundleJs filess
 */
const AcceptConsignment = lazy(() => import('./AcceptConsignment'));
const AllowGateIn = lazy(() => import('./AllowGateIn'));
const AllowGateOut = lazy(() => import('./AllowGateOut'));
const SearchInventory = lazy(() => import('./BrowseInventory/SearchInventory'));
const CreateProduct = lazy(() => import('./BrowseProduct/CreateProduct'));
const FindProduct = lazy(() => import('./BrowseProduct/FindProduct'));
const ViewProduct = lazy(() => import('./BrowseProduct/ViewProduct'));
const BundlingAllOrders = lazy(() => import('./Bundling/AllOrders'));
const CreateBundleOrder = lazy(() => import('./Bundling/AllOrders/CreateBundleOrder'));
const BundleOrderView = lazy(() => import('./Bundling/AllOrders/OrderView'));
const Issue = lazy(() => import('./Bundling/Issue'));
const BundlingConfirmPicking = lazy(() => import('./Bundling/Issue/PickingDetails'));
const DefineBundle = lazy(() => import('./Bundling/ManageBundle/DefineBundle'));
const FindBundle = lazy(() => import('./Bundling/ManageBundle/FindBundle'));
const Receive = lazy(() => import('./Bundling/Receive'));
const ConfirmPutaway = lazy(() => import('./Bundling/Receive/ConfirmPutaway'));
const CompleteLoadingUnloading = lazy(() => import('./CompleteLoadingUnloading'));
const CreateManualACN = lazy(() => import('./CreateManualACN'));
const CreateIRN = lazy(() => import('./IRN/CreateIRN'));
const ReceiveIRN = lazy(() => import('./IRN/ReceiveIRN'));
const ProductLookUp = lazy(() => import('./IRN/ReceiveIRN/ProductLookUp'));
const ReceiveIRNComplete = lazy(() => import('./IRN/ReceiveIRNNew'));
const ViewIRN = lazy(() => import('./IRN/ViewIRN'));
const FindConsignment = lazy(() => import('./InboundConsignment/FindConsignment'));
const ViewConsignment = lazy(() => import('./InboundConsignment/ViewConsignment'));
const InboundCreateProduct = lazy(() => import('./InboundOrders/CreateProduct'));
const FindOrders = lazy(() => import('./InboundOrders/FindOrders'));
const ViewOrders = lazy(() => import('./InboundOrders/ViewOrders'));
const CreateReturnOrder = lazy(() => import('./InboundReturnOrder/CreateReturnOrder'));
const FindReturnOrder = lazy(() => import('./InboundReturnOrder/FindReturnOrder'));
const GenerateReturnOrder = lazy(() => import('./InboundReturnOrder/GenerateReturnOrderForm'));
const ViewReturnOrder = lazy(() => import('./InboundReturnOrder/ViewReturnOrder'));
const AllOrders = lazy(() => import('./Outbound/AllOrders'));
const OutboundCreateSO = lazy(() => import('./Outbound/CreateSO'));
const Manifest = lazy(() => import('./Outbound/Manifest'));
const CompletedManifestDetals = lazy(() => import('./Outbound/Manifest/CompletedManifestDetails'));
const OrderView = lazy(() => import('./Outbound/OrderView'));
const Pack = lazy(() => import('./Outbound/Pack'));
const Pick = lazy(() => import('./Outbound/Pick'));
const PickingDetails = lazy(() => import('./Outbound/Pick/PendingPickList/PickingDetails'));
const Overview = lazy(() => import('./Overview'));
const GenerateLots = lazy(() => import('./Packaging/GenerateLots'));
const ConfirmPutlist = lazy(() => import('./Packaging/GenerateLots/ConfirmPutlist'));
const PackagingIssuance = lazy(() => import('./Packaging/PackagingIssuance'));
const PickingDetailsPackaging = lazy(() => import('./Packaging/PackagingIssuance/PickingDetails'));
const CompletePutlist = lazy(() => import('./Putaways/CompletePutlist'));
const CreatePutlist = lazy(() => import('./Putaways/CreatePutlist'));
const FindPutlist = lazy(() => import('./Putaways/FindPutlist'));
const ViewPutlist = lazy(() => import('./Putaways/ViewPutlist'));
const ReceiveOnDock = lazy(() => import('./ReceiveOnDock'));
const ReleaseFromDock = lazy(() => import('./ReleaseFromDock'));
const ReturnCreateRO = lazy(() => import('./Return/CreateReturnOrder'));
const PutBack = lazy(() => import('./Return/PutBack'));
const PendingPutaway = lazy(() => import('./Return/PutBack/PendingPutaway'));
const ReturnManifest = lazy(() => import('./Return/ReturnManifest'));
const AfterShipmentAdded = lazy(() =>
  import('./Return/ReturnManifest/B2CPages/AfterShipmentAdded')
);
const ReturnManifestDetails = lazy(() => import('./Return/ReturnManifest/ReturnManifestDetails'));
const ReturnManifestMain = lazy(() => import('./Return/ReturnManifest/ReturnManifestMain'));
const StagingInventory = lazy(() => import('./Return/StagingInventory'));
const SearchACN = lazy(() => import('./SearchACN'));
const StartLoadingUnloading = lazy(() => import('./StartLoadingUnloading'));
const CreateTransfer = lazy(() => import('./StockTransfer/CreateTransfer'));
const PickTransfer = lazy(() => import('./StockTransfer/PickTransfer'));
const PutawayTransfer = lazy(() => import('./StockTransfer/PutawayTransfer'));
const SearchStockTransfer = lazy(() => import('./StockTransfer/SearchStockTransfer'));
const ViewTransfer = lazy(() => import('./StockTransfer/ViewTransfer'));

export {
  AcceptConsignment,
  AfterShipmentAdded,
  AllOrders,
  AllowGateIn,
  AllowGateOut,
  BundleOrderView,
  BundlingAllOrders,
  BundlingConfirmPicking,
  CompleteLoadingUnloading,
  CompletePutlist,
  CompletedManifestDetals,
  ConfirmPutaway,
  ConfirmPutlist,
  CreateBundleOrder,
  CreateIRN,
  CreateManualACN,
  CreateProduct,
  CreatePutlist,
  CreateReturnOrder,
  CreateTransfer,
  CreateUser,
  DefineBundle,
  FindBundle,
  FindConsignment,
  FindOrders,
  FindProduct,
  FindPutlist,
  FindReturnOrder,
  ForgotPassword,
  GenerateLots,
  GenerateReturnOrder,
  InboundCreateProduct,
  Issue,
  ManageUser,
  ManageUserGroup,
  Manifest,
  OrderView,
  OtpVerification,
  OutboundCreateSO,
  Overview,
  Pack,
  PackagingIssuance,
  PendingPutaway,
  Pick,
  PickTransfer,
  PickingDetails,
  PickingDetailsPackaging,
  ProductLookUp,
  PutBack,
  PutawayTransfer,
  Receive,
  ReceiveIRN,
  ReceiveIRNComplete,
  ReceiveOnDock,
  ReleaseFromDock,
  ResetPassword,
  ReturnCreateRO,
  ReturnManifest,
  ReturnManifestDetails,
  ReturnManifestMain,
  SearchACN,
  SearchInventory,
  SearchStockTransfer,
  SignIn,
  StagingInventory,
  StartLoadingUnloading,
  UserGroups,
  ViewConsignment,
  ViewIRN,
  ViewOrders,
  ViewProduct,
  ViewPutlist,
  ViewReturnOrder,
  ViewTransfer
};
