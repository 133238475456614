const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8
  },

  heading: {
    fontSize: '16px',
    fontWeight: 600
  },

  inputWrapper: {
    marginTop: 6,
    display: 'flex',
    flexWrap: 'wrap',
    gap: 4
  },

  input: {
    width: '25%'
  },

  modalRadio: {
    '& .MuiFormGroup-root': {
      display: 'flex',
      flexDirection: 'row'
    }
  },

  actionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    padding: {
      xs: '20px',
      sm: '20px 30px'
    },
    border: '1px solid #E1E3E5',
    backgroundColor: '#FFFFFF',
    borderRadius: '12px 12px 0px 0px',
    marginBottom: '1rem'
  },

  actionButtonWrapper: {
    display: 'flex',
    gap: 2
  },

  actionButton: {
    fontWeight: 500,
    cursor: 'pointer',
    borderRadius: '7px',
    padding: '10px 30px'
  },

  finalWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  },

  lcanId: {
    cursor: 'pointer',
    transition: 'all 200ms ease',
    color: 'primary.main',
    '&:hover': {
      textDecoration: 'underline'
    }
  },

  toolTip: {
    fontSize: '16px',
    textAlign: 'center',
    maxWidth: '300px',
    wordSpacing: 1.2
  },

  address: {
    cursor: 'pointer',
    transition: 'all 200ms ease',
    '&:hover': {
      color: '#0858cf'
    }
  },

  dropDownWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '15px',
    marginBottom: '3rem'
  },

  downloadWrapper: {
    position: {
      xs: 'static',
      md: 'absolute'
    },
    bottom: 8,
    right: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px',
    borderRadius: '5px',
    marginTop: '18px',
    height: {
      xs: '32px',
      sm: '34px'
    },
    width: {
      xs: '34px',
      sm: '38px'
    }
  },

  download: {
    transition: 'all 400ms ease',
    color: 'white'
  },

  tableWrapper: {
    width: '100%',
    height: 470,
    overflowY: 'auto',
    fontFamily: "'Poppins', sans-serif",
    '& .ag-header-cell-text': {
      fontWeight: 500,
      fontSize: '16px'
    },
    '& .ag-cell': {
      fontSize: '14.5px',
      display: 'flex',
      alignItems: 'center'
    }
  },

  paginationWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    height: '100%'
  }
};

export default styles;
