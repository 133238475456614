import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, Button } from '@mui/material';
import {
  MainContent,
  Pagination,
  Spinner,
  TableDataRenderer,
  Tooltip,
  CustomIconButton
} from 'components/common';
import { showErrorToast } from 'components/common/Toaster';
import { BAD_REQUEST, SUCCESS } from 'constants/apiStatusCodes';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getSupplierList } from 'redux/APICaller/masterManagementAPIs';
import styles from './styles';

const SupplierList = () => {
  const [list, setList] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [pageData, setPageData] = useState({
    currentPage: 1,
    maxPage: 0
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [{ width }] = useWindowDimensions();
  const isWebView = width > 1000;

  const APICALL = async (page = 1) => {
    setLoading(true);
    const res = await getSupplierList({
      page: page - 1,
      size: 15
    });

    if (res.status === SUCCESS) {
      if (page === 1 || page === 'reset') {
        setList([
          {
            page: 1,
            array: res?.data?.list
          }
        ]);
      } else setList([...list, { page, array: res?.data?.list }]);
      setPageData({ maxPage: res?.data?.total_pages, currentPage: page === 'reset' ? 1 : page });
      if (initialLoad) setInitialLoad(false);
    } else {
      if (res?.status === BAD_REQUEST) showErrorToast(res?.data?.message);
      else showErrorToast();
    }
    setLoading(false);
  };

  const isDataExist = (page) => {
    let found = false;
    list?.map((item) => {
      if (item.page === page) {
        found = true;
      }
    });
    return found;
  };

  const apiCallWithPage = (page) => {
    if (!isDataExist(page)) APICALL(page);
    else setPageData({ ...pageData, currentPage: page });
  };

  const setRowsWithPage = (page = 1) => {
    list?.map((item) => {
      if (item.page === page) {
        setTableRows(item.array);
      }
    });
  };

  useEffect(() => {
    setRowsWithPage(pageData.currentPage);
  }, [pageData.currentPage, list]);

  useEffect(() => {
    APICALL();
  }, []);

  const columnDefs = [
    {
      field: 'supplier_name',
      headerName: 'Suppliers Name',
      minWidth: 250,
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) =>
        data.supplier_name ? <Tooltip title={data.supplier_name} dataLength={18} /> : 'No data'
    },
    {
      field: 'supplier_code',
      headerName: 'Suppliers Code',
      minWidth: 250,
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => (
        <Tooltip title={data.supplier_code ? data.supplier_code : 'No data'} />
      )
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 250,
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => (
        <Button
          sx={{
            backgroundColor: data.status ? '#E4FFD8' : '#FFF3D8',
            color: data.status ? '#32830E' : '#BE870D',
            width: 80,
            '&:hover': {
              backgroundColor: data.status ? '#E4FFD8' : '#FFF3D8',
              color: data.status ? '#32830E' : '#BE870D'
            }
          }}>
          {data.status ? 'Enable' : 'Disable'}
        </Button>
      )
    },
    {
      field: 'Navigator',
      headerName: 'Action',
      minWidth: 250,
      flex: isWebView ? 1 : 0,
      cellRenderer: ({ data }) => (
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <CustomIconButton onClick={() => navigate(`/suppliers/${data?.supplier_id}`)}>
            <EditOutlinedIcon />
          </CustomIconButton>
        </Box>
      )
    }
  ];

  return (
    <MainContent
      heading="Suppliers"
      navigateClick={() => navigate('/suppliers')}
      navigateLabel="Add Suppliers">
      <Box sx={styles.wrapper}>
        {loading ? <Spinner /> : <TableDataRenderer list={tableRows} columnDefs={columnDefs} />}
        <Box sx={styles.paginationWrapper}>
          <Pagination
            count={pageData.maxPage}
            page={pageData.currentPage}
            onChange={(_, pageNumber) => {
              apiCallWithPage(pageNumber);
            }}
          />
        </Box>
      </Box>
    </MainContent>
  );
};

export default SupplierList;
