import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box } from '@mui/material';
import React, { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Input, Dropdown } from '../../../../common';
import styles from '../styles';
import { useDispatch } from 'react-redux';
import { ERROR_MESSAGE, ERROR_MSG, SUCCESS_MSG } from '../../../../../constants/constants';
import { showToastMessage } from '../../../../../redux/showMessageSlice';
import { showErrorToast, showSuccessToast } from 'components/common/Toaster';

const transformData = (inputArray) => {
  return inputArray?.map((item) => ({
    label: item?.link ? `${item?.link}-${item?.code}` : item?.code,
    value: item?.link ? `${item?.link}-${item?.code}` : item?.code
  }));
};

const Bin = ({ loading, setBinList, shelfList, storageType }) => {
  const dispatch = useDispatch();

  const [addBinData, setAddBinData] = useState([{ binName: '' }]);

  const { control, trigger, getValues, setValue } = useForm({
    defaultValues: {},
    mode: 'all',
    criteriaMode: 'all'
  });

  const addBin = () => {
    if (
      addBinData?.length &&
      !addBinData[addBinData?.length - 1]?.delete &&
      !getValues(`${'bin'}-${addBinData?.length - 1}`)
    ) {
      return;
    }
    const newBinData = [
      ...addBinData,
      {
        binName: ''
      }
    ];
    setAddBinData(newBinData);
  };

  const saveBinPayload = (BinData) => {
    const list = [];
    BinData?.forEach((bin, index) => {
      if (getValues(`${'bin'}-${index}`)) {
        list.push({
          code: getValues(`${'bin'}-${index}`),
          link: getValues(`${'bin-link'}-${index}`) ? getValues(`${'bin-link'}-${index}`) : ''
        });
      }
    });

    setBinList(list);
  };

  const saveBin = (val, index) => {
    if (val) {
      // check if value already exist
      let valueFound = false;
      addBinData?.forEach((bin, ind) => {
        if (ind !== index) {
          if (
            getValues(`${'bin'}-${ind}`) === getValues(`${'bin'}-${index}`) &&
            getValues(`${'bin-link'}-${ind}`) === getValues(`${'bin-link'}-${index}`)
          )
            valueFound = true;
        }
      });

      if (valueFound) {
        showErrorToast('Bin  already exist');
        return;
      }

      const tempBinData = addBinData?.map((bin, ind) => {
        if (index === ind) return { ...bin, save: true };
        else return bin;
      });
      //
      setAddBinData(tempBinData);
      saveBinPayload(tempBinData);
    }
  };

  const deleteBin = (index) => {
    setValue(`${'bin'}-${index}`, '');

    const tempBinData = addBinData?.map((bin, ind) => {
      if (index === ind) return { ...bin, delete: true };
      else return bin;
    });
    //
    setAddBinData(tempBinData);
    saveBinPayload(tempBinData);
  };

  return (
    <Box sx={styles.aisleContainer}>
      {addBinData.map((bin, index) => {
        if (bin?.delete) return null;
        else
          return (
            <Box key={index} sx={styles.aisleWrapper}>
              <Input
                name={`${'bin'}-${index}`}
                label="Bin Name"
                control={control}
                customStyles={storageType == 1 ? { width: '90%' } : styles.input}
                // isRequired
                // onKeyPress={(event) => {
                //   if (!/[0-9+]/.test(event.key)) {
                //     event.preventDefault();
                //   }
                // }}
                loading={loading}
                rules={{ required: 'This is a required field' }}
                disabled={bin?.save || index < addBinData?.length - 1}
              />
              {storageType != 1 ? (
                <Dropdown
                  name={`${'bin-link'}-${index}`}
                  label="Link Shelf"
                  control={control}
                  customStyles={styles.input}
                  isRequired
                  options={transformData(shelfList)}
                  loading={loading}
                  rules={{ required: 'This is a required field' }}
                  disabled={bin?.save || index < addBinData?.length - 1}
                />
              ) : null}
              {bin?.save ? (
                <Box
                  sx={styles.validityCheck}
                  onClick={() => {
                    deleteBin(index);
                  }}>
                  <DeleteIcon />
                </Box>
              ) : (
                <Box
                  sx={styles.validityCheck}
                  onClick={() => {
                    trigger().then((res) => saveBin(res, index));
                  }}>
                  <DoneIcon />
                </Box>
              )}
            </Box>
          );
      })}
      {addBinData[addBinData?.length - 1]?.save && (
        <Box sx={styles.addButton} onClick={addBin}>
          + Add Bin
        </Box>
      )}
    </Box>
  );
};

export default Bin;
