import { Box } from '@mui/material';
import React from 'react';
import styles from '../styles';

const ItemDetailsRenderer = (data) => {
  return (
    <Box>
      <Box sx={styles.itemDetails}>Qty : {data?.no_of_units || 0}</Box>
      <Box>Packages : {data?.no_of_packages || 0}</Box>
    </Box>
  );
};

export default ItemDetailsRenderer;
