const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    padding: {
      xs: '20px',
      sm: '20px 30px'
    },
    border: '1px solid #E1E3E5',
    backgroundColor: '#FFFFFF',
    borderRadius: '12px'
  },

  formWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    rowGap: 3,
    flexWrap: 'wrap'
  },

  input: {
    width: {
      xs: '47%',
      sm: '32%'
    }
  },

  largeInput: {
    position: 'relative',
    width: '47%'
  },

  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: {
      xs: 'center',
      sm: 'flex-end'
    }
  },

  button: {
    height: '38px',
    fontWeight: 400,
    borderRadius: '5px',
    width: 'max-content'
  },

  checkInvoice: {
    display: {
      xs: 'none',
      sm: 'flex'
    },
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    position: 'absolute',
    top: 15,
    right: 30,
    fontSize: '12px'
  },

  icon: {
    fontSize: '16px'
  },

  invoiceWrapper: {
    position: 'relative',
    width: {
      xs: '47%',
      sm: '32%'
    }
  },

  invoiceDetailsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: {
      xs: '15px',
      sm: '20px'
    }
  },

  invoiceHeading: {
    textAlign: 'center',
    fontSize: {
      xs: '14px',
      sm: '16px'
    },
    textDecoration: 'underline',
    fontWeight: 600
  },

  detailsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: {
      xs: '14px',
      sm: '16px'
    }
  },

  detailsHeading: {
    fontSize: {
      xs: '12px',
      sm: '14px'
    },
    fontWeight: 500,
    textDecoration: 'underline'
  },

  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    marginTop: '1em'
  },

  singleDetail: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: {
      xs: '12px',
      sm: '14px'
    }
  }
};

export default styles;
