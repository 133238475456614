import React from 'react';
import Input from '../../FormComponents/Input';

const index = ({ name, disabled, type = 'number', control, rules, onKeyPress }) => {
  return (
    <Input
      name={name}
      disabled={disabled}
      type={type}
      control={control}
      rules={rules}
      onKeyPress={onKeyPress}
    />
  );
};

export default index;
