const SUPPLIER_PATHS = {
  POST_SUPPLIER: '/supplier',
  POST_SUPPLIER_UPDATE: 'supplier/:supplierId/warehouse',
  GET_SUPPLIER_LIST: '/supplier/all',
  GET_CATEGORY_LIST: '/category/all',
  GET_STORAGE_LISTING_DETAILS: '/storage-unit',
  GET_SUPPLIER_DETAILS_BY_ID: '/supplier/:supplierId',
  GET_WAREHOUSE_LIST_BY_SUPPLIER_ID: '/warehouse/facility/:facilityId',
  GET_AREA_LIST_BY_WAREHOUSE_ID: '/warehouse/:warehouseId/area',
  GET_ZONE_LIST_BY_AREA_ID: '/area/:areaId/zones',
  POST_ZONE: '/zones',
  GET_USER_STATE: '/company/getting-started',
  POST_ZONE_STORAGE: '/storage-unit',
  GET_WAREHOUSE_DETAILS_BY_ID: '/warehouse/:warehouseId',
  GET_WAREHOUSE_LIST_BY_SUPPLIER: 'supplier/:supplierId/warehouses'
};

export default SUPPLIER_PATHS;
