import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Input } from '../../../../common';
import styles from '../styles';
import { useDispatch } from 'react-redux';
import { showErrorToast, showSuccessToast } from 'components/common/Toaster';
import { ERROR_MESSAGE, ERROR_MSG, SUCCESS_MSG } from '../../../../../constants/constants';

const AisleDetails = ({ loading, setAisleList }) => {
  const dispatch = useDispatch();

  const [addAisleData, setAddAisleData] = useState([{ aisleName: '' }]);

  const { control, trigger, getValues, setValue } = useForm({
    defaultValues: {},
    mode: 'all',
    criteriaMode: 'all'
  });

  const addAisle = () => {
    if (
      addAisleData?.length &&
      !addAisleData[addAisleData?.length - 1]?.delete &&
      !getValues(`${'aisle'}-${addAisleData?.length - 1}`)
    ) {
      return;
    }
    const newAisleData = [
      ...addAisleData,
      {
        aisleName: ''
      }
    ];
    setAddAisleData(newAisleData);
  };

  const saveAislePayload = (aisleData) => {
    const list = [];
    aisleData?.forEach((_, index) => {
      if (getValues(`${'aisle'}-${index}`)) {
        list.push({ code: getValues(`${'aisle'}-${index}`) });
      }
    });

    setAisleList(list);
  };

  const saveAisle = (val, index) => {
    if (val) {
      // check if value already exist
      let valueFound = false;
      addAisleData?.forEach((aisle, ind) => {
        if (ind !== index) {
          if (getValues(`${'aisle'}-${ind}`) === getValues(`${'aisle'}-${index}`))
            valueFound = true;
        }
      });

      if (valueFound) {
        showErrorToast('Aisle  already exist');
        return;
      }

      const tempaisleData = addAisleData?.map((aisle, ind) => {
        if (index === ind) return { ...aisle, save: true };
        else return aisle;
      });

      setAddAisleData(tempaisleData);
      saveAislePayload(tempaisleData);
    }
  };

  const deleteAisle = (index) => {
    setValue(`${'aisle'}-${index}`, '');

    const tempaisleData = addAisleData?.map((aisle, ind) => {
      if (index === ind) return { ...aisle, delete: true };
      else return aisle;
    });

    setAddAisleData(tempaisleData);
    saveAislePayload(tempaisleData);
  };

  return (
    <Box sx={styles.aisleContainer}>
      {addAisleData.map((aisle, index) => {
        if (aisle?.delete) return null;
        else
          return (
            <Box key={index} sx={styles.aisleWrapper}>
              <Input
                name={`${'aisle'}-${index}`}
                label="Aisle Name"
                control={control}
                customStyles={{ width: '100%' }}
                isRequired
                // onKeyPress={(event) => {
                //   if (!/[0-9+]/.test(event.key)) {
                //     event.preventDefault();
                //   }
                // }}
                loading={loading}
                rules={{ required: 'This is a required field' }}
                disabled={aisle?.save || index < addAisleData?.length - 1}
              />
              {aisle?.save ? (
                <Box
                  sx={styles.validityCheck}
                  onClick={() => {
                    deleteAisle(index);
                  }}>
                  <DeleteIcon />
                </Box>
              ) : (
                <Box
                  sx={styles.validityCheck}
                  onClick={() => {
                    trigger().then((res) => saveAisle(res, index));
                  }}>
                  <DoneIcon />
                </Box>
              )}
            </Box>
          );
      })}
      {addAisleData[addAisleData?.length - 1]?.save && (
        <Box sx={styles.addButton} onClick={addAisle}>
          + Add Aisle
        </Box>
      )}
    </Box>
  );
};

export default AisleDetails;
