const BUNDLE_PATHS = {
  CREATE_BUNDLE: '/bundle',
  CREATE_PICKLIST: '/bundle/picklist',
  FIND_BUNDLE: '/bundle/children/:id',
  GET_BUNDLE: '/bundle/:id',
  // GET_BUNDLE_LSIN: '/bundle/type/:id/Bundle',
  GET_BUNDLE_LSIN: '/bundle/type',
  GET_BUNDLE_LSIN_LIST: '/sku/:id/Bundle',
  GET_BUNDLE_ORDER_ASSEMBLY: '/bundle/order/assembly',
  DOWNLOAD_BUNDLE_ORDER_ASSEMBLY: '/bundle/order/assembly/export',
  GET_BUNDLE_ORDER: '/bundle/order/:id',
  GET_BUNDLE_ORDER_LIST: '/bundle/order',
  CREATE_BUNDLE_ORDER: '/bundle/order',
  GET_PICKLIST_DETAILS_PENDING: '/bundle/picklist/details/PENDING',
  GET_PICKLIST_DETAILS_PENDING_DOWNLOAD: '/bundle/picklist/details/PENDING/export',
  GET_PICKLIST_DETAILS_COMPLETED: '/bundle/picklist/details/COMPLETED',
  PRINT_BUNDLE_ORDER_WID: '/bundle/print/wid/:id',
  PRINT_BUNDLE_PICKLIST: '/bundle/picklist/print',
  ISSUE_FOR_ASSEMBLY: '/bundle/picklist/issue',
  GET_COMPLETED_RECEIPT: '/bundle/issued/picklists/:id',
  PRINT_PUTLIST: '/bundle/putlist/print',
  GET_PICKLIST_DETAILS: '/bundle/picklist/details/:id',
  CONFIRM_PICKLIST_DETAILS: '/bundle/picklist/confirm',
  COMPLETE_RECEIVE_STOCK: '/bundle/order/stock/receive',
  CREATE_PUTLIST: '/bundle/putlist',
  GET_PUTLIST: '/bundle/putlist/list',
  GET_CONFIRM_PUTAWAY_DETAILS: '/bundle/order/:id/putlist',
  CONFIRM_PUTAWAY: '/bundle/confirm/putaway/:id'
};

export default BUNDLE_PATHS;
