const OUTBOUND_PATHS = {
  GET_ALL_ORDERS: '/sales/order',
  ALL_ORDERS_CSV_DOWNLOAD: '/sales/order/export',
  GET_ORDERS_DETAILS: '/sales/order/view/:so_id',
  ORDERS_POD_DOWNLOAD: '/sales/order/pod/download/:id',
  ORDERS_LR_DOWNLOAD: '/sales/order/lr/download/:id',
  ORDERS_PRINT: '/sales/order/print/:type',
  CREATE_SO: '/sales/order/import',
  CREATE_PICK_LIST: '/sales/order/pick',
  CREATE_PICK_LIST_B2C: '/sales/order/pick/b2c',
  COMPLETE_PICK_LIST: '/sales/order/pick/picklist',
  PRINT_PICKLIST: '/sales/order/pick/picklist/print',
  PICKING_DETAILS: `/sales/order/pick/picklist/:picklistId`,
  PICKLIST_CONFIRM: `/sales/order/pick/picklist/confirm`,
  PICKLISTID_VERIFY: `/sales/order/pack/:picklistId`,
  GET_PACK_LIST_AND_DETAILS: `/sales/order/pack/pick/packlist`,
  VERIFY_WID_WITH_PICKLISTID: `/sales/order/pick/wid/verify`,
  PACK_COMPLETE: `/sales/order/pack/pack/complete/b2c`,
  PACK_COMPLETE_B2B: `/sales/order/pack/pack/complete/b2b`,
  MANIFEST_DETAILS: '/sales/order/manifest/:manifestId',
  CREATE_MANIFEST_LIST: '/sales/order/manifest',
  PENDING_AND_COMPLETED_MANIFEST_LIST: '/sales/order/manifest/list',
  PRINT_MANIFEST: '/sales/order/manifest/:manifestId/print',
  UPLOAD_POD_AND_PENDING_HANDOVER: '/sales/order/manifest/b2c/complete',
  UPLOAD_POD_AND_LR: '/sales/order/manifest/b2b/upload',
  CREATE_MANIFEST_HANDOVER: '/sales/order/manifest/handover',
  DOWNLOAD_UPLOADED_MANIFEST: '/sales/order/manifest/download/:manifestId'
};

export default OUTBOUND_PATHS;
