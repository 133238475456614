const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem'
  },

  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },

  heading: {
    fontSize: '24px',
    fontWeight: 500
  },

  button: {
    height: '45px',
    width: '120px',
    borderRadius: '8px'
  },

  formWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    rowGap: '20px'
  },

  input: {
    width: {
      xs: '47%',
      md: '30%'
    }
  },

  radioButton: {
    '& .MuiFormGroup-root': {
      display: 'flex',
      flexDirection: 'row'
    },
    width: {
      xs: '47%',
      md: '30%'
    }
  }
};

export default styles;
