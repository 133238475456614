import { Box } from '@mui/material';
import DynamicButton from '../FormComponents/Button';
import styles from './styles';

const FormWrapper = ({
  heading,
  buttonLabel = 'Save',
  buttonClick,
  children,
  loading,
  customStyles,
  childrenStyles,
  as
}) => {
  return (
    <Box sx={{ ...styles.container, ...customStyles }}>
      <Box sx={styles.headerWrapper}>
        <Box sx={styles.heading}>{heading}</Box>
        {buttonClick && (
          <DynamicButton
            disabled={loading}
            label={buttonLabel}
            customStyles={styles.button}
            onClick={buttonClick}
            as={as}
          />
        )}
      </Box>
      <Box sx={{ ...styles.container, ...childrenStyles }}>{children}</Box>
    </Box>
  );
};

export default FormWrapper;
