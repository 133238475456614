/* eslint-disable */
import { FormControl, FormHelperText, InputLabel, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { getError } from '../../../../constants/common';
import Calendar from './Calendar';
import MaskedInput from './MaskedInput';
import styles from './styles';
import {
  MINIMUM_DATE,
  changeToDateFormat,
  changeToMaskedFormat,
  dateToString,
  getMaxDate,
  isLower,
  validateDate
} from './utils';

const DatePicker = ({
  name,
  control,
  label,
  fullWidth = true,
  rules,
  minDate = new Date(MINIMUM_DATE),
  minAge = 0, //change to max Date
  inputProps,
  variant = 'outlined',
  className,
  onClick,
  ariaLabel,
  customStyles,
  ...rest
}) => {
  const maxDate = getMaxDate(minAge);

  return (
    <Controller
      render={({ field, formState: { errors } }) => {
        const error = getError(name, errors);
        return (
          <FormControl sx={customStyles} fullWidth={fullWidth} className={className}>
            {label && <InputLabel>{label}</InputLabel>}
            <TextField
              id={`${name}`}
              variant={variant}
              label={label}
              sx={styles.inputWrapper}
              value={changeToMaskedFormat(field.value)}
              onChange={(event) => {
                const val = changeToDateFormat(event.target.value);
                field.onChange(val);
              }}
              onBlur={(event) => {
                if (
                  validateDate(event.target.value) &&
                  !isLower(event.target.value, dateToString(maxDate))
                ) {
                  const val = dateToString(maxDate);
                  field.onChange(val);
                }
                field.onBlur();
              }}
              inputRef={field.ref}
              inputProps={{
                ...inputProps,
                itemRef: field.ref,
                minDate,
                maxDate,
                date: new Date(field.value),
                'aria-label': ariaLabel || `${name}`
              }}
              InputProps={{
                id: `${name}`,
                inputComponent: MaskedInput,
                'aria-label': ariaLabel || `${name}`,
                endAdornment: <Calendar minDate={minDate} maxDate={maxDate} field={field} />
              }}
              error={!!error}
              {...rest}
            />
            {error && <FormHelperText sx={styles.error}>{error.message}</FormHelperText>}
          </FormControl>
        );
      }}
      name={name}
      control={control}
      rules={rules}
      {...rest}
    />
  );
};

export default DatePicker;
