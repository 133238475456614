import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import {
  cityListConverter,
  countryListConverter,
  stateListConverter
} from '../../../../constants/apiDataChanger';
import {
  getCityList,
  getCountryList,
  getStateList
} from '../../../../redux/APICaller/userProfileAPIs';
import { Dropdown, Input } from '../../../common';
import styles from '../styles';

const BillingAddress = ({ control, loading, watch, setValue }) => {
  const { data = { data: [] } } = useQuery({
    queryKey: 'country',
    queryFn: () => getCountryList()
  });

  const { data: stateData = { data: [] } } = useQuery({
    queryKey: ['state', watch('country')],
    queryFn: () => getStateList({ id: watch('country') }),
    enabled: !!watch('country')
  });

  const { data: cityData = { data: [] } } = useQuery({
    queryKey: ['city', watch('state')],
    queryFn: () => getCityList({ id: watch('state') }),
    enabled: !!watch('state')
  });

  return (
    <Box sx={styles.formWrapper}>
      <Dropdown
        name="country"
        label="Country"
        control={control}
        loading={loading}
        options={countryListConverter(data.data)}
        customStyles={styles.input}
      />
      <Dropdown
        name="state"
        label="State"
        disabled={!watch('country')}
        loading={loading}
        control={control}
        options={stateListConverter(stateData.data) || []}
        customStyles={styles.input}
      />
      <Input
        name="zip_code"
        label="Zip Code"
        control={control}
        customStyles={styles.input}
        loading={loading}
        onKeyPress={(event) => {
          if (!/[0-9+]/.test(event.key)) {
            event.preventDefault();
          }
        }}
        rules={{
          validate: {
            minDigits: (value) =>
              (value?.length >= 5 && !!value) || 'There should be minimum 5 digits'
          }
        }}
      />
      <Dropdown
        name="city"
        label="City"
        disabled={!watch('state') && !watch('country')}
        control={control}
        options={cityListConverter(cityData.data) || []}
        customStyles={styles.input}
        loading={loading}
      />
      <Input name="billing_full_address" label="Full Address" control={control} loading={loading} />
    </Box>
  );
};

export default BillingAddress;
