import {
  AisleList,
  AreaList,
  BinList,
  CategoryList,
  CreateCategory,
  Facility,
  FacilityList,
  ForgotPassword,
  GettingStarted,
  ManageArea,
  RackList,
  SetUpPassword,
  ShelfList,
  SignIn,
  SignUp,
  StorageDetailsZone,
  StorageListing,
  SupplierList,
  Suppliers,
  UserProfile,
  Verify,
  Warehouse,
  WarehouseZone
} from '../components/features';

import {
  AcceptConsignment,
  AfterShipmentAdded,
  AllOrders,
  AllowGateIn,
  AllowGateOut,
  BundleOrderView,
  BundlingAllOrders,
  BundlingConfirmPicking,
  CompletedManifestDetals,
  CompleteLoadingUnloading,
  CompletePutlist,
  ConfirmPutaway,
  ConfirmPutlist,
  CreateBundleOrder,
  CreateIRN,
  CreateManualACN,
  CreateProduct,
  CreatePutlist,
  CreateReturnOrder,
  CreateTransfer,
  CreateUser,
  DefineBundle,
  FindBundle,
  FindConsignment,
  FindOrders,
  FindProduct,
  FindPutlist,
  FindReturnOrder,
  GenerateLots,
  GenerateReturnOrder,
  InboundCreateProduct,
  Issue,
  ManageUser,
  ManageUserGroup,
  Manifest,
  OrderView,
  OutboundCreateSO,
  Overview,
  Pack,
  PackagingIssuance,
  PendingPutaway,
  Pick,
  PickingDetails,
  PickingDetailsPackaging,
  PickTransfer,
  ProductLookUp,
  PutawayTransfer,
  PutBack,
  Receive,
  ReceiveIRN,
  ReceiveIRNComplete,
  ReceiveOnDock,
  ReleaseFromDock,
  ReturnCreateRO,
  ReturnManifest,
  ReturnManifestDetails,
  ReturnManifestMain,
  SearchACN,
  SearchInventory,
  SearchStockTransfer,
  StagingInventory,
  StartLoadingUnloading,
  UserGroups,
  ViewConsignment,
  ViewIRN,
  ViewOrders,
  ViewProduct,
  ViewPutlist,
  ViewReturnOrder,
  ViewTransfer
} from '../components/Pages';
import ReceiveTrailerOnDock from '../components/Pages/ReceiveOnDock/ReceiveTrailerOnDock';
import UpdateUser from '../components/Pages/UserPrivilege/ManageUser/UpdateUser';

const router = {
  authentication: [
    {
      path: '/sign-in',
      element: SignIn
    },
    {
      path: '/sign-up',
      element: SignUp
    },
    {
      path: '/forgot-password',
      element: ForgotPassword
    },
    {
      path: '/verify/:id',
      element: Verify
    },
    {
      path: '/reset-password/:email/:token',
      element: SetUpPassword
    },
    {
      path: '/setup-password/:id',
      element: SetUpPassword
    }
  ],

  defaultRoutes: [
    {
      path: '/',
      element: Overview,
      role: 'whitelist'
    },
    {
      path: '/getting-started',
      element: GettingStarted,
      role: 'whitelist'
    },
    {
      path: '/user-profile',
      element: UserProfile,
      role: 'Vendor'
    },
    {
      path: '/facility/:facilityId?',
      element: Facility,
      role: 'Master'
    },
    {
      path: '/facility-list',
      element: FacilityList,
      role: 'Master'
    },
    {
      path: '/warehouse/:id?',
      element: Warehouse,
      role: 'Master'
    },
    {
      path: '/category/:id?',
      element: CreateCategory,
      role: 'Master'
    },
    {
      path: '/warehouse-zone/:facilityId?',
      element: WarehouseZone,
      role: 'Master'
    },
    {
      path: '/zone-storage/:facilityId?/:areaId?/:zoneId?/:warehouseId?',
      element: StorageDetailsZone,
      role: 'Master'
    },
    {
      path: '/suppliers/:suppliersId?',
      element: Suppliers,
      role: 'Master'
    },
    {
      path: '/manage-area/:id?',
      element: ManageArea,
      role: 'Master'
    },
    {
      path: '/storage-listing',
      element: StorageListing,
      role: 'Master'
    },
    {
      path: '/search-acn',
      element: SearchACN,
      role: 'ROLE_gate_yard_dock'
    },
    {
      path: 'search-acn/accept/:acnId',
      element: AcceptConsignment,
      role: 'ROLE_gate_yard_dock'
    },
    {
      path: 'search-acn/allow-gate-in/:acnId',
      element: AllowGateIn,
      role: 'ROLE_gate_yard_dock'
    },
    {
      path: 'create-manual-acn',
      element: CreateManualACN,
      role: 'ROLE_gate_yard_dock'
    },
    {
      path: 'receive-on-dock',
      element: ReceiveOnDock,
      role: 'ROLE_gate_yard_dock'
    },
    {
      path: 'allow-gate-out',
      element: AllowGateOut,
      role: 'ROLE_gate_yard_dock'
    },
    {
      path: 'receive-on-dock/confirm-receive-on-dock/:acnId',
      element: ReceiveTrailerOnDock,
      role: 'ROLE_gate_yard_dock'
    },
    {
      path: 'start-loading-unloading',
      element: StartLoadingUnloading,
      role: 'ROLE_gate_yard_dock'
    },
    {
      path: 'complete-loading-unloading',
      element: CompleteLoadingUnloading,
      role: 'ROLE_gate_yard_dock'
    },
    {
      path: 'release-from-dock',
      element: ReleaseFromDock,
      role: 'ROLE_gate_yard_dock'
    },
    {
      path: 'view-orders/:po_no?',
      element: ViewOrders,
      role: 'ROLE_inbound'
    },
    {
      path: 'find-orders',
      element: FindOrders,
      role: 'ROLE_inbound'
    },
    {
      path: 'view-consignment/:acn_no?',
      element: ViewConsignment,
      role: 'ROLE_inbound'
    },
    {
      path: 'find-consignment',
      element: FindConsignment,
      role: 'ROLE_inbound'
    },
    {
      path: 'find-return-order',
      element: FindReturnOrder,
      role: 'ROLE_inbound'
    },
    {
      path: 'view-return-order/:ro_no?',
      element: ViewReturnOrder,
      role: 'ROLE_inbound'
    },
    {
      path: 'create-return-order',
      element: CreateReturnOrder,
      role: 'ROLE_inbound'
    },
    {
      path: 'create-irn',
      element: CreateIRN,
      role: 'ROLE_inbound'
    },
    {
      path: 'receive-irn',
      element: ReceiveIRN,
      role: 'ROLE_inbound'
    },
    {
      path: 'create-return-order/:irnId',
      element: GenerateReturnOrder,
      role: 'ROLE_inbound'
    },
    {
      path: 'receive-irn/:skuCode/:irnId',
      element: ProductLookUp,
      role: 'ROLE_inbound'
    },
    {
      path: 'view-irn',
      element: ViewIRN,
      role: 'ROLE_inbound'
    },
    {
      path: 'receive-irn-complete/:irn/:irnId/:wid/:qcId',
      element: ReceiveIRNComplete,
      role: 'ROLE_inbound'
    },
    {
      path: 'create-product',
      element: CreateProduct,
      role: 'ROLE_inventory'
    },
    {
      path: 'find-product',
      element: FindProduct,
      role: 'ROLE_inventory'
    },
    {
      path: 'view-product/:sku_id?',
      element: ViewProduct,
      role: 'ROLE_inventory'
    },
    {
      path: 'search-inventory',
      element: SearchInventory,
      role: 'ROLE_inventory'
    },
    {
      path: 'create-putlist',
      element: CreatePutlist,
      role: 'ROLE_inbound'
    },
    {
      path: 'complete-putlist',
      element: CompletePutlist,
      role: 'ROLE_inbound'
    },
    {
      path: 'find-putlist',
      element: FindPutlist,
      role: 'ROLE_inbound'
    },
    {
      path: 'view-putlist/:putlistId?',
      element: ViewPutlist,
      role: 'ROLE_inbound'
    },
    {
      path: 'create-transfer',
      element: CreateTransfer,
      role: 'ROLE_inventory'
    },
    {
      path: 'view-transfer/:transferId?',
      element: ViewTransfer,
      role: 'ROLE_inventory'
    },
    {
      path: 'pick-transfer',
      element: PickTransfer,
      role: 'ROLE_inventory'
    },
    {
      path: 'search-transfer',
      element: SearchStockTransfer,
      role: 'ROLE_inventory'
    },
    {
      path: 'put-away-transfer',
      element: PutawayTransfer,
      role: 'ROLE_inventory'
    },
    {
      path: 'create-purchase-order',
      element: InboundCreateProduct,
      role: 'ROLE_inbound'
    },
    {
      path: 'all-orders',
      element: AllOrders,
      role: 'ROLE_outbound'
    },
    {
      path: 'all-orders/:order_id?/:order_no?/:channel?',
      element: OrderView,
      role: 'ROLE_outbound'
    },
    {
      path: 'create-so',
      element: OutboundCreateSO,
      role: 'ROLE_outbound'
    },
    {
      path: 'pick',
      element: Pick,
      role: 'ROLE_outbound'
    },
    {
      path: 'pick-details/:id/:picklist_no',
      element: PickingDetails,
      role: 'ROLE_outbound'
    },
    {
      path: 'pack',
      element: Pack,
      role: 'ROLE_outbound'
    },
    {
      path: 'manifest',
      element: Manifest,
      role: 'ROLE_outbound'
    },
    {
      path: 'manifest-details/:id',
      element: CompletedManifestDetals,
      role: 'ROLE_outbound'
    },
    {
      path: 'return-manifest',
      element: ReturnManifest,
      role: 'ROLE_returns'
    },
    {
      path: 'create-ro',
      element: ReturnCreateRO,
      role: 'ROLE_returns'
    },
    {
      path: 'return-manifest-details/:id',
      element: ReturnManifestDetails,
      role: 'ROLE_returns'
    },
    {
      path: 'receive-return-manifest/:id',
      element: ReturnManifestMain,
      role: 'ROLE_returns'
    },
    {
      path: 'receive-return-manifest-details/:id/:uuid',
      element: AfterShipmentAdded,
      role: 'ROLE_returns'
    },
    {
      path: 'staging-inventory',
      element: StagingInventory,
      role: 'ROLE_returns'
    },
    {
      path: 'put-back',
      element: PutBack,
      role: 'ROLE_returns'
    },
    {
      path: 'bundle-details',
      element: FindBundle,
      role: 'ROLE_bundling'
    },
    {
      path: 'bundle-create',
      element: DefineBundle,
      role: 'ROLE_bundling'
    },
    {
      path: 'all-bundling-orders',
      element: BundlingAllOrders,
      role: 'ROLE_bundling'
    },
    {
      path: 'create-bundle-order',
      element: CreateBundleOrder,
      role: 'ROLE_bundling'
    },
    {
      path: 'bundle-order-view/:id',
      element: BundleOrderView,
      role: 'ROLE_bundling'
    },
    {
      path: 'issue',
      element: Issue,
      role: 'ROLE_bundling'
    },
    {
      path: 'confirm-picking/:id',
      element: BundlingConfirmPicking,
      role: 'ROLE_bundling'
    },
    {
      path: 'receive',
      element: Receive,
      role: 'ROLE_bundling'
    },
    {
      path: 'confirm-putaway/:id',
      element: ConfirmPutaway,
      role: 'ROLE_bundling'
    },
    {
      path: 'pending-putaway/:id',
      element: PendingPutaway,
      role: 'ROLE_returns'
    },
    {
      path: 'generate-lot',
      element: GenerateLots,
      role: 'ROLE_packaging'
    },
    {
      path: 'issue-to-sbs',
      element: PackagingIssuance,
      role: 'ROLE_packaging'
    },
    {
      path: 'confirm-putlist/:id',
      element: ConfirmPutlist,
      role: 'ROLE_packaging'
    },
    {
      path: 'picking-details-packaging/:id',
      element: PickingDetailsPackaging,
      role: 'ROLE_packaging'
    },
    {
      path: 'manage-user-group',
      element: ManageUserGroup,
      role: 'Vendor' // User Privilege
    },
    {
      path: 'manage-users',
      element: ManageUser,
      role: 'Vendor' // User Privilege
    },
    {
      path: '/create-user',
      element: CreateUser,
      role: 'Vendor' // User Privilege
    },
    {
      path: '/update-user/:id',
      element: UpdateUser,
      role: 'Vendor' // User Privilege
    },
    {
      path: '/user-groups/:id/:type',
      element: UserGroups,
      role: 'Vendor' // User Privilege
    },
    {
      path: '/supplier-list',
      element: SupplierList,
      role: 'Master'
    },
    {
      path: '/category-list',
      element: CategoryList,
      role: 'Master'
    },
    {
      path: '/area-list',
      element: AreaList,
      role: 'Master'
    },
    {
      path: '/suppliers/:id/warehouse',
      element: AisleList,
      role: 'Master'
    },
    {
      path: '/rack-list/:id',
      element: RackList
    },
    {
      path: '/shelf-list/:id',
      element: ShelfList
    },
    {
      path: '/bin-list/:id',
      element: BinList
    }
  ]
};

const { authentication, defaultRoutes } = router;

export { authentication, defaultRoutes };
