import WAREHOUSE_PATHS from '../../constants/APIPaths/WarehouseAPI';
import { getAPI, postAPI } from '../../helpers/API';

const { CREATE_WAREHOUSE, CREATE_CATEGORY, GET_CATEGORY_BY_ID } = WAREHOUSE_PATHS;

export function createNewWarehouse(data) {
  return postAPI({
    url: CREATE_WAREHOUSE,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

export function createNewCategory(data) {
  return postAPI({
    url: CREATE_CATEGORY,
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}

export function getCategory(data) {
  return getAPI({
    url: GET_CATEGORY_BY_ID.replace(':id', data?.id),
    data,
    isPrivate: true,
    isCustomDomain: true,
    isCustomDomain2: true
  });
}
